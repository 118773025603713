import React from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useStyles } from './styles';

const RefreshButton = ({ onClick, isLoading }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.refreshButton}
      size="small"
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={18} color="primary" />
      ) : (
        <RefreshIcon />
      )}
    </IconButton>
  );
};

export default RefreshButton;
