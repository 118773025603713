import qs from 'qs';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { formatDate } from '../../utils/date';
import DataGrid from '../../components/DataGrid';
import { formatCurrency } from '../../utils/currency';
import { capitalizeString } from '../../utils/string';
import StatusLabel from '../../components/StatusLabel';

const columns = [
  {
    field: 'type',
    title: 'Type',
    width: '100px',
  },
  {
    field: 'processorTransactionId',
    title: 'Processor Transaction Id',
    width: '150px',
  },
  {
    field: 'date',
    title: 'Date',
    width: '200px',
  },
  {
    field: 'status',
    title: 'Payment Status',
    width: '200px',
  },
  {
    field: 'total',
    title: 'Total',
    width: '100px',
  },
];

const TransactionList = () => {
  const [query, setQuery] = useState({ page: 1 });

  const { data, isLoading, isFetching } = useQuery(
    ['transactions', query],
    () => axios.get(`/partner-payments?${qs.stringify(query)}`),
    {
      keepPreviousData: true,
      select: data => ({
        ...data,
        content: data.content.map(row => ({
          id: row.id,
          type: capitalizeString(row.type),
          processorTransactionId: row.processor_transaction_id,
          total: formatCurrency(row.amount),
          status: (
            <StatusLabel
              label={capitalizeString(row.processor_payment_status)}
              status={Number(row.processor_payment_status === 'success')}
            />
          ),
          date: formatDate(row.transaction_date),
        })),
      }),
    },
  );

  return (
    <DataGrid
      dense
      pageable
      rowKey="id"
      columns={columns}
      rows={data?.content || []}
      totalPages={data?.totalPages}
      totalElements={data?.totalElements}
      loading={isLoading || isFetching}
      onQueryChange={setQuery}
      emptyText="You don't have any transactions yet"
    />
  );
};

export default TransactionList;
