import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';

const Banner = ({ url, text, btnText }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        alignItems: 'center',
        padding: '14px 20px',
        borderRadius: '4px',
        backgroundColor: '#ADD8E6',
        color: '#000',
        marginBottom: '1rem',
        fontWeight: '600',
        boxShadow:
          '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)', // Material-UI shadow
      }}
    >
      <Typography
        sx={{
          backgroundColor: '#fff',
          borderRadius: '50%',
          padding: '0 5px',
          marginRight: '5px',
        }}
      >
        ❓
      </Typography>{' '}
      <Typography fontWeight="bold">Need Help? </Typography> &nbsp;{' '}
      {text
        ? text
        : "If you have any questions or run into any issues, we're here to help."}
      <Link
        sx={{
          color: '#000',
          marginLeft: '5px',
          textDecoration: 'underline',
        }}
        href={url}
        target="_blank"
      >
        {btnText ? btnText : 'Visit our Support Page'}
      </Link>
      .
    </Box>
  );
};

export default Banner;
