import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '40px',
    '--amplify-primary-shade': '#fff1f0',
    '--amplify-primary-color': theme.palette.primary.main,
    '--amplify-primary-tint': theme.palette.primary.main,
    '--amplify-font-family': theme.typography.fontFamily,
    '--amplify-primary-contrast': theme.palette.primary.contrastText,
    '& .hydrated': {
      '--container-height': 'calc(100vh - 40px)',
      '--border-radius': `${theme.shape.borderRadius}px`,
    },
  },
  logo: {
    height: '120px',
    marginTop: '-120px',
    position: 'absolute',
    top: 'calc(50% - 208px)',
    animation: '$appLogoFloat infinite 3s ease-in-out',
  },
  '@keyframes appLogoFloat': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(10px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
}));
