import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '200px',
    marginRight: theme.spacing(3),
  },
  paper: {
    flex: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3.5),
    borderRadius: 6,
    '& .tabulator': {
      border: 'none',
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: theme.palette.background.paper,
      '& .tabulator-placeholder span': {
        padding: theme.spacing(4, 0),
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& .tabulator-header': {
        margin: 0,
        fontWeight: 400,
        borderBottomWidth: 2,
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(14),
        borderBottomColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        '& .tabulator-col': {
          background: theme.palette.background.paper,
          border: 'none',
          '&.tabulator-sortable:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '& .tabulator-col-content': {
            padding: theme.spacing(1.5, 1),
          },
          '& .tabulator-col-sorter': {
            color: theme.palette.text.secondary,
          },
          '&.tabulator-sortable[aria-sort=asc] .tabulator-col-sorter .tabulator-arrow':
            {
              borderBottomColor: theme.palette.text.primary,
            },
          '&.tabulator-sortable[aria-sort=desc] .tabulator-col-sorter .tabulator-arrow':
            {
              borderTopColor: theme.palette.text.primary,
            },
        },
      },
      '& .tabulator-table': {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        '& .tabulator-row': {
          margin: 0,
          backgroundColor: theme.palette.background.paper,
          borderTop: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        '& .tabulator-group': {
          padding: theme.spacing(3, 2),
          backgroundColor: theme.palette.background.default,
          '& span': {
            color: theme.palette.primary.main,
          },
          '&.tabulator-group-visible .tabulator-arrow': {
            borderTopColor: theme.palette.primary.main,
          },
          '&:not(.tabulator-group-visible) .tabulator-arrow': {
            borderLeftColor: theme.palette.primary.main,
          },
        },
        '& .tabulator-cell': {
          border: 'none',
          height: 'auto !important',
          padding: theme.spacing(3, 2),
          backgroundColor: theme.palette.background.paper,
          '&:first-child': {
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
    },
  },
  clickable: {
    textDecoration: 'none',
    color: '#000',
  },
  nonClickable: {
    textDecoration: 'none',
    color: '#000',
    cursor: 'not-allowed',
  },
}));
