import clsx from 'clsx';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useCallback, useState, useMemo } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import useCurrentPartner from '../../hooks/useCurrentPartner';

import { ZapierIcon } from '../../assets/icons';
import { integrationProviders } from '../../config/integration';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5.5, 2),
    borderRadius: 6,
    boxShadow: '0 1px 4px 0 rgba(21, 34, 50, 0.23)',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  chipLabel: {
    position: 'absolute',
    top: theme.spacing(-1),
    left: theme.spacing(-1),
  },
  zapierLabel: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  zapierIcon: {
    width: '1rem',
    height: '1rem',
  },
  useHash: {
    opacity: 0.2,
    cursor: 'not-allowed',
    'pointer-events': 'none',
  },
  disabledPaper: {
    cursor: 'default',
  },
}));

const IntegrationProviderList = ({ onProviderSelect }) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');

  const partner = useCurrentPartner();

  const handleClick = useCallback(
    provider => {
      if (provider.available === false) {
        return;
      }

      onProviderSelect(provider.type);
    },
    [onProviderSelect],
  );

  const integrationList = useMemo(
    () =>
      integrationProviders.filter(provider =>
        keyword
          ? provider.title.toLowerCase().includes(keyword.toLowerCase())
          : true,
      ),
    [keyword],
  );
  return (
    <>
      <Box marginBottom={3.75}>
        <TextField
          margin="none"
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" variant="standard">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Grid container spacing={4}>
        {integrationList.map((provider, id) => (
          <Grid key={id} item xs={6} md={4} lg={3}>
            <Tooltip title={provider.title}>
              <Paper
                elevation={0}
                className={clsx(classes.paper, {
                  [classes.disabledPaper]: provider.available === false,
                  [classes.useHash]:
                    !provider.isHashAvailable && partner.hash_access === true,
                })}
                onClick={handleClick.bind(this, provider)}
              >
                <provider.icon width={158} height={110} />
                {provider.available === false && (
                  <div className={classes.chipLabel}>
                    <Chip color="primary" label="Coming Soon" />
                  </div>
                )}
                {provider.isZapierWebhook && (
                  <div
                    className={classes.zapierLabel}
                    title="Powered by Zapier"
                  >
                    <ZapierIcon className={classes.zapierIcon} />
                  </div>
                )}
              </Paper>
            </Tooltip>
          </Grid>
        ))}
        {integrationList.length === 0 && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="subtitle2">
              No integrations matched your search
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

IntegrationProviderList.propTypes = {
  onProviderSelect: PropTypes.func.isRequired,
};

export default IntegrationProviderList;
