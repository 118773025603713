import PartnerTable from './PartnerTable';
import PageHeader from '../../components/PageHeader';
import { Box } from '@material-ui/core';
import { appPermissions, useAccess } from '../../hooks/useAccess';

const TriggerAdmin = () => {
  const { hasPermission } = useAccess();

  return hasPermission(appPermissions.adminRole) ? (
    <>
      <PageHeader title="Admin Partners View" />
      <PartnerTable />
    </>
  ) : (
    <Box>
      <div className="permission-error" style={{ color: '#777' }}>
        You do not have permissions. Please contact support.
      </div>
    </Box>
  );
};

export default TriggerAdmin;
