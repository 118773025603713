import qs from 'qs';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { useQueries } from 'react-query';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DataGrid from '../../components/DataGrid';
import ProgressBar from '../../components/ProgressBar';
import { formatDate } from '../../utils/date';

const columns = [
  {
    field: 'csvFile',
    title: 'CSV File',
    width: '290px',
  },
  {
    field: 'details',
    title: 'Details',
  },
  {
    field: 'state',
    title: 'State',
    width: '400px',
  },
  {
    field: 'notification',
    title: 'Notification',
    width: '250px',
  },
];

const renderState = row => {
  switch (row.status) {
    case 'FINISHED':
      return <ProgressBar value={100}>100%</ProgressBar>;

    case 'IN_PROG': {
      const progress = row.total
        ? Math.round(
            ((row.processed + row.failed + row.exist) / row.total) * 100,
          )
        : 0;
      return <ProgressBar value={progress}>{progress}%</ProgressBar>;
    }
    default:
      return null;
  }
};

const FileList = ({ rows, isLoading, onNotificationStateChange }) => {
  const [computedRows, setComputedRows] = useState([]);
  const [inProgress, setInProgress] = useState([]);

  useEffect(() => {
    setComputedRows(rows);
    setInProgress(rows.filter(row => row.status === 'IN_PROG' && !!row.jobId));
  }, [rows]);

  // based on provided rows rerender source
  const updateFileRowData = (fileName, newData) => {
    const updateIndex = computedRows.findIndex(r => r.fileName === fileName);
    const updatedRows =
      updateIndex === -1
        ? computedRows
        : [
            ...computedRows.slice(0, updateIndex),
            {
              ...computedRows[updateIndex],
              ...newData,
            },
            ...computedRows.slice(updateIndex + 1),
          ];
    setComputedRows(updatedRows);
  };

  useQueries(
    rows
      .filter(row => row.status === 'IN_PROG' && !!row.jobId)
      .map((row, index) => ({
        retry: 3,
        refetchInterval: 3000,
        enabled: !!inProgress[index],
        queryKey: ['csvFile', row.jobId],
        queryFn: () => {
          const source = axios.CancelToken.source();

          const promise = axios.get(
            `/checkImportStatus?${qs.stringify({ importId: row.jobId })}`,
            { cancelToken: source.token },
          );

          promise.cancel = () => {
            source.cancel('Query was cancelled by React Query');
          };

          return promise;
        },
        onSuccess: newData => {
          updateFileRowData(row.fileName, newData);
          if (newData.status === 'FINISHED') {
            setInProgress(prevState => {
              prevState.splice(index, 1, false);
              return prevState;
            });
          }
        },
      })),
  );

  const dataSource = useMemo(
    () =>
      computedRows.map(row => ({
        fileName: row.fileName,
        csvFile: row.originFileName,
        details: (
          <>
            {row.started && (
              <Typography variant="body2" noWrap>
                Started on {formatDate(row.started)}
              </Typography>
            )}
            {row.uploadedOn && (
              <Typography variant="body2" noWrap>
                Uploaded on {formatDate(row.uploadedOn)}
              </Typography>
            )}
            {row.finished && (
              <Typography variant="body2" noWrap>
                Finished on {formatDate(row.finished)}
              </Typography>
            )}
          </>
        ),
        state: (
          <>
            {renderState(row)}
            <Box
              display="flex"
              marginTop={0.5}
              justifyContent="center"
              fontSize="0.625rem"
              fontWeight={500}
            >
              <Box marginLeft={0.5}>Total: {row.total || 0}</Box>
              <Box marginLeft={0.5}>Processed: {row.processed || 0}</Box>
              <Box marginLeft={0.5}>Failed: {row.failed || 0}</Box>
              <Box marginLeft={0.5}>Duplicates: {row.exist || 0}</Box>
            </Box>
          </>
        ),
        notification: (
          <FormControlLabel
            name={row.jobId}
            disabled={!row.jobId}
            value={row.sendNotification ?? false}
            checked={row.sendNotification ?? false}
            onChange={onNotificationStateChange}
            control={<Checkbox color="primary" />}
            label={
              <Typography variant="body2">Send email when completed</Typography>
            }
          />
        ),
      })),
    [onNotificationStateChange, computedRows],
  );

  return (
    <DataGrid
      rowKey="fileName"
      rows={dataSource}
      columns={columns}
      loading={isLoading}
      emptyText="You don't have any contacts yet"
    />
  );
};

export default FileList;
