import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import RouterLink from '../RouterLink';
import { IconButton } from '@material-ui/core';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';
import { EditOutlined, DeleteOutlined } from '@material-ui/icons';
import { formatDate } from '../../utils/date';

const TemplateCard = ({ data }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const deleteMutation = useMutation(
    async id => {
      const response = await axios.delete(
        `${API_BASE_URL}/templates/${id}/delete`,
      );
      return response.data;
    },
    {
      onMutate: async deletedItemId => {
        const prevData = queryClient.getQueryData('fetchEmailTemp');

        queryClient.setQueryData('fetchEmailTemp', oldData => {
          return oldData.filter(item => item.id !== deletedItemId);
        });

        return prevData;
      },
      onError: context => {
        queryClient.setQueryData('fetchEmailTemp', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('fetchEmailTemp');
      },
    },
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleDelete = id => {
    deleteMutation.mutate(id);
    handleClose();
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return (
    <>
      <Card
        style={{
          position: 'relative',
        }}
      >
        <CardMedia
          component="img"
          image={data?.template_thumbnail}
          height="200"
          style={{ backgroundSize: '300px 200px' }}
          alt="Landscape"
        />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            style={{
              position: 'absolute',
              top: 150,
              left: 10,
              color: 'white',
              background: 'rgba(0, 0, 0, 0.5)',
              padding: '4px 8px',
            }}
          >
            {data?.custom_title}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            Updated at: {formatDate(data?.date_updated.toString())}
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <IconButton
              variant="contained"
              size="small"
              style={{ color: '#ff4154' }}
              onClick={handleOpen}
            >
              <DeleteOutlined />
            </IconButton>
            <IconButton
              component={RouterLink}
              to={`/admin-templates/${data?.id}/edit`}
              size="small"
              style={{ color: '#e8c94d' }}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(data?.id)} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplateCard;
