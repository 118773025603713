import qs from 'qs';
import axios from 'axios';
import * as Papa from 'papaparse';
import { useSnackbar } from 'notistack';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useMutation, useQueryClient } from 'react-query';
import PartnerContext from '../../contexts/PartnerContext';

import UploadDialog from './UploadDialog';
import { CustomToolTipStyles } from '../../styles/theme';

const UploadContactsContainer = () => {
  const customToolTipClasses = CustomToolTipStyles();
  const { listId } = useParams();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [fileType, setFileType] = useState([
    { key: 'Raw Email Addresses', value: 'raw' },
    { key: 'MD5 Hashes', value: 'MD5' },
  ]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { hash_access } = useContext(PartnerContext);

  const { mutateAsync: startImport } = useMutation(
    ({ fileName, originFileName, emailMapping, fileType, custom_fields }) =>
      axios.get(
        `/startImport?${qs.stringify({
          listId,
          fileName,
          originFileName,
          emailMapping,
          fileType: fileType || 'raw',
          sendNotification: false,
          encoding: 'MD5',
          custom_fields,
        })}`,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['partnerList', listId]);
      },
    },
  );

  const { mutateAsync: uploadAdditionalContacts } = useMutation(
    ({ bucketData, file, cancelToken }) =>
      axios.create().put(bucketData.uploadURL, file, {
        cancelToken,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Cache-Control': 'max-age=31104000',
        },
        onUploadProgress: e => {
          const progress = Math.round((e.loaded / e.total) * 100);
          if (uploadProgress >= 100) {
            setUploadProgress(100);
          } else {
            setUploadProgress(progress);
          }
        },
      }),
  );

  const handleUpload = async event => {
    const file = event.target.files[0];
    setSelectedFile(file);
    await Papa.parse(file, {
      worker: true,
      preview: 1,
      step: function (results) {
        setCsvHeaders(results.data);
      },
    });

    setUploadDialogOpen(true);
    event.target.value = null;
  };

  const onUploadSubmitCallback = async values => {
    const bucketData = await axios.get('/records/sign');
    const { CancelToken } = axios;
    const { token } = CancelToken.source();
    try {
      // uploading file to S3 Bucket
      await uploadAdditionalContacts({
        bucketData,
        file: selectedFile,
        cancelToken: token,
      });
      // start import process
      await startImport({
        fileName: bucketData.csvFilename,
        originFileName: selectedFile.name,
        emailMapping: values?.email_address,
        fileType: values.file_type,
        custom_fields: values.custom_fields,
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    await onCloseUploadCallback();
  };

  const onCloseUploadCallback = async () => {
    setUploadDialogOpen(false);
    setUploadProgress(0);
    setSelectedFile(null);
    await queryClient.invalidateQueries(['csvFiles', listId]);
  };

  return (
    <>
      <Tooltip
        title={
          <>
            <p>
              The contact list should ONLY include email address and be uploaded
              as a .csv file.
            </p>
            <p>
              If your contact list is larger than 50k contacts please email it
              to support@inboxmailers.com for processing.
            </p>
          </>
        }
        arrow
        classes={customToolTipClasses}
      >
        <Button
          id="upload-btn"
          color="primary"
          variant="contained"
          component="label"
        >
          Upload Contacts
          <input
            type={'file'}
            hidden={true}
            multiple={false}
            accept={'.csv'}
            onChange={handleUpload}
          />
        </Button>
      </Tooltip>
      {uploadDialogOpen && (
        <UploadDialog
          csvHeaders={csvHeaders}
          uploadProgress={uploadProgress}
          onClose={onCloseUploadCallback}
          onSubmit={onUploadSubmitCallback}
          hash_access={hash_access}
          fileType={fileType}
        />
      )}
    </>
  );
};

export default UploadContactsContainer;
