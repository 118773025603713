import { Dialog, DialogContent } from '@material-ui/core';
import TriggerCampaignForm from './TriggerCampaignForm';

const CampaignIntegrationTriggerBuilder = ({
  mode,
  handleCancel,
  initialCampaignData,
}) => {
  const isNew = !initialCampaignData.id;
  return (
    <>
      {mode === 'dialog' ? (
        <Dialog maxWidth="sm" fullWidth open onClose={handleCancel}>
          <DialogContent>
            <TriggerCampaignForm enableIcon handleCancel={handleCancel} />
          </DialogContent>
        </Dialog>
      ) : (
        <TriggerCampaignForm
          enableIcon
          handleCancel={handleCancel}
          isNew={isNew}
          initialCampaignData={initialCampaignData}
        />
      )}
    </>
  );
};

export default CampaignIntegrationTriggerBuilder;
