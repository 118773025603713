import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

import { useStyles } from './styles';

const StatusLabel = ({ label, status }) => {
  const classes = useStyles();
  console.log('STATUS: ', status);
  return (
    <Chip
      size="small"
      label={label}
      variant="contained"
      className={
        status === 17
          ? classes.statusPaused
          : status
          ? classes.statusSuccess
          : classes.statusWarning
      }
    />
  );
};

StatusLabel.propTypes = {
  label: PropTypes.string,
  status: PropTypes.oneOf([17, 0, 1]),
};

export default StatusLabel;
