import ListItem from '@material-ui/core/ListItem';
import React, { useMemo, forwardRef } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useStyles, useAdminStyles } from './styles';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';

const ListItemLink = ({
  to,
  isBeta,
  icon,
  primary,
  isDropdownListLink = false,
  isDropdownListItemActive = false,
  handleIsDropdownListItemActive,
  isAdmin,
}) => {
  const classes = useStyles();

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <NavLink to={to} innerRef={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        className={clsx({
          [classes.itemRoot]: !isAdmin,
          [classes.itemRootAdmin]: isAdmin,
          [classes.itemRootIsDropdownListLink]: isDropdownListLink,
        })}
        onClick={() => {
          if (
            !isDropdownListLink &&
            isDropdownListItemActive &&
            handleIsDropdownListItemActive
          ) {
            handleIsDropdownListItemActive(false);
          }
        }}
        activeClassName={clsx({
          [classes.itemActive]:
            !isDropdownListLink && !isDropdownListItemActive && !isAdmin,
          [classes.itemActiveAdmin]:
            !isDropdownListLink && !isDropdownListItemActive && isAdmin,
        })}
      >
        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ variant: 'body2' }}
        />
        {isBeta && <Chip className={classes.beta} size="small" label="BETA" />}
      </ListItem>
    </li>
  );
};

export default ListItemLink;
