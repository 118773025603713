import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import './style.css';
import Spinner from '../Spinner';

const UpdateEmailTemplatePrompt = ({
  dialogState,
  title,
  thumbnail,
  handleUpdateTemp,
  handleDialogClose,
  updateProcessing,
}) => {
  const [openDialog, setOpenDialog] = useState(dialogState);
  const [templateTitle, setTemplateTitle] = useState(title);
  const [base64Image, setBase64Image] = useState(thumbnail);

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const blob = await fetch(reader.result).then(response =>
          response.blob(),
        );

        setBase64Image(reader.result);
        reader.readAsDataURL(file);
      };

      reader.readAsDataURL(file);
    }
  };

  const closeDialog = () => {
    handleDialogClose();
    setOpenDialog(false);
  };

  const handleUpdateClick = () => {
    handleUpdateTemp(templateTitle, base64Image);
  };

  return (
    <Dialog open={openDialog}>
      <DialogTitle>Update template</DialogTitle>
      <DialogContent>
        <TextField
          defaultValue={title}
          onChange={e => setTemplateTitle(e.target.value)}
          inputProps={{ maxLength: 12 }}
        />
        <div className="file-input">
          <label for="file">Select a Thumbnail</label>
          <input
            type="file"
            id="file"
            name="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          // onClick={() => handleUpdateTemp(templateTitle, base64Image)}
          onClick={handleUpdateClick}
          sx={{
            width: '100px',
          }}
          disabled={updateProcessing}
        >
          {updateProcessing ? (
            <CircularProgress
              size="1.5rem"
              sx={{
                color: 'white',
              }}
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateEmailTemplatePrompt;
