import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const Spinner = ({ fixed, ...props }) => {
  const classes = useStyles();

  return <CircularProgress {...props} className={fixed && classes.fixed} />;
};

Spinner.propTypes = {
  fixed: PropTypes.bool,
};

export default Spinner;
