import appendHeaderFooterToHtml from './appendHeaderAndFooterToHtml';
import extractDomainFromEmail from './extractDomainFromEmail';
import getHeaderFooterForDomain from './getHeaderFooterForDomain';
import replacePlaceholders from './replacePlaceholders';

const formatEmail = email => {
  if (!email) return '';

  const sanitizedEmail = email.toLowerCase(); // Convert to lowercase
  return `<a href="mailto:${sanitizedEmail}">${sanitizedEmail}</a>`;
};
const formatURL = (url, text) => {
  if (!url) return '';

  // Check if the URL starts with "http://" or "https://", if not, append "http://"
  const prefixedURL = url.match(/^https?:\/\//) ? url : 'http://' + url;
  return `<a href="${prefixedURL}" target="_blank" rel="noopener noreferrer">${
    text || url
  }</a>`;
};
const replacePlaceholdersForEmail = (htmlString, from) => {
  const domain = extractDomainFromEmail(from.email);
  const replacers = getHeaderFooterForDomain(domain);
  if (!htmlString) return '';
  htmlString = appendHeaderFooterToHtml(
    htmlString,
    replacers?.header || '',
    replacers?.footer || '',
  );

  const customProperties = {
    FIRST_NAME: 'First Name Cool',
    last_name: 'Last Name',
    EMAIL: 'a@mail.com',
  };
  const placeholder = {
    ...customProperties,
    DATE_TIME: new Date().toLocaleString(),
    SITE: formatURL(`https://${domain}`),
    CONTACT_EMAIL: formatEmail('YOUR.EMAIL@YOURDOMAIN.COM'),
    PRIVACY_POLICY: formatURL(
      `https://${domain}/privacy-policy/`,
      'PRIVACY POLICY',
    ),
    TERMS_OF_SERVICE: formatURL(
      `https://${domain}/terms-of-service/`,
      'TERMS OF SERVICE',
    ),
    UNSUBSCRIBE: formatURL(`${from.unsubURL}`, 'unsubscribe here'),
  };
  const result = replacePlaceholders(htmlString, placeholder);
  return result;
};

export default replacePlaceholdersForEmail;
