import {
  dontMissMail,
  informInInbox,
  offersInInbox,
  thanksReply,
} from './replacersV2';

const getHeaderFooterForDomain = domain => {
  // Define your email templates here
  const emailTemplates = {
    'thanksreply.com': thanksReply,
    'offersininbox.com': offersInInbox,
    'informinbox.com': informInInbox,
    'dontmissmail.com': dontMissMail,
  };
  return emailTemplates[domain?.toLowerCase()];
};

export default getHeaderFooterForDomain;
