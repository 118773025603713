import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Spinner from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import FieldGroup from '../../components/FieldGroup';

const PixelCode = ({ pixelId }) => {
  const [textToCopy, setTextToCopy] = useState('');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied to clipboard successfully!', {
      variant: 'success',
    });
  }, [enqueueSnackbar]);

  const { data: pixel } = useQuery(
    ['pixel', pixelId],
    () => axios.get(`/pixels/${pixelId}`),
    {
      onSuccess: pixel => {
        setTextToCopy(
          `<link rel="stylesheet" href="https://${pixel.pixel_subdomain}.${process.env.REACT_APP_PIXEL_ENDPOINT}?pId=${pixel.uuid}&eml={{email}}">`,
        );
      },
      onError: () => {
        history.push('/404');
      },
    },
  );

  if (!pixel) return <Spinner fixed />;

  return (
    <>
      <PageHeader title={'Copy Pixel Code'} />
      <Paper sx={{ padding: 3.5, borderRadius: '6px', maxWidth: 730 }}>
        <FieldGroup title="Pixel Details">
          <TextField label="Pixel Name" value={pixel.pixel_name} disabled />

          <TextField
            multiline
            rows={4}
            label="Description"
            value={pixel.description}
            disabled
          />
        </FieldGroup>

        <TextField
          rows={4}
          multiline
          margin="none"
          variant="outlined"
          value={textToCopy}
          InputProps={{ readOnly: true }}
        />

        <Box display="flex" justifyContent="space-between" marginTop={3}>
          <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
            <Button>Copy Code</Button>
          </CopyToClipboard>
          <Button variant="contained" onClick={() => history.push('/pixel')}>
            Done
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default PixelCode;
