import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const FormSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  from: yup.string().email('Invalid email').required('From is required'),
});
const SendAllFormSchema = yup.object().shape({
  SendAll: yup.array().of(
    yup.object().shape({
      subject: yup.string().required('Required'),
      from: yup.object().shape({
        id: yup.string().required('Required'),
        email: yup.string().required('Required'),
        name: yup.string().required('Required'),
        company: yup.string().required('Required'),
      }),
      company: yup.string().required('Required'),
      weight: yup
        .number()
        .typeError('Please enter a valid number')
        .min(0, 'Weight should be at least 0')
        .max(100, 'Weight should not be more than 100')
        .required('This field is required'),
      templateId: yup.string().required('Required'),
    }),
  ),
});
export const FormResolver = yupResolver(FormSchema);
export const SendAllFormResolver = yupResolver(SendAllFormSchema);
