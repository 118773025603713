import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  emailFormContainer: {
    width: props => props.width || '500px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
  },
  button: {
    width: '150px',
    animation: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  '@keyframes blink': {
    // Defining the keyframes animation
    '0%': {
      backgroundColor: '#CE1FBE',
      boxShadow: '0 0 5px #CE1FBE',
    },
    '50%': {
      backgroundColor: '#A52882',
      boxShadow: '0 0 15px #A52882',
    },
    '100%': {
      backgroundColor: '#CE1FBE',
      boxShadow: '0 0 5px #CE1FBE',
    },
  },
  unsavedChanges: {
    animationName: '$blink',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
}));

export default useStyles;
