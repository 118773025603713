import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  statusSwitch: {
    margin: 0,
  },
  statusSwitchTrack: {
    opacity: 1,
    backgroundColor: theme.palette.error.main,
    '.MuiSwitch-switchBase.Mui-checked + &': {
      opacity: 1,
      backgroundColor: theme.palette.success.main,
    },
  },
}));
