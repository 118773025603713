export const formatPartnerCompanyName = partnerCompanyName => {
  return partnerCompanyName.replace(/\s/g, '').toLowerCase();
};

export const generateDynamicNameFromDomain = (domain, partnerCompanyName) => {
  // eslint-disable-next-line no-unused-vars
  const firstDotIndex = domain?.indexOf('.');
  const filteredDomain =
    firstDotIndex !== -1 ? domain?.slice(0, firstDotIndex) : domain;
  const formattedDomain = filteredDomain
    ?.split('.')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join('.');

  const formattedPartnerCompanyName =
    formatPartnerCompanyName(partnerCompanyName);

  const formattedPartnerCompany =
    formattedPartnerCompanyName.charAt(0).toUpperCase() +
    formattedPartnerCompanyName.slice(1);

  return {
    via: `${formattedPartnerCompany.toUpperCase()} via ${formattedDomain}.com`,
    email: `${formattedPartnerCompanyName}@${domain?.toLowerCase()}`,
    company: partnerCompanyName,
  };
};
