import axios from 'axios';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';

import { integrationProviders } from '../../config/integration';

function TriggerMethodDropdown({ field, form, setIsTriggerMethod }) {
  const { integration_id } = form.values;

  const {
    data: integrations = [],
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useQuery('integrations', () => axios.get('/integrations?status=1'));

  const selectedIntegration = integrations.find(
    integration => integration.id === integration_id,
  );

  const selectedProvider = integrationProviders.find(
    provider => provider.type === selectedIntegration?.type,
  );

  const hasMultipleMethods = selectedProvider
    ? Boolean(selectedProvider?.availableEndpoints?.length)
    : false;

  const isLoading = isIntegrationsLoading || isIntegrationsFetching;

  const emptyItem = useMemo(() => {
    if (isLoading) {
      return <MenuItem value="">Loading...</MenuItem>;
    }

    if (!field.value) {
      return <MenuItem value="">- No Selection -</MenuItem>;
    }

    return null;
  }, [field.value, isLoading]);

  if (!hasMultipleMethods) {
    setIsTriggerMethod(false);
    return null;
  }

  setIsTriggerMethod(true);

  return (
    <TextField
      {...field}
      select
      label="Trigger Method"
      value={isLoading ? '' : field.value}
      SelectProps={{
        displayEmpty: true,
        disabled: isLoading,
      }}
      helperText={form.touched[field.name] && form.errors[field.name]}
      error={form.touched[field.name] && Boolean(form.errors[field.name])}
    >
      {emptyItem}
      {selectedProvider.availableEndpoints.map((item, key) => (
        <MenuItem key={key} value={item.addValueFunction}>
          {item.methodFieldValue}
        </MenuItem>
      ))}
    </TextField>
  );
}

TriggerMethodDropdown.propTypes = {
  meta: PropTypes.object,
  form: PropTypes.object,
};

export default TriggerMethodDropdown;
