import axios from 'axios';
import { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import ListSubheader from '@material-ui/core/ListSubheader';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { integrationProviders } from '../../config/integration';

function EntityListDropdown({ field, form, setIsEntityField }) {
  const { integration_id, trigger_method } = form.values;

  const [searchText, setSearchText] = useState('');

  const {
    data: integrations = [],
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useQuery('integrations', () => axios.get('/integrations?status=1'));

  const selectedIntegration = integrations.find(
    integration => integration.id === integration_id,
  );

  const selectedProvider = integrationProviders.find(
    provider => provider.type === selectedIntegration?.type,
  );

  const hasEndpointMethods = selectedProvider
    ? Boolean(selectedProvider?.availableEndpoints)
    : false;

  const avlmthd = selectedProvider?.availableEndpoints;

  const selectedEndpointMethod = avlmthd?.find(
    label => label.addValueFunction === trigger_method,
  );

  const fldlbl = selectedEndpointMethod
    ? selectedEndpointMethod.fieldLabel
    : selectedProvider?.availableEndpoints
    ? selectedProvider.availableEndpoints[0].fieldLabel
    : null;

  const showEntitiesTextBox = selectedEndpointMethod
    ? selectedEndpointMethod.isTextFieldRequired
    : selectedProvider?.availableEndpoints
    ? selectedProvider.availableEndpoints[0].isTextFieldRequired
    : null;

  const entitiesTextBoxLabel = selectedEndpointMethod
    ? selectedEndpointMethod.textFieldLabel
    : selectedProvider?.availableEndpoints
    ? selectedProvider.availableEndpoints[0].textFieldLabel
    : null;

  const fldfnc = selectedEndpointMethod
    ? selectedEndpointMethod.fieldValueFunction
    : selectedProvider?.availableEndpoints
    ? selectedProvider.availableEndpoints[0].fieldValueFunction
    : null;

  const {
    data: integrationEntities = [],
    isLoading: isIntegrationEntitiesLoading,
    isFetching: isIntegrationEntitiesFetching,
  } = useQuery(
    ['integrationEntities', integration_id, fldfnc],
    () =>
      axios.get(`/integrations/${integration_id}/entities?fldfunc=${fldfnc}`),
    { enabled: hasEndpointMethods },
  );

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () =>
      integrationEntities.filter(option =>
        containsText(option.name, searchText),
      ),
    [searchText, integrationEntities],
  );

  const isLoading =
    isIntegrationsLoading ||
    isIntegrationsFetching ||
    isIntegrationEntitiesLoading ||
    isIntegrationEntitiesFetching;

  const emptyItem = useMemo(() => {
    if (isLoading) {
      return <MenuItem value="">Loading...</MenuItem>;
    }

    if (!field.value) {
      return <MenuItem value="">- No Selection -</MenuItem>;
    }

    return null;
  }, [field.value, isLoading]);

  if (!hasEndpointMethods) {
    setIsEntityField(false);
    return null;
  }

  setIsEntityField(true);

  return (
    <>
      {showEntitiesTextBox ? (
        <TextField
          {...field}
          label={entitiesTextBoxLabel ? entitiesTextBoxLabel : null}
          placeholder={`Enter ${entitiesTextBoxLabel}`}
          helperText={form.touched && form.error}
          error={form.touched && Boolean(form.error)}
        />
      ) : (
        <TextField
          {...field}
          select
          label={fldlbl}
          value={isLoading ? '' : field.value}
          SelectProps={{
            displayEmpty: true,
            disabled: isLoading,
          }}
          helperText={form.touched[field.name] && form.errors[field.name]}
          error={form.touched[field.name] && Boolean(form.errors[field.name])}
        >
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" variant="standard">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {emptyItem}
          {displayedOptions.map((item, key) => (
            <MenuItem key={key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}

EntityListDropdown.propTypes = {
  meta: PropTypes.object,
  form: PropTypes.object,
};

export default EntityListDropdown;
