import useCurrentPartner from './useCurrentPartner';

export const appPermissions = {
  networkIn: 'NETWORK_IN',
  publisher: 'PUBLISHER',
  apiAccess: 'API_ACCESS',
  hashAccess: 'HASH_ACCESS',
  adminRole: 'ADMIN_ROLE',
  triggerView: 'CAN_VIEW_TRIGGERS',
};

export function useAccess() {
  const partner = useCurrentPartner();
  const hasPermission = permission => {
    switch (permission) {
      case appPermissions.networkIn:
        return !!partner.network_in;
      case appPermissions.publisher:
        return !!partner.publisher;
      case appPermissions.apiAccess:
        return !!partner.api_access;
      case appPermissions.hashAccess:
        return !!partner.hash_access;
      case appPermissions.adminRole:
        return !!partner.admin_role;
      case appPermissions.triggerView:
        return !!partner.can_view_triggers;
      default:
        return true;
    }
  };

  return { hasPermission, isLoading: !partner.id };
}
