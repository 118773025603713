import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useStyles } from './styles';

const InfoBlock = ({ title, value, to }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} component={Link} to={to}>
      <Box marginRight={2}>
        <Typography noWrap variant="caption" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          sx={{ lineHeight: 1 }}
        >
          {value}
        </Typography>
      </Box>
      <ChevronRightIcon color="inherit" fontSize="small" />
    </Box>
  );
};

export default InfoBlock;
