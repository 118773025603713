import React, { useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { SendEmailNodeFormResolver } from './validation';
import { Handle, Position } from 'reactflow';
import useCurrentPartner from '../../../../hooks/useCurrentPartner';
import SendIcon from '@material-ui/icons/Send';
import { useInfiniteQuery } from 'react-query';
import {
  getCampaignDomains,
  getEmailTemplates,
} from '../../../../utils/apis/emailTemplates';
import { generateDynamicNameFromDomain } from '../../../../utils/domain';
import { MenuItem } from '@material-ui/core';
import { formatDateAgo } from '../../../../utils/date';
import extractDomainFromEmail from '../../../../utils/domains/extractDomainFromEmail';
import { isEqual } from 'lodash';
import SelectInfiniteScrollV2 from '../../../SelectInfiniteScroll';
import { filterAndAddDomain } from '../../utils';

const SendEmail = ({ handleUpdateNodeData, node }) => {
  const partner = useCurrentPartner();
  const classes = useStyles();
  const {
    register,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      from: node.data.actionDetails?.from,
      company:
        node.data.actionDetails?.from?.company ||
        partner?.partner_name ||
        partner?.details?.company_name,
      subject: node.data.actionDetails?.subject,
      templateId: node.data.actionDetails?.templateId,
    },
    delayError: 500,
    resolver: SendEmailNodeFormResolver,
  });

  const formValues = watch();

  const { data, fetchNextPage, hasNextPage, isLoading, isError } =
    useInfiniteQuery(
      ['email-templates', partner.id],
      ({ pageParam = 1 }) => getEmailTemplates(partner.id, pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.totalPages > allPages.length) {
            return allPages.length + 1;
          }
          return false;
        },
        enabled: !!partner.id,
        staleTime: 0,
      },
    );
  const {
    data: domainsData,
    fetchNextPage: fetchNextDomainsPage,
    hasNextPage: hasNextDomainsPage,
    isLoading: isLoadingDomains,
    isError: isErrorDomains,
  } = useInfiniteQuery(
    ['campaign_domains'],
    ({ pageParam = 1 }) => getCampaignDomains(partner.id, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.totalPages > allPages.length) {
          return allPages.length + 1;
        }
        return false;
      },
      enabled: !!partner.id,
      staleTime: 0,
    },
  );

  const handleTemplateChange = value => {
    setValue('templateId', value?.id, {
      shouldValidate: true,
    });
  };

  const handleCampaignFromChange = value => {
    const { via, email, company } = generateDynamicNameFromDomain(
      value?.domain,
      formValues?.company,
    );
    setValue(
      'from',
      {
        id: value?.id,
        name: via,
        email,
        company,
      },
      {
        shouldValidate: true,
      },
    );
  };

  useEffect(() => {
    if (isValid) {
      const domain = extractDomainFromEmail(formValues.from?.email);
      const { via, email, company } = generateDynamicNameFromDomain(
        domain,
        formValues?.company,
      );

      const formattedFrom = {
        ...formValues.from,
        name: via,
        email,
        company,
      };
      const updatedData = {
        ...node.data,
        actionDetails: {
          templateId: formValues?.templateId,
          subject: formValues?.subject,
          from: formattedFrom,
        },
      };
      const changed = !isEqual(updatedData, node.data);

      if (changed) {
        handleUpdateNodeData(updatedData, node);
      }
    }
  }, [node, handleUpdateNodeData, formValues, isValid]);
  const campaignDomains = useMemo(
    () => domainsData?.pages.flatMap(page => page.data) || [],
    [domainsData?.pages],
  );
  const emailTemplates = useMemo(
    () => data?.pages.flatMap(page => page.data) || [],
    [data?.pages],
  );
  // Filter to use shared domains
  const newCampaignDomains = filterAndAddDomain(campaignDomains);
  return (
    <Box className={classes.sendEmailContainer}>
      <Handle type="target" position={Position.Top} />
      <Typography variant="h6" className={classes.sendEmailTitle}>
        <span className={classes.titleSpan}>
          <SendIcon /> SEND EMAIL
        </span>
      </Typography>
      <SelectInfiniteScrollV2
        data={campaignDomains}
        isLoading={isLoadingDomains}
        selectedId={formValues.from?.id}
        isError={isErrorDomains}
        fetchNextPage={fetchNextDomainsPage}
        hasNextPage={hasNextDomainsPage}
        onChange={handleCampaignFromChange}
        optionProperty={['name', 'domain']}
        renderOption={(props, option) => {
          const { via, email } = generateDynamicNameFromDomain(
            option?.domain,
            formValues?.company,
          );

          return (
            <MenuItem {...props} key={option.id}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight="500"
                  fontSize="12px"
                  component="p"
                >
                  {via}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  ml="8px"
                  fontSize="12px"
                  fontWeight="400"
                >
                  {`<${email}>`}
                </Typography>
              </Box>
            </MenuItem>
          );
        }}
        label="From"
      />
      <TextField
        label="Subject"
        placeholder="Enter subject"
        size="small"
        {...register('subject')}
        error={!!errors.subject}
        helperText={errors.subject?.message}
      />
      <TextField
        label="Company"
        size="small"
        placeholder="Enter company name"
        {...register('company')}
        error={!!errors?.company}
        helperText={errors.company?.message}
      />

      <SelectInfiniteScrollV2
        data={emailTemplates}
        isLoading={isLoading}
        selectedId={formValues?.templateId}
        isError={isError}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        onChange={handleTemplateChange}
        renderOption={(props, option) => {
          let optionLabel = option['template_name'];
          const createdAt = option['date_created'];
          const dateAgo = formatDateAgo(createdAt);
          return (
            <Box component="li" {...props} key={option.id}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontWeight="500"
                  fontSize="12px"
                  component="p"
                >
                  {optionLabel}:{' '}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  ml="8px"
                  fontSize="12px"
                  fontWeight="400"
                >
                  Created {dateAgo}
                </Typography>
              </Box>
            </Box>
          );
        }}
        optionProperty="template_name"
        label="Creative"
      />

      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

export default SendEmail;
