import { Box, Button, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EmailEditor from 'react-email-editor';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import UpdateEmailTemplatePrompt from '../UpdateEmailTemplatePrompt';
import {
  API_BASE_URL,
  fetchTemplateById,
} from '../../utils/apis/adminTemplates';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import options from '../EmailEditor/config/options';
import appearance from '../EmailEditor/config/appearance';

const EditAdminTemplate = props => {
  const queryClient = useQueryClient();
  const { customId } = useParams();
  const [open, setOpen] = useState(false);
  const [templateHtml, setTemplateHtml] = useState('');
  const [templateObject, setTemplateObject] = useState('');
  const emailEditorRef = useRef(null);
  const partner = useCurrentPartner();

  const { data, error, isLoading } = useQuery(
    ['template', customId],
    async () => await fetchTemplateById(customId),
  );
  const mutation = useMutation(
    newData =>
      axios.patch(`${API_BASE_URL}/templates/${data?.id}/modify`, newData),
    {
      onMutate: async newData => {
        const previousData = queryClient.getQueryData('fetchEmailTemp');
        queryClient.setQueryData('fetchEmailTemp', oldData =>
          updateFunction(oldData, newData),
        );
        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries('fetchEmailTemp');
      },
      onSettled: (data, error, context) => {
        if (error) {
          queryClient.setQueryData('fetchEmailTemp', context.previousData);
        } else {
          setOpen(false);
        }
      },
    },
  );
  useEffect(() => {
    setTemplateHtml(data?.template_html);
    setTemplateObject(data?.template_object);
  }, [data]);
  const updateTemplateObjectState = () => {
    emailEditorRef.current?.editor?.exportHtml(data => {
      const { design, html } = data;
      setTemplateObject(design);
      setTemplateHtml(html);
      setOpen(true);
    });
  };
  const handleOpen = () => {
    updateTemplateObjectState();
  };
  const loadDesign = () => {
    emailEditorRef.current?.editor?.loadDesign(templateObject);
  };

  const handleUpdateTemp = async (templateTitle, base64Image) => {
    const updatedData = {
      customTitle: templateTitle,
      templateObject,
      thumbnail: base64Image,
      templateHtml,
      templateId: customId,
      editorId: partner.id,
    };

    try {
      await mutation.mutateAsync(updatedData);
    } catch (error) {}
  };

  const updateFunction = (oldData, newData) => {
    return oldData?.map(item =>
      item.id === newData.id ? { ...newData } : item,
    );
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">{data?.custom_title}</Typography>
        <Box sx={{ display: 'flex', gap: 5 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#E00001 ' }}
            onClick={handleOpen}
          >
            Update template
          </Button>
          {open ? (
            <UpdateEmailTemplatePrompt
              updateProcessing={mutation.isLoading}
              dialogState={open}
              title={data?.custom_title}
              thumbnail={data?.template_thumbnail}
              handleUpdateTemp={handleUpdateTemp}
              handleDialogClose={() => setOpen(false)}
            />
          ) : null}
        </Box>
      </Box>

      <EmailEditor
        ref={emailEditorRef}
        minHeight={800}
        onLoad={loadDesign}
        appearance={appearance}
        options={options}
      />
    </div>
  );
};

export default EditAdminTemplate;
