import axios from 'axios';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useState } from 'react';

const CreditBuyingOptions = [
  {
    creditValue: '62,500',
    cpmValue: 8,
    price: '500.00',
    addon_id: '62500-credits-internal',
    addon_credits: '62500',
  },
  {
    creditValue: '285,000',
    cpmValue: 7,
    price: '2000.00',
    addon_id: '285000-credits-internal',
    addon_credits: '285000',
  },
  {
    creditValue: '830,000',
    cpmValue: 6,
    price: '5000.00',
    addon_id: '830000-credits-internal',
    addon_credits: '830000',
  },
  {
    creditValue: '2,000,000',
    cpmValue: 5,
    price: '10000.00',
    addon_id: '2000000-credits-internal',
    addon_credits: '2000000',
  },
];

const BuyCreditDialog = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const partner = useCurrentPartner();
  const queryClient = useQueryClient();
  const [selectedCreditOption, setSelectedCreditOption] = useState(null);

  const { mutateAsync: buyAdditionalCredits } = useMutation(
    data =>
      axios.put('/partner/credits', {
        subscription_id: data.subscription_id,
        addon_id: data.addon_id,
        addon_quantity: data.addon_quantity,
        addon_credits: data.addon_credits,
      }),
    {
      onSuccess: async res => {
        onClose();
        if (res.statusCode == 200 && res.message == 'Purchase successful') {
          enqueueSnackbar(
            `Your purchase of ${selectedCreditOption} was successful`,
            {
              variant: 'success',
            },
          );
          const partnerData = await axios.get('/partner');
          queryClient.setQueryData('currentPartner', prevData => ({
            ...prevData,
            ...partnerData,
          }));
        } else if (
          res.statusCode == 400 &&
          res.message == 'Purchase unsuccessful'
        ) {
          enqueueSnackbar(
            `Your purchase of ${selectedCreditOption} was not successful`,
            {
              variant: 'error',
            },
          );
        }
      },
      onError: async err => {
        enqueueSnackbar(`Something went wrong`, {
          variant: 'error',
        });
        onClose();
      },
    },
  );

  const renderCreditBuyingLabel = option => {
    return (
      <>
        <Typography component="div" sx={{ paddingBottom: 0.5 }}>
          {option.creditValue} Credits (${option.cpmValue} CPM)
        </Typography>
        <Typography component="div" variant="body2" color="textSecondary">
          ${option.price}
        </Typography>
      </>
    );
  };

  const buyCreditHandleChange = event => {
    const creditOption = CreditBuyingOptions.find(
      option => option.creditValue == event.target.value,
    );
    setSelectedCreditOption(creditOption.creditValue);
  };

  const formik = useFormik({
    initialValues: {
      buyCredit: '',
    },
    onSubmit: values => {
      const creditOption = CreditBuyingOptions.find(
        option => option.creditValue == values.buyCredit,
      );
      buyAdditionalCredits({
        addon_id: creditOption.addon_id,
        subscription_id: partner.processor_subscription_id,
        addon_quantity: 1,
        addon_credits: creditOption.addon_credits,
      });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle> Buy Additional Credits</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Base Pricing is $10 CPM. Prepaying for credits saves money! Select your prepaid amount below. (For more than 2M Credit pricing, Contact Us)
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="buyCredit"
            value={formik.values.buyCredit}
            onChange={val => {
              formik.handleChange(val);
              buyCreditHandleChange(val);
            }}
            sx={{ marginTop: 2 }}
          >
            {CreditBuyingOptions.map(option => (
              <FormControlLabel
                value={option.creditValue}
                control={<Radio />}
                label={renderCreditBuyingLabel(option)}
                sx={{ marginTop: 1.5 }}
              />
            ))}
          </RadioGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || !selectedCreditOption}
        >
          Proceed To Checkout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyCreditDialog;
