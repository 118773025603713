import axios from 'axios';
import { Auth } from 'aws-amplify';

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

const partnerId = localStorage?.supprtId ? localStorage.supprtId : null;

axios.interceptors.request.use(async config => {
  if (config.method !== 'OPTIONS') {
    let currentSession = null;

    try {
      currentSession = await Auth.currentSession();
    } catch (err) {
      currentSession = null;
    }

    const token = currentSession?.idToken?.jwtToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      Auth.signOut();
    }

    if (partnerId != null){
      config.params = {'partner_id': partnerId };
    }
  }

  return config;
});

axios.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error?.response?.data || error),
);