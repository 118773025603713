import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import AccountDetails from './AccountDetails';
import AccountBilling from './AccountBilling';
import PageHeader from '../../components/PageHeader';

const tabs = [
  {
    key: 'details',
    label: 'Details',
    component: AccountDetails,
  },
  {
    key: 'billing',
    label: 'Billing & Usage',
    component: AccountBilling,
  },
];

const AccountSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const { tabId } = useParams();
  const [activeTab, setActiveTab] = useState(tabId || tabs[0].key);

  const { component: TabPanel } = useMemo(
    () => tabs.find(tab => tab.key === activeTab) || tabs[0],
    [activeTab],
  );

  useEffect(() => {
    history.push(`/account/settings/${activeTab}`);
  }, [activeTab, tabId, history]);

  return (
    <>
      <PageHeader title="Account" backButtonProps={{ to: '/' }} />
      <Paper className={classes.paper}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3.5 }}
        >
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            aria-label="account tabs"
          >
            {tabs.map(tab => (
              <Tab key={tab.key} label={tab.label} value={tab.key} />
            ))}
          </Tabs>
        </Box>
        <TabPanel />
      </Paper>
    </>
  );
};

export default AccountSettings;
