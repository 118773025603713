import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: props => (props.dense ? 0 : theme.spacing(3.5)),
    boxShadow: props => (props.dense ? 'none' : theme.shadows[1]),
    width: '100%',
    overflowX: 'auto',
  },
  tableOverflow: {
    overflowX: 'scroll',
  },
  tooltipIcon: {
    display: 'inline-block',
    background: '#ffffff',
    color: '#000000',
    borderRadius: '50%',
    width: '17px',
    height: '17px',
    lineHeight: '14px',
    textAlign: 'center',
    fontSize: '12px',
    border: '2px solid black',
    marginLeft: '0.5em',
    fontWeight: 600,
  },
  freezeCell: {
    position: 'sticky',
  },
}));
