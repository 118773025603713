const API_BASE_URL =
  process.env.REACT_APP_BASE_WYSIWYG_API_URL ||
  'https://dev-wysiwyg-im.smartbrandscompany.com/wysiwyg/api/v1';

export const EMAIL_TEMPLATE_TYPE = {
  IMPORTED: 'IMPORTED',
};
export const createEmailTemplate = async (partnerId, data) => {
  const apiUrl = `${API_BASE_URL}/email-templates/${partnerId}/create`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const createEmailImportedTemplate = async (partnerId, data) => {
  const apiUrl = `${API_BASE_URL}/email-templates/${partnerId}/import`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const updateEmailImportedTemplate = async data => {
  const apiUrl = `${API_BASE_URL}/email-templates/import/${data.id}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const updateEmailTemplate = async data => {
  const apiUrl = `${API_BASE_URL}/email-templates/${data.id}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
export const getEmailTemplates = async (
  partnerId,
  page = 1,
  size = 10,
  status = 1,
) => {
  const apiUrl = `${API_BASE_URL}/partners/${partnerId}/email-templates?page=${page}&size=${size}&status=${status}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getEmailTemplate = async templateId => {
  const apiUrl = `${API_BASE_URL}/email-templates/${templateId}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const deleteEmailTemplate = async templateId => {
  const apiUrl = `${API_BASE_URL}/email-templates/${templateId}/archive`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    if (response.ok) {
      return response;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getCampaignDomains = async (
  partnerId,
  page = 1,
  size = 10,
  status = 1,
) => {
  const apiUrl = `${API_BASE_URL}/partners/${partnerId}/campaign-domains?page=${page}&size=${size}&status=${status}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
