/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import FlowOptions from './FlowOptions';
import useStyles from './styles';
import CampaignForm from './Form';
import {
  createNodeTypes,
  findModifiedActionsByOrder,
  nodeOptions,
  transformNodeData,
} from './utils';
import {
  createCampaignActions,
  updateCampaignActions,
} from '../../utils/apis/campaigns';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

let id = 0;
const getId = nodes => {
  if (nodes.length > 0) {
    // Assuming node IDs are of the form "dndnode_#", where # is a number
    const highestIdNode = nodes.reduce((highest, node) => {
      const currentId = parseInt(node.id.split('_')[1], 10);
      return currentId > highest ? currentId : highest;
    }, -1);
    return `dndnode_${highestIdNode + 1}`;
  }
  return `dndnode_${id++}`; // default behavior if no nodes
};
const removeElements = (elementsToRemove, elements) => {
  const nodeIdsToRemove = elementsToRemove
    .filter(el => el.type === 'node')

    .map(el => el.id);
  const edgeIdsToRemove = elementsToRemove
    .filter(el => el.type === 'edge')
    .map(el => el.id);

  const nodesRemaining = elements.filter(
    el => !nodeIdsToRemove.includes(el.id),
  );
  const edgesRemaining = elements.filter(
    el =>
      !edgeIdsToRemove.includes(el.id) &&
      !nodeIdsToRemove.includes(el.source) &&
      !nodeIdsToRemove.includes(el.target),
  );

  return [...nodesRemaining, ...edgesRemaining];
};

const CampaignActionsBuilder = ({ initialBuilderData }) => {
  const classes = useStyles();
  const partner = useCurrentPartner();
  const reactFlowWrapper = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [builderData, setBuilderData] = useState(initialBuilderData);
  const [nodes, setNodes, onNodesChange] = useNodesState(
    initialBuilderData.nodes,
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    initialBuilderData.edges,
  );
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [formData, setFormData] = useState({
    title: initialBuilderData.title,
    description: initialBuilderData.description,
  });
  const [actionEditorVisible, setActionEditorVisible] = useState(false);

  // Store node positions separately
  const [nodePositions, setNodePositions] = useState({});
  useEffect(() => {
    setBuilderData(initialBuilderData);
    setNodes(initialBuilderData.nodes);
    setEdges(initialBuilderData.edges);
    setFormData({
      title: initialBuilderData.title,
      description: initialBuilderData.description,
    });
    if (initialBuilderData.nodes && initialBuilderData.nodes.length > 0) {
      const lastNodeId =
        initialBuilderData.nodes[initialBuilderData.nodes.length - 1].id;
      setCurrentNodeId(lastNodeId);
    }
  }, [initialBuilderData, setEdges, setNodes]);

  const handleUpdateNodeData = useCallback(
    (newData, node) => {
      setNodes(nodes => {
        return nodes.map(n => {
          if (n.id === node.id) {
            return { ...n, data: { ...n.data, ...newData } };
          }
          return n;
        });
      });
    },
    [setNodes],
  );
  const nodeTypes = useMemo(
    () => createNodeTypes(handleUpdateNodeData),
    [handleUpdateNodeData],
  );
  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges],
  );

  const onElementsRemove = useCallback(
    elementsToRemove => {
      setNodes(ns => removeElements(elementsToRemove, ns));
      setEdges(es => removeElements(elementsToRemove, es));
    },
    [setEdges, setNodes],
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const actionOrder = nodes.length + 1;
      const newNodeId = getId(nodes);
      const newNode = {
        id: newNodeId,
        type,
        position,
        data: { actionType: `${type}`, actionOrder },
      };

      setNodes(nds => nds.concat(newNode));

      if (currentNodeId !== null) {
        const newEdge = {
          id: `edge-${currentNodeId}-${newNodeId}`,
          source: currentNodeId,
          target: newNodeId,
        };
        setEdges([...edges, newEdge]);
      }

      setCurrentNodeId(newNodeId);
      setNodePositions({ ...nodePositions, [newNodeId]: position });
    },
    [reactFlowInstance, setNodes, currentNodeId, setEdges, nodePositions],
  );

  const {
    mutateAsync: saveCampaignActionsData,
    isLoading: isSavingCampaignActionsData,
  } = useMutation(data => createCampaignActions(partner.id, data), {
    onSuccess: ({ data }) => {
      setBuilderData({ ...builderData, id: data?.id });
      enqueueSnackbar('Campaign action details saved successfully.', {
        variant: 'success',
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to save campaign action details.', {
        variant: 'error',
      });
    },
  });
  const { mutateAsync: updateCampaignData, isLoading: isUpdatingCampaignData } =
    useMutation(data => updateCampaignActions(data), {
      onSuccess: ({ data }) => {
        enqueueSnackbar('Campaign detail updated successfully.', {
          variant: 'success',
        });
      },
      onError: error => {
        enqueueSnackbar('Unable to update campaign details.', {
          variant: 'error',
        });
      },
    });

  const actions = useMemo(() => {
    const renderedNodes = nodes.map(node => {
      const { actionDetails, actionType, actionOrder } = node.data || {};

      // Determine if actionDetails needs to be mapped
      const shouldMapActionDetails =
        actionType === nodeOptions.SEND_ALL ||
        actionType === nodeOptions.AB_SPLIT;
      const formattedActionDetails = shouldMapActionDetails
        ? actionDetails?.map((action, index) => {
            // Find the corresponding action in builderData
            const matchingAction = builderData?.actions?.find(
              a => a.actionOrder === actionOrder && a.actionType === actionType,
            );
            const uuid = matchingAction?.actionDetails?.[index]?.uuid;

            return { ...action, uuid };
          })
        : actionDetails;

      return {
        ...node,
        data: {
          ...node.data,
          actionDetails: formattedActionDetails,
        },
        position: nodePositions[node.id],
      };
    });

    return transformNodeData(renderedNodes);
  }, [nodes, builderData, nodePositions]);

  const design = {
    nodes,
    edges,
  };

  const saveActionDetails = () => {
    const dataToSave = { id: builderData?.id, ...formData, design, actions };
    if (dataToSave?.id) {
      const changedActionOrders = findModifiedActionsByOrder(
        builderData.actions,
        actions,
      );

      updateCampaignData({
        ...dataToSave,
        changedActionOrders,
      });
    } else {
      saveCampaignActionsData(dataToSave);
    }
  };
  const handleNextClick = data => {
    setActionEditorVisible(true);
    setFormData({ ...actionEditorVisible, ...data });
  };
  const handlePreviousClick = () => {
    saveActionDetails();
    setTimeout(() => {
      setActionEditorVisible(false);
    }, 2000); // Delay of 2000 milliseconds (2 seconds)
  };

  const minimapStyle = {
    height: 120,
  };
  return (
    <>
      {!actionEditorVisible ? (
        <CampaignForm
          handleNextClick={handleNextClick}
          defaultValues={{ ...formData }}
        />
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '150px',
              }}
              onClick={handlePreviousClick}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '150px',
              }}
              onClick={saveActionDetails}
            >
              {isSavingCampaignActionsData || isUpdatingCampaignData ? (
                <CircularProgress
                  size="1.5rem"
                  sx={{
                    color: 'white',
                  }}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
          <Box className={classes.dndflow}>
            <ReactFlowProvider>
              <Box
                className={classes['reactflow-wrapper']}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onElementsRemove={onElementsRemove}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  fitView
                >
                  <MiniMap style={minimapStyle} zoomable pannable />
                  <Controls />
                  {nodes.length === 0 && (
                    <Typography align="center" variant="h6">
                      Drag actions here
                    </Typography>
                  )}
                </ReactFlow>
              </Box>
              <FlowOptions />
            </ReactFlowProvider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CampaignActionsBuilder;
