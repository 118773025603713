import React, { useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { SendTestEmailFormResolver } from './validation';
import PageHeader from '../PageHeader';
import FieldGroup from '../FieldGroup';
import { useMutation } from 'react-query';
import { sendTestEmailUpdated } from '../../utils/apis/smtp';
import { useSnackbar } from 'notistack';
import useCurrentPartner from '../../hooks/useCurrentPartner';

const SendTestEmailForm = ({ actions, data }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const partner = useCurrentPartner();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    delayError: 500,
    defaultValues: {
      email: partner?.email_address,
    },
    resolver: SendTestEmailFormResolver,
  });

  const {
    mutateAsync: sendTestEmailDetails,
    isLoading: isSendingTestEmailDetails,
  } = useMutation(data => sendTestEmailUpdated(data), {
    onSuccess: ({ data }, variables) => {
      variables?.closeDialog();
      enqueueSnackbar('Test email sent successfully.', {
        variant: 'success',
        autoHideDuration: 500,
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to send test email.', {
        variant: 'error',
        autoHideDuration: 500,
      });
    },
  });

  const sendDetails = useMemo(() => {
    return {
      ...data,
    };
  }, [data]);
  const onSubmit = handleSubmit(async ({ email }) => {
    await sendTestEmailDetails({
      ...sendDetails,
      contactEmail: email,
    });
  });
  return (
    <>
      <Box>
        <FieldGroup>
          <PageHeader title="Send Test Email" />
          <form onSubmit={onSubmit}>
            <TextField
              label="Email"
              size="small"
              placeholder="Email"
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <Box
              display="flex"
              gap="20px"
              justifyContent={isMobile ? 'center' : 'flex-end'}
              my={2}
            >
              {actions}
              <Button
                sx={{
                  width: '150px',
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {isSendingTestEmailDetails ? (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  'Send'
                )}
              </Button>
            </Box>
          </form>
        </FieldGroup>
      </Box>
    </>
  );
};

export default SendTestEmailForm;
