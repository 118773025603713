import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'notistack';

import { useStyles } from './styles';

import TransactionList from './TransactionList';
import StatusSwitch from '../../components/StatusSwitch';
import FieldGroup from '../../components/FieldGroup';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useEffect, useState } from 'react';
import Builder from '../../components/Builder';
import RouterLink from '../../components/RouterLink';

const AccountBilling = () => {
  const classes = useStyles();
  const partner = useCurrentPartner();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: chargebeeLogin, isLoading: isSessionLoading } = useMutation(
    () =>
      axios.post(`/chargebee/portal-session`, {
        redirectUrl: window.location.href,
      }),
    {
      onSuccess: response => {
        window.open(response, '_self');
      },
    },
  );

  const { mutateAsync: savePartnerDetails } = useMutation(
    data => axios.put('/partner/billing', data),
    {
      onSuccess: async details => {
        enqueueSnackbar(
          'Your Billing & Usage details have been successfully updated.',
          {
            variant: 'success',
          },
        );
        const partnerData = await axios.get('/partner');

        queryClient.setQueryData('currentPartner', prevData => ({
          ...prevData,
          ...partnerData,
        }));
      },
    },
  );

  const [autoCreditReload, setAutoCreditReload] = useState(true);
  const [rechargeValue, setRechargeValue] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');

  const [
    accountWideDailySpendLimitSwitch,
    setAccountWideDailySpendLimitSwitch,
  ] = useState(50);

  const [accountWideDailySpendLimit, setAccountWideDailySpendLimit] =
    useState(null);

  const handleRechargeAmountInputChange = event => {
    if (event.target.value > 0) {
      setErrorMsg('');
      setRechargeValue(Number(event.target.value));
    } else {
      setRechargeValue(0);
      setErrorMsg(
        'Recharge Amount must be at least $1 in order for credits to be purchased.',
      );
    }
  };

  const onChangeSwitch = event => {
    setAutoCreditReload(event.target.checked);
  };

  const onChangeSwitchDailySpendLimit = event => {
    setAccountWideDailySpendLimitSwitch(event.target.checked);
  };

  const onAccountWideDailySpendLimitChange = event => {
    updateDailySpendMath(event.target.value);
    setAccountWideDailySpendLimit(event.target.value);
  };

  const [spendMath, setSpendMath] = useState(null);

  const updateDailySpendMath = num => {
    const daily = num / 0.01;
    const formatedDaily = formatCurrency(daily);
    console.log(' FORMATTED DAILY : ', formatedDaily);
    setSpendMath(formatedDaily);
  };

  const formatCurrency = (number, style = 'decimal', options = {}) => {
    return new Intl.NumberFormat('en-US', { style: style, ...options }).format(
      number,
    );
  };

  useEffect(() => {
    setAutoCreditReload(partner.credit_reload);
    setRechargeValue(partner.recharge_amount);
    setAccountWideDailySpendLimitSwitch(
      partner.account_daily_spend_total ? true : false,
    );
    setAccountWideDailySpendLimit(partner.account_daily_spend_total || 50);
    updateDailySpendMath(partner.account_daily_spend_total || 50);
  }, [partner]);

  return (
    <>
      <Builder
        dense
        hideProgress
        onSubmit={() =>
          savePartnerDetails({
            credit_reload: autoCreditReload ? 1 : 0,
            account_daily_spend_total: accountWideDailySpendLimitSwitch
              ? accountWideDailySpendLimit
              : null,
            recharge_amount: autoCreditReload ? rechargeValue : 0,
          })
        }
        initialValues={{}}
        cancelButtonProps={{
          component: RouterLink,
          to: '/',
        }}
        actionOnTop
      >
        <FieldGroup>
          <Box sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontWeight: 700, paddingBottom: 0 }}>
              Auto Recharge Credits
            </Typography>
            <StatusSwitch
              field={{ value: autoCreditReload, onChange: onChangeSwitch }}
            />
          </Box>
          {autoCreditReload ? (
            <FieldGroup title="Recharge Amount">
              <div>
                <p style={{ margin: 0 }}>
                  Select the amount that you will be charged on Auto Recharge,
                  currently: <strong>${rechargeValue}</strong>.
                </p>
                <p>
                  Note: A dollar amount of $0 will not result in a successful
                  recharge, and no credits will be added to your account.
                </p>
              </div>
              {errorMsg ? (
                <div className={classes.errorMsg}>{errorMsg}</div>
              ) : (
                <></>
              )}
              <div className={classes.rechargeInputs}>
                $
                <TextField
                  className={classes.costText}
                  value={rechargeValue ? rechargeValue : ''}
                  onChange={handleRechargeAmountInputChange}
                  margin="none"
                  type="number"
                >
                  {rechargeValue}
                </TextField>
              </div>
            </FieldGroup>
          ) : null}
        </FieldGroup>

        <FieldGroup title="Account Daily Spend Limit">
          <StatusSwitch
            field={{
              value: accountWideDailySpendLimitSwitch,
              onChange: onChangeSwitchDailySpendLimit,
            }}
          />

          {accountWideDailySpendLimitSwitch ? (
            <FieldGroup>
              <Box sx={{ width: 150 }}>
                <Select
                  value={accountWideDailySpendLimit}
                  onChange={onAccountWideDailySpendLimitChange}
                  name="account_daily_spend_total"
                  variant="outlined"
                >
                  <MenuItem value={50}>$50</MenuItem>
                  <MenuItem value={100}>$100</MenuItem>
                  <MenuItem value={200}>$200</MenuItem>
                  <MenuItem value={500}>$500</MenuItem>
                  <MenuItem value={1000}>$1000</MenuItem>
                  <MenuItem value={2500}>$2500</MenuItem>
                  {![50, 100, 200, 500, 1000, 2500].includes(
                    accountWideDailySpendLimit,
                  ) && (
                    <MenuItem value={accountWideDailySpendLimit} disabled>
                      ${accountWideDailySpendLimit}
                    </MenuItem>
                  )}
                </Select>
              </Box>
              <Box>
                <FieldGroup
                  description={
                    <>
                      Your daily spend limit is calculated against a $10 CPM,
                      regardless of CPM at time of purchase. With your current
                      spend limit, you can expect about{' '}
                      <strong>{spendMath}</strong> triggers per day.
                    </>
                  }
                ></FieldGroup>
              </Box>
            </FieldGroup>
          ) : null}
        </FieldGroup>
      </Builder>
      <FieldGroup
        title="Subscription & Billing Management"
        description={
          <>
            To manage your subscription and payment method, please{' '}
            <Link
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={chargebeeLogin}
            >
              click here.
            </Link>
          </>
        }
      >
        {isSessionLoading && <LinearProgress />}
      </FieldGroup>

      <FieldGroup
        title="Transactions"
        description="A list of the most recent transactions associated with your subscription."
      >
        <TransactionList />
      </FieldGroup>
    </>
  );
};

export default AccountBilling;
