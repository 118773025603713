import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    maxWidth: '500px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    textAlign: 'center',
  },
  uploadIcon: {
    fontSize: '4rem',
    color: theme.palette.primary.main,
  },
  selectButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1.5, 4),
    borderRadius: '4px',
    fontSize: '1.2rem',
  },
  fileInput: {
    display: 'none',
  },
}));

const ImportDialog = ({ isOpen, onClose, onProceed }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState('');

  const handleFileInputChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleClose = () => {
    setSelectedFile(''); // Clear the selected file
    onClose();
  };

  const handleProceed = () => {
    onProceed(selectedFile);
    handleClose(); // Clear the selected file
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: classes.dialogContainer }}
    >
      <div className={classes.dialogHeader}>
        <IconButton onClick={handleClose} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.uploadContainer}>
          <CloudUploadIcon className={classes.uploadIcon} />
          <label className={classes.selectButton} htmlFor="fileInput">
            Select an HTML file to import
          </label>
          <input
            id="fileInput"
            type="file"
            accept=".html"
            onChange={handleFileInputChange}
            className={classes.fileInput}
          />
        </div>
        {selectedFile?.name && (
          <Typography variant="body2" color="textSecondary">
            Selected file: {selectedFile?.name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleProceed} color="primary" variant="contained">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
