import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const formatDate = (date, format = 'MM/DD/YYYY hh:mmA EST') =>
  dayjs.utc(date).tz('America/New_York').format(format);

export const formatDateAgo = date => {
  const now = dayjs.utc().tz('America/New_York');
  const targetDate = dayjs.utc(date).tz('America/New_York');
  const distance = targetDate.from(now);
  return distance;
};
