import { createTheme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { makeStyles } from '@material-ui/core/styles';

export const CustomToolTipStyles = makeStyles(theme => ({
  arrow: {
    color: '#000000',
  },
  tooltip: {
    backgroundColor: '#000000',
  },
}));

const theme = createTheme({
  typography: {
    base: 14,
    fontFamily: `'Inter', 'Helvetica', 'Arial', sans-serif`,
  },
  palette: {
    primary: { main: 'rgb(224, 0, 1)' },
    secondary: { main: 'rgb(151, 161, 205)' },
    background: {
      default: 'rgb(245, 246, 250)',
    },
    text: {
      primary: 'rgb(19, 21, 35)',
      secondary: 'rgb(90, 96, 127)',
    },
    divider: 'rgb(215, 219, 236)',
    grey: {
      300: 'rgb(155, 155, 155)',
      400: 'rgb(232, 232, 232)',
      500: 'rgb(217, 217, 217)',
      700: 'rgb(140, 140, 140)',
      800: 'rgb(89, 89, 89)',
      900: 'rgb(38, 38, 38)',
    },
    action: {
      selected: 'rgba(224, 0, 1, 0.05)',
    },
  },
  shape: {
    borderRadius: 4,
  },
  shadows: ['none', '0 1px 4px 0 rgba(21, 34, 50, 0.08)', ...shadows.slice(2)],
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          fontWeight: 400,
          fontSize: '1rem',
          minWidth: 'auto !important',
          textTransform: 'none',
          padding: '0 0 16px 0',
          marginRight: 32,
          '&:last-of-type': {
            marginRight: 0,
          },
          '&.Mui-selected': {
            color: 'rgb(51, 55, 82)',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '1rem',
          boxShadow: 'none',
          textTransform: 'none',
        },
        outlinedSecondary: {
          color: 'rgb(51, 55, 82)',
          backgroundColor: 'rgb(255, 255, 255)',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        keepMounted: false,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: { vertical: 'top', horizontal: 'center' },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.870rem',
          height: '2rem',
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },
      styleOverrides: {
        root: {
          padding: '16px 24px',
          justifyContent: 'space-between',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        margin: 'normal',
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          display: 'block',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(217, 225, 236)',
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
          width: '100%',
        },
        multiline: {
          padding: 0,
        },
        input: {
          padding: '8px 16px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& > label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::placeholder':
            {
              opacity: '1 !important',
              color: 'rgb(161, 167, 196) !important',
            },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          fontSize: '0.875rem',
          transform: 'translate(0, -4px)',
        },
        filled: {
          zIndex: 0,
          pointerEvents: 'inherit',
          transform: 'translate(0, -4px)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0, -4px)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          padding: '13px 8px',
          borderColor: 'rgb(230, 233, 244)',
        },
        body: {
          '&:first-of-type': {
            fontWeight: 500,
          },
        },
        head: {
          color: 'rgb(90, 96, 127)',
          padding: '12px 8px',
          borderWidth: 2,
        },
      },
    },
  },
});

export default theme;
