import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import TriggerList from './TriggerList';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';
import Banner from '../../components/Banner';

const TriggerManager = () => {
  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/how-to-create-a-trigger/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader
        title="Trigger Management"
        buttons={[
          <Button
            color="primary"
            to="/trigger-manager/add"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <TriggerList />
    </>
  );
};

export default TriggerManager;

/*
import qs from 'qs';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import TriggerList from './TriggerList';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';
import Banner from '../../components/Banner';

const TriggerManager = () => {
  const [query, setQuery] = useState({ page: 1 });
  console.log('query:', query);
  const {
    data: triggers,
    isLoading,
    isFetching,
  } = useQuery(
    ['triggerList', query],
    () => axios.get(`/triggers?${qs.stringify(query)}`),
    { keepPreviousData: true },
  );
  console.log('triggers:', triggers);

  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/how-to-create-a-trigger/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader
        title="Trigger Management"
        buttons={[
          <Button
            color="primary"
            to="/trigger-manager/add"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <TriggerList
      isLoading={isLoading || isFetching}
      rows={triggers?.content || []}
      totalElements={triggers?.totalElements}
      totalPages={triggers?.totalPages}
      onQueryChange={setQuery}
      />
    </>
  );
};

export default TriggerManager;
*/
