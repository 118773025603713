import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const PixelCodeDialog = ({ open, pixel = {}, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied to clipboard successfully!', {
      variant: 'success',
    });
  }, [enqueueSnackbar]);

  const pixelUrl = `https://${pixel.pixel_subdomain}.${process.env.REACT_APP_PIXEL_ENDPOINT}?pId=${pixel.uuid}&eml={{email}}`;
  const textToCopy = `<link rel="stylesheet" href="${pixelUrl}">`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle id="generate-pixel-code-dialog-title">
        Copy Pixel Code
      </DialogTitle>
      <DialogContent>
        <TextField
          rows={4}
          multiline
          margin="none"
          variant="outlined"
          value={textToCopy}
          InputProps={{ readOnly: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>

        <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
          <Button size="large" color="primary" variant="contained">
            Copy Code
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
};

export default PixelCodeDialog;
