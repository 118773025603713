import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  statusSuccess: {
    color: 'rgb(6, 165, 97)',
    backgroundColor: 'rgb(196, 248, 226)',
    borderRadius: theme.shape.borderRadius,
  },
  statusWarning: {
    color: 'rgb(249, 150, 0)',
    backgroundColor: 'rgb(255, 244, 201)',
    borderRadius: theme.shape.borderRadius,
  },
  statusPaused: {
    color: 'rgb(235 246 255)',
    backgroundColor: 'rgb(30, 146, 244)',
    borderRadius: theme.shape.borderRadius,
  },
}));
