import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContentText from '@material-ui/core/DialogContentText';

const RowAction = ({
  icon,
  okText,
  cancelText,
  buttonLabel,
  actionLabel,
  entityLabel,
  queryKey,
  onSuccess,
  queryFunction,
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { mutate: action, isLoading } = useMutation(queryFunction, {
    onSuccess: data => {
      queryKey && queryClient.invalidateQueries(queryKey);

      handleClose();

      if (onSuccess) {
        onSuccess(data, queryClient);
      }
    },
  });

  return (
    <>
      <Box sx={{ display: 'inherit' }} onClick={handleOpen}>
        {icon ?? buttonLabel}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        {isLoading && <LinearProgress />}
        <DialogTitle>
          {buttonLabel} {entityLabel}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {actionLabel} this {entityLabel}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            {cancelText ?? 'No'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={() => action()}
          >
            {okText ?? 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RowAction;
