import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  refreshButton: {
    width: 30, // Set the width
    height: 30, // Set the height
    marginLeft: 'auto', // Align to the right
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Add box shadow
    '&:hover': {
      backgroundColor: 'transparent', // Remove background color on hover
    },
    '&:hover svg': {
      color: theme.palette.primary.main, // Change icon color on hover
    },
  },
}));
