import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const StatusPage = ({ text, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h1"
          className={classes.text}
          sx={{ fontSize: '24px', fontWeight: 600 }}
        >
          {text}
        </Typography>
        {description && (
          <Typography
            variant="h2"
            className={classes.description}
            sx={{ fontSize: '14px' }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </div>
  );
};

StatusPage.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
};

export default StatusPage;
