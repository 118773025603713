/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import PageHeader from '../../components/PageHeader';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './styles';
// import '../../dtButtons.css';

const ReportsAll = ({ exportFunc }) => {
  const tableRef = useRef(null);
  const [render, setRender] = useState(false);
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        data: 'id',
        defaultContent: '-',
        orderable: false,
        render: function (data) {
          return `<span class="${classes.id}">${data}</span>`;
        },
      },
      {
        title: 'Status',
        data: 'status',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          if (data === 'working' || data === 'pending')
            return `<span class="${classes.working}">${data}</span>`;
          return `<span class="${
            data === 'success' ? classes.success : classes.fail
          }">${data}</span>`;
        },
      },
      {
        title: 'Report Type',
        data: 'report_type',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          if (!data) return;
          return `<span class="${classes.type}">${data}</span>`;
        },
      },
      {
        title: 'Filters',
        data: 'search_filters',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          if (data === 'NULL' || !data) return '-';
          const jsonData = JSON.parse(data);
          const filtersList = filtersFormating(jsonData.filters);
          return `<div class="${classes.filterBlock}">
          <p class="${classes.filter}"><strong>From:</strong> ${
            jsonData.from
          }</p>
          <p class="${classes.filter}"><strong>To:</strong> ${jsonData.to}</p>
          ${filtersList.join('\n')}
          </div>`;
        },
      },
      {
        title: 'Row Count',
        data: 'row_count',
        defaultContent: '-',
        orderable: false,
        render: function (data) {
          if (isNaN(data)) return "-";
          return data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        title: 'Download',
        data: 's3_url',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return downloadBtnStyle(data);
        },
      },
      {
        title: 'Date Created',
        data: 'created_at',
        defaultContent: '<i>No value</i>',
        orderable: false,
      },
    ],
    [],
  );

  function filtersFormating(input) {
    const filterArr = [];
    Object.entries(input).forEach(([key, value]) => {
      if (key === 'CAST(status_code AS TEXT)')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Status Code:</strong> ${value}</p>`,
        );
      if (key === 'list_name')
        filterArr.push(
          `<p class="${classes.filter}"><strong>List Name:</strong> ${value}</p>`,
        );
      if (key === 'email_address')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Email Address:</strong> ${value}</p>`,
        );
      if (key === 'uuid')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Pixel ID:</strong> ${value}</p>`,
        );
      if (key === 'integration_name')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Integration Name:</strong> ${value}</p>`,
        );
      if (key === 'response_text')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Response Text:</strong> ${value}</p>`,
        );
      if (key === 'date_created::TEXT')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Date Created:</strong> ${value}</p>`,
        );
      if (key === 'date_sent::TEXT')
        filterArr.push(
          `<p class="${classes.filter}"><strong>Date-Time Sent:</strong> ${value}</p>`,
        );
    });
    return filterArr;
  }

  function downloadBtnStyle(data) {
    return `<div class="${classes.colStyle}"><a class="${
      data ? classes.active : classes.inactive
    }" href="${data ? data : ''}">Download</a></div>`;
  }

  const fetchData = async params => {
    try {
      const response = await axios.post(
        `/reports/outgoingNotifications/?view=exportsView&export=yes`,
        { ...params },
      );

      console.log(response.data);
      return response;
    } catch (err) {
      console.error('REQUEST timed out or failed: ', err);
    }
  };

  useEffect(() => {
    const initializeDataTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        const table = $(tableRef.current).DataTable({
          serverSide: true,
          processing: true,
          columns: columns,
          dom: '<"d-flex align-items-center dt-controls" l <"date-range-inputs"> B>rtip',
          responsive: true,
          scrollX: true,
          ajax: (data, callback, settings) => {
            fetchData(data)
              .then(result => {
                console.log('RESULT: ', result);
                callback({
                  draw: data.draw,
                  recordsTotal: result.recordsTotal || result.length,
                  recordsFiltered: result.recordsFiltered || result.length,
                  data: result.data,
                });
              })
              .catch(error => {
                console.error('Error fetching data: ', error);
                callback({
                  draw: data.draw,
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                });
              });
          },
          language: {
            infoFiltered: '',
            processing: ReactDOMServer.renderToString(<CircularProgress />),
          },
          buttons: [],
          initComplete: function (settings, json) {
            const api = this.api();
            applyCustomStyles(api);
            api.draw();

            setTimeout(() => {
              applyCustomStyles(api);
            }, 0);
          },
          drawCallback: function (settings) {
            const api = this.api();
            applyCustomStyles(api);

            // Hide pagination if there are no results
            const recordsTotal = settings._iRecordsDisplay;
            if (!recordsTotal) {
              $(api.table().container()).find('ul.pagination').hide();
            } else {
              $(api.table().container()).find('ul.pagination').show();
            }
          },
        });
      }
    };

    initializeDataTable();

    window.addEventListener('resize', initializeDataTable);

    return () => {
      window.removeEventListener('resize', initializeDataTable);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [render]);

  function applyCustomStyles(api) {
    $(api.table().container()).find('.makeStyles-paper-35').css({
      'max-width': '1200px',
    });
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('.date-rage-input').css({
      padding: '8px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
      'border-bottom': '2px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
    });

    $(api.table().container()).find('tfoot tr th').css({
      'border-bottom': '2px solid #dee2e6',
    });

    $(api.table().container()).find('.dt-info').css({
      'padding-left': '10px',
    });
    $(api.table().container()).find('tbody tr td').css({
      'padding-left': '5px',
    });

    $(api.table().container())
      .find('tbody tr td:last-child, thead tr th:last-child')
      .css({
        'border-right': 'none',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
      )
      .css({
        'border-right': '1px solid #dee2e6',
        'background-color': 'inherit',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
      )
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
      .css({
        'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
      });

    // Apply custom styles for pagination controls
    $(api.table().container()).find('ul.pagination').css({
      display: 'flex', // Use flexbox to align items horizontally
      justifyContent: 'center', // Center align pagination controls
      padding: '0', // Remove default padding
      marginTop: '10px', // Add some spacing above the pagination controls
      listStyle: 'none', // Remove default list styling
    });

    $(api.table().container()).find('.date-range-inputs').css({
      'font-size': '14px',
      'font-weight': 'bold',
    });

    $(api.table().container()).find('ul.pagination li').css({
      display: 'inline',
      margin: '0 2px', // Add spacing between pagination items
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block', // Ensure links are inline
      padding: '5px 10px', // Add padding for clickable area
      border: '1px solid #ddd', // Border for pagination items
      borderRadius: '5px', // Rounded corners for pagination items
      textDecoration: 'none', // Remove underline from links
      color: '#333', // Text color
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001', // Highlight background color for the active page
      color: 'white', // Text color for active page
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1', // Background color on hover
      color: '#333', // Text color on hover
    });

    $(api.table().container()).find('.dt-controls').css({
      display: 'flex',
      alignItems: 'center',
    });

    // Increase size for all inputs with ids matching dt-search-*
    $(api.table().container()).find('input[id^="dt-search-"]').css({
      height: '2.5rem',
      width: '15rem',
    });

    // Increase size for all selects with ids matching dt-length-*
    $(api.table().container()).find('select[id^="dt-length-"]').css({
      height: '2.5rem',
      width: '3rem',
    });

    // Apply flexbox to div.dt-length
    $(api.table().container()).find('div.dt-length').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('div.dt-search').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#4CAF50',
      'border-width': '0',
      'border-radius': '5px',
      color: 'white',
    });

    $(api.table().container()).find('.search-instruction').css({
      'font-size': '12px',
      'font-weight': 'normal',
      'margin-left': '12px',
      'margin-right': '12px',
      color: '#888',
    });
  }

  return (
    <>
      <PageHeader title="Reports Downloads" />
      <Box>
        <h4>
          Downloads may take a moment to complete. If Status is <i>Pending</i>,
          please refresh the page.
          <br />
          Note: Downloads are only kept from the last 7 days.
        </h4>
      </Box>
      <Paper className={classes.paper}>
        <Box>
          <table
            ref={tableRef}
            className="display"
            style={{ width: '100%' }}
          ></table>
        </Box>
      </Paper>
    </>
  );
};

export default ReportsAll;
