import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    flex: 'auto',
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: theme.spacing(3.5),
    borderRadius: 6,
  },
  formContainer: {
    maxWidth: props => (props.dense ? '100%' : 730),
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formactionsOnTop: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  },
}));
