import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import { CustomToolTipStyles } from '../../styles/theme';

import { useStyles } from './styles';

const FieldGroup = ({ title, description, tooltipMessage, children }) => {
  const classes = useStyles();
  const customToolTipClasses = CustomToolTipStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <Typography
          component="div"
          sx={{ fontWeight: 700, paddingBottom: description ? 0.5 : 0 }}
        >
          {title}
        </Typography>
        {tooltipMessage ? (
          <Tooltip title={tooltipMessage} arrow classes={customToolTipClasses}>
            <span className={classes.tooltipIcon}>?</span>
          </Tooltip>
        ) : null}
      </Box>
      {description && (
        <Typography component="div" variant="body2" color="textSecondary">
          {description}
        </Typography>
      )}
      <Box paddingBottom={1} />
      {children}
    </div>
  );
};

FieldGroup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FieldGroup;
