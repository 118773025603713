import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useCallback, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useStyles } from './styles';
import PageHeader from '../PageHeader';
import ScrollToTop from '../ScrollToTop';

const Builder = ({
  mode,
  title,
  dense,
  values,
  okText,
  onClose,
  children,
  onSubmit,
  isLoading,
  cancelText,
  showActions,
  hideProgress,
  initialValues,
  okButtonProps,
  validationSchema,
  cancelButtonProps,
  getSubmitButtonProps,
  actionOnTop,
}) => {
  const classes = useStyles({ dense });

  const cancelButton = useMemo(
    () => (
      <Button color="secondary" variant="outlined" {...cancelButtonProps}>
        {cancelText}
      </Button>
    ),
    [cancelButtonProps, cancelText],
  );

  const renderSubmit = useCallback(
    formik => (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={formik.isSubmitting || isLoading}
        children={okText}
        {...okButtonProps}
        {...getSubmitButtonProps?.(formik)}
      />
    ),
    [getSubmitButtonProps, isLoading, okButtonProps, okText],
  );
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formik => (
        <>
          <ScrollToTop />
          {title && (
            <PageHeader
              backButtonProps={cancelButtonProps}
              title={title}
              buttons={[
                <Button
                  color="primary"
                  variant="contained"
                  onClick={formik.submitForm}
                  startIcon={<DoneIcon />}
                  disabled={formik.isSubmitting || isLoading}
                  children={okText}
                  {...getSubmitButtonProps?.(formik)}
                />,
              ]}
            />
          )}
          {mode === 'dialog' && (
            <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
              {(isLoading || formik.isSubmitting) && <LinearProgress />}
              <Form autoComplete="off">
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                  {cancelButton}
                  {renderSubmit(formik)}
                </DialogActions>
              </Form>
            </Dialog>
          )}
          {mode === 'page' && (
            <>
              <Form autoComplete="off" className={classes.formContainer}>
                {actionOnTop ? (
                  <div className={classes.formactionsOnTop}>
                    {cancelButton}
                    <Box marginLeft={1.5} />
                    {renderSubmit(formik)}
                  </div>
                ) : null}
                {(isLoading || formik.isSubmitting) && !hideProgress && (
                  <LinearProgress />
                )}
                {dense ? (
                  children
                ) : (
                  <Paper className={classes.paper}>{children}</Paper>
                )}
                {showActions && (
                  <>
                    <Divider sx={{ marginY: 3.5 }} />
                    {!actionOnTop ? (
                      <div className={classes.formActions}>
                        {cancelButton}
                        <Box marginLeft={1.5} />
                        {renderSubmit(formik)}
                      </div>
                    ) : null}
                  </>
                )}
              </Form>
            </>
          )}
        </>
      )}
    </Formik>
  );
};

Builder.propTypes = {
  dense: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  okText: PropTypes.string,
  isLoading: PropTypes.bool,
  cancelText: PropTypes.string,
  showActions: PropTypes.bool,
  hideProgress: PropTypes.bool,
  okButtonProps: PropTypes.object,
  validationSchema: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  getSubmitButtonProps: PropTypes.func,
  mode: PropTypes.oneOf(['page', 'dialog']),
  initialValues: PropTypes.object.isRequired,
};

Builder.defaultProps = {
  dense: false,
  mode: 'page',
  okText: 'Save',
  showActions: true,
  cancelText: 'Cancel',
};

export default Builder;
