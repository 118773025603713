import qs from 'qs';
import { useSnackbar } from 'notistack';
import { useFormikContext } from 'formik';
import Button from '@material-ui/core/Button';

import useCloseCallback from '../../hooks/useCloseCallback';
import { useEffect } from 'react';

const AUTH_WINDOW_PARAMS = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=450,left=100,top=100`;

const IntegrationAuthFlow = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue, setStatus } = useFormikContext();

  const tokenId = useCloseCallback('id');
  const isAuthorized = Boolean(values.api_key);

  const handleOpen = () => {
    const baseAuthUrl = `${process.env.REACT_APP_BASE_API_URL}/auth/${values.type}`;
    const authorizationURL = `${baseAuthUrl}?${qs.stringify({
      redirectUri: `${baseAuthUrl}/callback`,
      state: window.btoa(
        [
          `${window.location.origin}/close?id={{tokenId}}`,
          process.env.REACT_APP_BASE_API_URL,
        ].join('|'),
      ),
    })}`;

    window.open(authorizationURL, 'IMP_AUTH_WINDOW', AUTH_WINDOW_PARAMS);
  };

  useEffect(() => {
    if (tokenId) {
      setStatus(undefined);
      setFieldValue('api_key', tokenId);
      enqueueSnackbar('You have been successfully connected', {
        variant: 'success',
      });
    }
  }, [tokenId, enqueueSnackbar, setFieldValue, setStatus]);

  useEffect(() => {
    if (!isAuthorized) {
      setStatus({
        submitProps: {
          disabled: true,
        },
      });
    }
  }, [isAuthorized, setStatus]);

  return (
    <Button
      size="small"
      color="primary"
      variant="outlined"
      onClick={handleOpen}
    >
      {isAuthorized ? 'Re-Authorize' : 'Authorize'}
    </Button>
  );
};

export default IntegrationAuthFlow;
