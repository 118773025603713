import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  paper: {
    maxWidth: 'fit-content',
    margin: '0 auto',
    overflow: 'auto',
  },
  emailPreview: {
    // Add any specific styles for the email preview content if needed
  },
});
