import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './styles';
import PageTitle from '../PageTitle';
import RouterLink from '../RouterLink';
import { Tooltip } from '@material-ui/core';
import { CustomToolTipStyles } from '../../styles/theme';

const PageHeader = ({ title, backButtonProps, buttons, tooltipMessage }) => {
  const classes = useStyles();
  const customToolTipClasses = CustomToolTipStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={3.5}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {backButtonProps && (
          <Link
            variant="body2"
            color="textSecondary"
            component={RouterLink}
            sx={{ display: 'flex', alignItems: 'center' }}
            {...backButtonProps}
          >
            <ArrowBackIcon fontSize="small" /> Back
          </Link>
        )}
        <Box display="flex" alignItems="center">
          <PageTitle>{title}</PageTitle>
          {tooltipMessage ? (
            <Tooltip
              title={tooltipMessage}
              arrow
              classes={customToolTipClasses}
            >
              <span className={classes.tooltipIcon}>?</span>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      <ul className={classes.buttonsList}>
        {buttons.map((button, key) => (
          <li key={key}>{button}</li>
        ))}
      </ul>
    </Box>
  );
};

PageHeader.propTypes = {
  backButtonProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.element).isRequired,
};

PageHeader.defaultProps = {
  buttons: [],
};

export default PageHeader;
