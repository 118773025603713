import axios from 'axios';
import * as yup from 'yup';
import { Field, setIn } from 'formik';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory, useParams } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';
import FieldGroup from '../../components/FieldGroup';
import IntegrationAuthFlow from './IntegrationAuthFlow';
import IntegrationProviderList from './IntegrationProviderList';
import IntegrationWebhookField from './IntegrationWebhookField';
import IntegrationConfigFields from './IntegrationConfigFields';
import {
  inboxMailersCampaign,
  integrationMethods,
  integrationProviders,
} from '../../config/integration';
import ModalWrapper from '../../components/ModalWrapper';
import TriggerCampaignForm from '../../components/CampaignBuilder/TriggerCampaignForm';
import CampaignIntegrationTriggerBuilder from '../../components/CampaignBuilder/CampaignIntegrationTriggerBuilder';
import { getCampaignByIntegrationId } from '../../utils/apis/campaigns';

const validationSchema = yup.object({
  name: yup.string().label('Integration Name').max(50).required(),
  method: yup.string().label('Method').required(),
});

const IntegrationBuilder = ({ mode, onClose, onSubmit }) => {
  const history = useHistory();
  const { integrationId } = useParams();
  const queryClient = useQueryClient();
  const [providerId, setProviderId] = useState('');
  const [customCampaignBuilder, setCustomCampaignBuilder] = useState(false);

  const integrationConfig = integrationProviders.find(
    provider => provider.type === providerId,
  );
  const integrationMethod =
    integrationConfig && integrationConfig.availableMethods
      ? integrationConfig.availableMethods[0]
      : '';

  const { data: integrationValues, isLoading: isIntegrationLoading } = useQuery(
    ['integration', integrationId],
    () => axios.get(`/integrations/${integrationId}`),
    {
      enabled: !!integrationId,
      onError: () => {
        history.push('/404');
      },
    },
  );
  const {
    data: { data: campaign } = { data: {} },
    error,
    isLoading,
  } = useQuery(
    ['campaign', integrationId],
    async () => await getCampaignByIntegrationId(integrationId),
    {
      enabled: !!integrationId,
      staleTime: 0,
    },
  );
  useEffect(() => {
    if (integrationValues && !providerId) {
      setProviderId(integrationValues.type);
    }
  }, [integrationValues, providerId]);

  // Handle custom campaign builder selected
  useEffect(() => {
    if (providerId === inboxMailersCampaign.type) {
      setCustomCampaignBuilder(true);
    }
  }, [onClose, providerId]);

  // Handle Close Custom Campaign Builder
  const handleCloseCustomCampaignBuilder = () => {
    setCustomCampaignBuilder(false);
    onClose?.();
  };

  const { mutateAsync: saveIntegration } = useMutation(
    data =>
      axios.request({
        url: integrationId ? `/integrations/${integrationId}` : '/integrations',
        method: integrationId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: async data => {
        await queryClient.refetchQueries('integrations');
        queryClient.invalidateQueries(['integration', integrationId]);

        if (onSubmit) {
          onSubmit(data);
        } else {
          history.push('/integrations');
        }

        onClose?.();
      },
    },
  );

  const initialValues = useMemo(
    () => ({
      method: integrationMethod,
      name: '',
      type: providerId,
      api_key: '',
      request_url: '',
      ...integrationMethods[integrationMethod]?.fields?.reduce(
        (prev, cur) => setIn(prev, cur.name, cur.value ?? ''),
        {},
      ),
      ...integrationValues,
    }),
    [integrationValues, integrationMethod, providerId],
  );

  const handleSubmit = useCallback(
    values => saveIntegration(values),
    [saveIntegration],
  );

  if (integrationId && (!integrationValues || !providerId)) {
    return <Spinner fixed />;
  }

  if (!providerId) {
    if (mode === 'dialog') {
      return (
        <Dialog open maxWidth="md" fullWidth onClose={onClose}>
          <DialogTitle>
            <PageHeader
              title="Integrations"
              backButtonProps={{ component: 'button', onClick: onClose }}
            />
          </DialogTitle>
          <DialogContent>
            <IntegrationProviderList
              onProviderSelect={provider => setProviderId(provider)}
            />
          </DialogContent>
          <DialogActions />
        </Dialog>
      );
    }
    return (
      <>
        <PageHeader
          title="Integrations"
          backButtonProps={{ to: '/integrations' }}
          tooltipMessage="Can't find your ESP? Use the search bar below or use the custom webhook option to get connected. Please email support@inboxmailers.com for any questions had."
        />
        <IntegrationProviderList
          onProviderSelect={provider => setProviderId(provider)}
        />
      </>
    );
  }

  if (customCampaignBuilder) {
    return (
      <CampaignIntegrationTriggerBuilder
        mode={mode}
        initialCampaignData={{
          id: campaign?.id,
          title: campaign?.title,
          description: campaign?.description,
          webhookUrl: campaign?.webhook_url,
          actions: campaign?.campaign_actions,
        }}
        handleCancel={handleCloseCustomCampaignBuilder}
      />
    );
  }
  const title = integrationId ? 'Edit Integration' : 'Connect New Integration';

  return (
    <Builder
      mode={mode}
      onClose={onClose}
      title={mode === 'dialog' ? '' : title}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={isIntegrationLoading}
      validationSchema={validationSchema}
      getSubmitButtonProps={formik => ({
        disabled:
          !formik.isValid || formik.isSubmitting || isIntegrationLoading,
        ...formik.status?.submitProps,
      })}
      cancelButtonProps={
        mode === 'dialog'
          ? { onClick: onClose }
          : {
              component: RouterLink,
              to: '/integrations',
            }
      }
    >
      <FieldGroup title="Integration Connection Details">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <integrationConfig.icon width={200} height={120} />
          </Grid>
          {integrationConfig.isZapierWebhook && (
            <Grid item xs={8}>
              <Typography variant="body2" color="textSecondary">
                We integrate with {integrationConfig.title} by way of Zapier.
                From within your Zapier account, set up your automation with
                "Webhooks by Zapier" as the initial trigger. You can then build
                out your automation to connect with {integrationConfig.title} in
                whatever way meets your requirements. Insert the webhook URL
                provided by Zapier in the "Webhook URL" field below, and after
                completing the rest of the fields, click the "Save" button and
                you're ready to go!
              </Typography>
            </Grid>
          )}
        </Grid>
        <Field name="name">
          {({ field, meta }) => (
            <TextField
              {...field}
              autoFocus
              label="Integration Name"
              placeholder="Enter Integration Name"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
        <Field name="method">
          {({ field, meta }) => (
            <TextField
              {...field}
              select
              label="Connection Method"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            >
              {integrationConfig.availableMethods?.map(key => (
                <MenuItem key={key} value={key}>
                  {integrationMethods[key].title}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Field>
        <IntegrationConfigFields />
        {integrationMethods[integrationMethod]?.hasWebhookField && (
          <IntegrationWebhookField />
        )}
      </FieldGroup>
      {integrationMethods[integrationMethod]?.hasAuthorization && (
        <FieldGroup
          title="Authorize Application"
          description={`Inbox Mailers would like to access your ${integrationConfig.title} account`}
        >
          <IntegrationAuthFlow />
        </FieldGroup>
      )}
    </Builder>
  );
};

export default IntegrationBuilder;
