import qs from 'qs';
import axios from 'axios';
import { useQuery } from 'react-query';
import Box from '@material-ui/core/Box';
import { ReactTabulator } from 'react-tabulator';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { formatDate } from '../../utils/date';
import Spinner from '../../components/Spinner';
import { formatCurrency } from '../../utils/currency';
import useEndlessScroll from '../../hooks/useEndlessScroll';

const columns = [
  { title: 'Email Address', field: 'email_address', width: 300 },
  { title: 'Date/Time', field: 'date_created', width: 250 },
  {
    title: 'Amount',
    field: 'amount',
    width: 250,
    formatter: cell =>
      formatCurrency(cell.getValue(), { minimumFractionDigits: 3 }),
  },
];

const options = {
  groupBy: data => `${data.pixel_name} (${data.pixel_uuid})`,
  headerSort: false,
  movableRows: false,
  movableColumns: true,
  downloadReady: (_, blob) => blob,
};

const CommissionsTable = ({ rowsPerLoad, dateFilter, commFromDate, commToDate, forwardedRef }) => {
  const [activePage, setActivePage] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const dataTableRef = useRef(null);
  const query = {
    take: rowsPerLoad,
    offset: activePage * rowsPerLoad - rowsPerLoad,
    filterDate: dateFilter,
    fDate: commFromDate,
    tDate: commToDate,
  };

  useImperativeHandle(forwardedRef, () => ({
    exportData() {
      dataTableRef.current.table.download('csv', 'commissions.csv');
    },
    resetData() {
      setActivePage(0);
      setHasNext(true);
    },
  }));

  const {
    isLoading,
    isFetching,
    error,
    refetch: fetchMore,
  } = useQuery(
    ['commissionsReport', query],
    () => axios.get(`/reports/commissions?${qs.stringify(query)}`),
    {
      enabled: false,
      onSuccess: res => {
        if (res.length < rowsPerLoad) setHasNext(false);
        dataTableRef.current?.table?.replaceData(
          res.map(row => ({
            ...row,
            date_created: formatDate(row.date_created),
          })),
        );
      },
    },
  );

  const { bottomPageObserver } = useEndlessScroll(
    isLoading || isFetching,
    error,
    hasNext,
    {
      onBottomReached: () => setActivePage(prev => prev + 1),
    },
  );

  useEffect(() => {
    if (activePage >= 1) {
      fetchMore();
    } else {
      setActivePage(prev => prev + 1);
    }
  }, [activePage, fetchMore]);

  return (
    <>
      <ReactTabulator
        ref={dataTableRef}
        data={[]}
        columns={columns}
        tooltips
        resizableColumns
        resizableRows={false}
        options={options}
        layout="fitData"
        placeholder="No Commissions available yet."
      />
      {(isLoading || isFetching) && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
      <div ref={bottomPageObserver} />
    </>
  );
};

export default forwardRef((props, ref) => (
  <CommissionsTable {...props} forwardedRef={ref} />
));
