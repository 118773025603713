import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const FormSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  from: yup.string().email('Invalid email').required('From is required'),
});

export const SendEmailNodeFormSchema = yup.object().shape({
  subject: yup.string().required('Required'),
  from: yup.object().shape({
    id: yup.string().required('Required'),
    email: yup.string().required('Required'),
    name: yup.string().required('Required'),
    company: yup.string().required('Required'),
  }),
  company: yup.string().required('Required'),

  templateId: yup.string().required('Required'),
});
export const FormResolver = yupResolver(FormSchema);
export const SendEmailNodeFormResolver = yupResolver(SendEmailNodeFormSchema);
