import SendEmail from './NodeActionTypes/SendEmail';
import Delay from './NodeActionTypes/Delay';
import Wait from './NodeActionTypes/Wait';
import ABSplit from './NodeActionTypes/ABSplit';
import SendAll from './NodeActionTypes/SendAll';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { flow, find, isEqual, filter, map } from 'lodash';
export const nodeOptions = {
  SEND_EMAIL: 'SEND_EMAIL',
  AB_SPLIT: 'AB_SPLIT',
  SEND_ALL: 'SEND_ALL',
  DELAY: 'DELAY',
  WAIT: 'WAIT',
};

export const createNodeTypes = handleUpdateNodeData => ({
  SEND_EMAIL: node => (
    <SendEmail
      node={{ id: node.id, type: node.type, data: node.data }}
      handleUpdateNodeData={handleUpdateNodeData}
    />
  ),

  AB_SPLIT: node => (
    <ABSplit
      node={{ id: node.id, type: node.type, data: node.data }}
      handleUpdateNodeData={handleUpdateNodeData}
    />
  ),
  SEND_ALL: node => (
    <SendAll
      node={{ id: node.id, type: node.type, data: node.data }}
      handleUpdateNodeData={handleUpdateNodeData}
    />
  ),

  DELAY: node => (
    <Delay
      node={{ id: node.id, type: node.type, data: node.data }}
      handleUpdateNodeData={handleUpdateNodeData}
    />
  ),

  WAIT: node => (
    <Wait
      node={{ id: node.id, type: node.type, data: node.data }}
      handleUpdateNodeData={handleUpdateNodeData}
    />
  ),
});

export const fetchDomains = async partnerId => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/partners/${partnerId}/campaign-domains?status=1`,
    );
    // Handle the successful response and return the transformed data
    const transformedData = response.data.map(fromDomain => ({
      label: fromDomain.senderAcct,
      id: fromDomain.id,
      domain: fromDomain.domain,
      name: fromDomain.name,
    }));
    return [{ label: 'Select From...', id: '' }, ...transformedData];
  } catch (error) {
    // Handle any errors here
    console.error(error);
    return [];
  }
};

export const fetchTemplates = async partnerId => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/partners/${partnerId}/email-templates?status=1`,
    );
    // Handle the successful response and return the transformed data
    const transformedData = response.data.map(template => ({
      label: template.template_name,
      value: template.id,
    }));
    return [{ label: 'Select Creative', value: '' }, ...transformedData];
  } catch (error) {
    // Handle any errors here
    console.error(error);
    return [];
  }
};

const generateUUID = () => {
  return uuidv4();
};

export const transformNodeData = nodeData => {
  const transformedData = [];
  for (const node of nodeData) {
    const actionOrder = node?.data?.actionOrder;
    const actionType = node?.data?.actionType;

    if (
      actionType === nodeOptions.SEND_ALL ||
      actionType === nodeOptions.AB_SPLIT
    ) {
      transformedData?.push({
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: node?.data?.actionDetails?.map(actionDetail => ({
          uuid: actionDetail?.uuid || generateUUID(),
          from: actionDetail?.from,
          subject: actionDetail?.subject,
          templateId: actionDetail?.templateId,
          weight: actionDetail?.weight || undefined,
        })),
      });
    } else if (actionType === nodeOptions.SEND_EMAIL) {
      transformedData?.push({
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          from: node?.data?.actionDetails?.from,
          subject: node?.data?.actionDetails?.subject,
          templateId: node?.data?.actionDetails?.templateId,
        },
      });
    } else if (actionType === nodeOptions.DELAY) {
      transformedData?.push({
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          delay: node?.data?.actionDetails?.delay,
          delayUnit: node?.data?.actionDetails?.delayUnit,
        },
      });
    } else if (actionType === nodeOptions.WAIT) {
      transformedData?.push({
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          wait: node?.data?.actionDetails?.wait,
          waitUnit: node?.data?.actionDetails?.waitUnit,
        },
      });
    }
  }
  return transformedData;
};

export const transformOriginalActionsData = originalActionsData => {
  const transformedData = originalActionsData?.map(data => {
    const actionOrder = data.action_order;
    const actionType = data.action_type;

    if (
      actionType === nodeOptions.SEND_ALL ||
      actionType === nodeOptions.AB_SPLIT
    ) {
      return {
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: data.action_details.map(actionDetail => ({
          uuid: actionDetail.uuid,
          from: actionDetail.from,
          subject: actionDetail.subject,
          templateId: actionDetail.templateId,
          weight: actionDetail.weight || undefined,
        })),
      };
    } else if (actionType === nodeOptions.SEND_EMAIL) {
      return {
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          from: data.action_details.from,
          subject: data.action_details.subject,
          templateId: data.action_details.templateId,
        },
      };
    } else if (actionType === nodeOptions.DELAY) {
      return {
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          delay: data.action_details.delay,
          delayUnit: data.action_details.delayUnit,
        },
      };
    } else if (actionType === nodeOptions.WAIT) {
      return {
        actionOrder: actionOrder,
        actionType: actionType,
        actionDetails: {
          wait: data.action_details.wait,
          waitUnit: data.action_details.waitUnit,
        },
      };
    }
  });

  return transformedData;
};

export const findModifiedActionsByOrder = (
  originalActions,
  modifiedActions,
) => {
  return flow(
    actions =>
      filter(actions, baseAction => {
        const currentAction = find(modifiedActions, {
          actionOrder: baseAction.actionOrder,
        });
        return currentAction && !isEqual(baseAction, currentAction);
      }),
    actions => map(actions, action => ({ actionOrder: action.actionOrder })),
  )(originalActions);
};

export const filterAndAddDomain = domainList => {
  const filteredList = domainList.filter(domain => domain.partner_id !== null);

  const sharedDomainPool = {
    id: null,
    senderAcct: 'SharedDomainPool',
    unsubURL: null,
    address: null,
    name: 'Shared domain Pool',
    domain: 'SharedDomainPool',
    DNSprovider: null,
    date_created: null,
    date_updated: null,
    partner_id: null,
    status: null,
    is_valid: true,
    settings: null,
    notes: null,
  };

  filteredList.push(sharedDomainPool);
  return filteredList;
};

export const filterOutSharedDomains = domainList => {
  const filteredList = domainList.filter(domain => domain.partner_id !== null);
  return filteredList;
};
