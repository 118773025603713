import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 120px)',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    margin: 0,
    verticalAlign: 'top',
    display: 'inline-block',
    padding: theme.spacing(1, 0),
  },
  description: {
    lineHeight: 'inherit',
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0, 0, 0, 3),
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));
