import qs from 'qs';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import PixelList from './PixelList';
import PixelCodeDialog from './PixelCodeDialog';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';

const Pixel = () => {
  const [isPixelCodeDialogVisible, setIsPixelCodeDialogVisible] =
    useState(false);
  const [pixel, setPixel] = useState({});
  const [query, setQuery] = useState({ page: 1 });

  const handlePixelCodeGenerate = pixel => {
    setPixel(pixel);
    setIsPixelCodeDialogVisible(true);
  };

  const handlePixelCodeDialogClose = () => {
    setIsPixelCodeDialogVisible(false);
  };

  const {
    isLoading,
    isFetching,
    data: pixels,
  } = useQuery(
    ['pixelData', query],
    () => axios.get(`/pixels?${qs.stringify(query)}`),
    { keepPreviousData: true },
  );

  return (
    <>
      <PageHeader
        title="Pixel Management"
        buttons={[
          <Button
            color="primary"
            to="/pixel/add"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <PixelCodeDialog
        open={isPixelCodeDialogVisible}
        onClose={handlePixelCodeDialogClose}
        pixel={pixel}
      />
      <PixelList
        rows={pixels?.content || []}
        totalPages={pixels?.totalPages}
        totalElements={pixels?.totalElements}
        isLoading={isLoading || isFetching}
        onQueryChange={setQuery}
        onPixelCodeGenerate={handlePixelCodeGenerate}
      />
    </>
  );
};

export default Pixel;
