import React from 'react';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { FormResolver } from './validation';
import PageHeader from '../PageHeader';
import FieldGroup from '../FieldGroup';

const useStyles = makeStyles(theme => ({
  campaignFormContainer: {
    width: props => props.width || '500px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
  },
}));
const CampaignForm = ({ handleNextClick, defaultValues }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    delayError: 500,
    defaultValues: {
      title: defaultValues.title,
      description: defaultValues.description,
      // startDate: defaultValues.startDate,
      //  endDate: defaultValues.endDate,
    },
    resolver: FormResolver,
  });

  const onSubmit = handleSubmit(data => {
    handleNextClick(data);
  });
  return (
    <>
      <PageHeader
        title="Create/Edit Campaign"
        backButtonProps={{ to: '/campaigns' }}
      />
      <Box className={classes.campaignFormContainer}>
        <FieldGroup title="Campaign Details">
          <form onSubmit={onSubmit}>
            <TextField
              placeholder="Title"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              {...register('title')}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
            <TextField
              placeholder="Description"
              variant="outlined"
              fullWidth
              margin="dense"
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('description')}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            {/* TODO: add end and start date picker */}
            <Box
              display="flex"
              justifyContent={isMobile ? 'center' : 'flex-end'}
              my={1}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: '150px',
                }}
                color="primary"
              >
                Next
              </Button>
            </Box>
          </form>
        </FieldGroup>
      </Box>
    </>
  );
};

export default CampaignForm;
