import axios from 'axios';
import * as yup from 'yup';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import FieldGroup from '../../components/FieldGroup';
import RouterLink from '../../components/RouterLink';
import TriggerDropdown from '../../components/TriggerDropdown';
import PixelCode from '../PixelCode';

const validationSchema = yup.object({
  pixel_name: yup.string().label('Pixel Name').max(32).required(),
  description: yup.string().label('Description').max(200),
});

const PixelBuilder = () => {
  const history = useHistory();
  const { pixelId } = useParams();
  const queryClient = useQueryClient();
  const [isPixelCreated, setIsPixelCreated] = useState(false);
  const [pixelCodeViewId, setPixelCodeViewId] = useState(null);
  const [dynamicListCheck, setDynamicListCheck] = useState(true);

  const showPixelCodeView = pixelId => {
    setPixelCodeViewId(pixelId);
    setIsPixelCreated(true);
  };

  const { data: pixel } = useQuery(
    ['pixel', pixelId],
    () => axios.get(`/pixels/${pixelId}`),
    {
      enabled: !!pixelId,
      onError: () => {
        history.push('/404');
      },
    },
  );

  useEffect(() => {
    setDynamicListCheck(
      pixel?.pixel_opens == 0 || pixel?.pixel_opens == 1
        ? pixel.pixel_opens
        : true,
    );
  }, [pixel]);

  const { mutateAsync: savePixel } = useMutation(
    data =>
      axios.request({
        url: pixelId ? `/pixels/${pixelId}` : '/pixels',
        method: pixelId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: response => {
        queryClient.invalidateQueries('pixelData');
        queryClient.invalidateQueries('pixel');

        if (!pixelId) {
          showPixelCodeView(response.id);
        } else {
          history.push('/pixel');
        }
      },
    },
  );

  if (pixelId && !pixel) {
    return <Spinner fixed />;
  }

  const initialValues = {
    pixel_name: pixel?.pixel_name || '',
    description: pixel?.description || '',
    trigger_id: pixel?.trigger_id || '',
    dynamic_trigger_id: pixel?.dynamic_trigger_id || '',
  };

  const addPixelToNetwork = event => {
    setDynamicListCheck(event.target.checked);
  };

  const submitPixelData = data => {
    let parsedData = { ...data, pixel_opens: dynamicListCheck ? 1 : 0 };
    if (!dynamicListCheck) {
      delete parsedData.dynamic_trigger_id;
    }
    savePixel(parsedData);
  };

  return (
    <>
      {isPixelCreated ? (
        <PixelCode pixelId={pixelCodeViewId} />
      ) : (
        <Builder
          onSubmit={submitPixelData}
          initialValues={initialValues}
          validationSchema={validationSchema}
          title={`${pixelId ? 'Edit' : 'Create'} Pixel`}
          cancelButtonProps={{
            component: RouterLink,
            to: '/pixel',
          }}
        >
          <FieldGroup title="Pixel Details">
            <Field name="pixel_name">
              {({ field, meta }) => (
                <TextField
                  {...field}
                  label="Pixel Name"
                  placeholder="Enter Pixel Name"
                  helperText={meta.touched && meta.error}
                  error={meta.touched && Boolean(meta.error)}
                />
              )}
            </Field>
            <Field name="description">
              {({ field, meta }) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Pixel Description"
                  placeholder="Enter Description for Pixel"
                  helperText={meta.touched && meta.error}
                  error={meta.touched && Boolean(meta.error)}
                />
              )}
            </Field>
            <Field name="trigger_id" component={TriggerDropdown} />
          </FieldGroup>
          <FieldGroup
            title="Dynamic List"
            tooltipMessage="This feature will take your pixel fires and automatically upload them to a network list connected to the trigger below. This is a great way to increase triggers quickly."
          >
            <FormControlLabel
              name={'dynamic_list'}
              checked={dynamicListCheck}
              onChange={e => addPixelToNetwork(e)}
              control={<Checkbox color="primary" />}
              label={
                <Typography variant="body2">
                  Automatically Add Pixel Opens to Network List?
                </Typography>
              }
            />
            {dynamicListCheck ? (
              <Field name="dynamic_trigger_id" component={TriggerDropdown} />
            ) : null}
          </FieldGroup>
        </Builder>
      )}
    </>
  );
};

export default PixelBuilder;
