import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';
import { formatDate } from '../../utils/date';

const ChosenTemplate = ({
  data,
  handleChosenTemplate,
  onChildSelectLoading,
}) => {
  const [selectLoading, setSelectLoading] = useState(false);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const deleteMutation = useMutation(
    async id => {
      const response = await axios.delete(
        `${API_BASE_URL}/templates/${id}/delete`,
      );
      return response.data;
    },
    {
      onMutate: async deletedItemId => {
        const prevData = queryClient.getQueryData('fetchEmailTemp');

        queryClient.setQueryData('fetchEmailTemp', oldData => {
          return oldData.filter(item => item.id !== deletedItemId);
        });

        return prevData;
      },
      onError: context => {
        queryClient.setQueryData('fetchEmailTemp', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('fetchEmailTemp');
      },
    },
  );

  const handleClick = async () => {
    try {
      setSelectLoading(true);

      await handleChosenTemplate(data?.id);
      setSelectLoading(false);
      onChildSelectLoading(selectLoading);
    } catch (error) {
      console.error('Error handling chosen template:', error);
    }
  };

  return (
    <>
      <Card
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <CardMedia
          component="img"
          image={data?.template_thumbnail}
          height="200"
          style={{ backgroundSize: '300px 200px' }}
          alt="Landscape"
        />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            style={{
              position: 'absolute',
              top: 150,
              left: 10,
              color: 'white',
              background: 'rgba(0, 0, 0, 0.5)',
              padding: '4px 8px',
            }}
          >
            {data?.custom_title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Updated at: {formatDate(data?.date_updated.toString())}
          </Typography>
        </CardContent>

        <CardActions>
          <Box
            sx={{
              display: 'flex',
            }}
          ></Box>
        </CardActions>
      </Card>
    </>
  );
};

export default ChosenTemplate;
