import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { useStyles } from './styles';
import UserProfileMenu from '../UserProfileMenu';

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar color="inherit" position="fixed" className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Link to="/">
          <img
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="logo"
          />
        </Link>
        <div className={classes.grow} />
        <UserProfileMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
