import { useMemo, useState } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

import DataGrid from '../../components/DataGrid';
import RowAction from '../../components/RowAction';
import RowActions from '../../components/RowActions';
import RouterLink from '../../components/RouterLink';
import { Button, Typography } from '@material-ui/core';
import { formatDate } from '../../utils/date';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useQuery } from 'react-query';

import AddIcon from '@material-ui/icons/Add';
import PageHeader from '../../components/PageHeader';
import { deleteCampaign, getCampaigns } from '../../utils/apis/campaigns';
import StatusLabel from '../../components/StatusLabel';
import { inboxMailersCampaign } from '../../config/integration';

const columns = [
  {
    field: 'name',
    title: 'Name',
    width: '290px',
  },
  {
    field: 'description',
    title: 'Description',
    width: '300px',
  },
  {
    field: 'integration',
    title: 'Integration',
    width: '100px',
  },
  {
    field: 'needEmailTemplate',
    title: 'Need Template',
    width: '120px',
  },
  {
    field: 'dateCreated',
    title: 'Date Created',
    width: '200px',
  },
  {
    field: 'dateUpdated',
    title: 'Date Updated',
    width: '200px',
  },
  {
    field: 'actions',
    width: '92px',
  },
];

const CampaignListsDisplay = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const dataSource = useMemo(
    () =>
      rows.map(row => ({
        ...row,
        name: <Typography>{row.title}</Typography>,
        description: <Typography>{row.description}</Typography>,
        needEmailTemplate: (
          <StatusLabel
            label={row.needEmailTemplate ? 'TRUE' : 'FALSE'}
            status={row.needEmailTemplate}
          />
        ),
        integration: (
          <StatusLabel
            label={row.integrations?.length > 0 ? 'TRUE' : 'FALSE'}
            status={row.integrations?.length > 0}
          />
        ),
        dateCreated: (
          <Typography>{formatDate(row.date_created.toString())}</Typography>
        ),
        dateUpdated: (
          <Typography>{formatDate(row.date_updated.toString())}</Typography>
        ),
        actions: (
          <RowActions
            actions={[
              {
                title: 'Edit',
                component: RouterLink,
                to:
                  row.integrations.length > 0 &&
                  row.campaign_designs.length === 0
                    ? `/integrations/${row.integrations[0].id}/edit`
                    : `/campaigns/${row.id}/edit`,
                children: <BorderColorOutlinedIcon />,
              },
              {
                title: 'Delete',
                children: (
                  <RowAction
                    icon={<DeleteOutlinedIcon />}
                    buttonLabel="Delete"
                    actionLabel="delete"
                    entityLabel="campaign"
                    queryKey="campaigns"
                    queryFunction={() => deleteCampaign(row.id)}
                  />
                ),
              },
            ]}
          />
        ),
      })),
    [rows],
  );

  return (
    <DataGrid
      rowKey="id"
      pageable
      rows={dataSource}
      columns={columns}
      loading={isLoading}
      totalPages={totalPages}
      totalElements={totalElements}
      onQueryChange={onQueryChange}
      emptyText="You don't have any campaigns yet"
    />
  );
};

const CampaignLists = () => {
  const partner = useCurrentPartner();
  const [query, setQuery] = useState({ page: 1 });
  const {
    data: { data: campaigns, totalPages, totalCount } = {
      data: [],
      totalPages: 0,
      totalCount: 0,
    },
    isLoading,
    isFetching,
  } = useQuery(
    ['campaigns', partner.id, query],
    async () => await getCampaigns(partner.id, query.page),
    {
      enabled: !!partner.id,
      keepPreviousData: true,
      staleTime: 0,
    },
  );
  return (
    <>
      <PageHeader
        title="Campaigns"
        buttons={[
          <Button
            color="primary"
            to="/campaigns/new"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <CampaignListsDisplay
        isLoading={isLoading || isFetching}
        rows={campaigns}
        totalElements={totalCount}
        totalPages={totalPages}
        onQueryChange={setQuery}
      />
    </>
  );
};
export default CampaignLists;
