import React, { useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  TextField,
  IconButton,
  MenuItem,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { useForm, useFieldArray } from 'react-hook-form';
import useStyles from './styles';
import { ABSplitFormResolver } from './validation';
import { Handle, Position } from 'reactflow';
import useCurrentPartner from '../../../../hooks/useCurrentPartner';
import CallSplit from '@material-ui/icons/CallSplit';
import { useInfiniteQuery } from 'react-query';
import {
  getCampaignDomains,
  getEmailTemplates,
} from '../../../../utils/apis/emailTemplates';
import SelectInfiniteScrollV2 from '../../../SelectInfiniteScroll';
import { formatDateAgo } from '../../../../utils/date';
import { generateDynamicNameFromDomain } from '../../../../utils/domain';
import calculateGridSize from '../../../../utils/calculateGridSize';
import { isEqual } from 'lodash';
import extractDomainFromEmail from '../../../../utils/domains/extractDomainFromEmail';
import { useSnackbar } from 'notistack';
import { filterAndAddDomain } from '../../utils';

const ABSplit = ({ handleUpdateNodeData, node }) => {
  const partner = useCurrentPartner();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const defaultFormValue = useMemo(
    () => ({
      from: {
        id: '',
        name: '',
        email: '',
      },
      company: partner?.partner_name || partner?.details?.company_name,
      subject: '',
      templateId: '',
      weight: 99,
    }),
    [partner],
  );
  const initialValues = useMemo(() => {
    return node.data.actionDetails?.length > 0
      ? node.data.actionDetails.map(data => ({
          subject: data?.subject,
          from: data?.from,
          company:
            data?.from?.company ||
            partner?.partner_name ||
            partner?.details?.company_name,
          templateId: data?.templateId,
          weight: data?.weight,
        }))
      : [defaultFormValue, defaultFormValue];
  }, [defaultFormValue, node, partner]);
  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    delayError: 500,
    defaultValues: {
      ABSplit: initialValues,
    },
    resolver: ABSplitFormResolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ABSplit',
  });
  const watchFieldArray = watch('ABSplit');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const { data, fetchNextPage, hasNextPage, isLoading, isError } =
    useInfiniteQuery(
      ['email-templates', partner.id],
      ({ pageParam = 1 }) => getEmailTemplates(partner.id, pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.totalPages > allPages.length) {
            return allPages.length + 1;
          }
          return false;
        },
        enabled: !!partner.id,
        staleTime: 0,
      },
    );
  const {
    data: domainsData,
    fetchNextPage: fetchNextDomainsPage,
    hasNextPage: hasNextDomainsPage,
    isLoading: isLoadingDomains,
    isError: isErrorDomains,
  } = useInfiniteQuery(
    ['campaign_domains'],
    ({ pageParam = 1 }) => getCampaignDomains(partner.id, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.totalPages > allPages.length) {
          return allPages.length + 1;
        }
        return false;
      },
      enabled: !!partner.id,
      staleTime: 0,
    },
  );

  const campaignDomains = useMemo(
    () => domainsData?.pages.flatMap(page => page.data) || [],
    [domainsData?.pages],
  );
  const emailTemplates = useMemo(
    () => data?.pages.flatMap(page => page.data) || [],
    [data?.pages],
  );

  const handleTemplateChange = (value, field, index) => {
    setValue(`ABSplit.${index}.templateId`, value?.id, {
      shouldValidate: true,
    });
  };
  const handleCampaignFromChange = (value, field, index) => {
    const { via, email, company } = generateDynamicNameFromDomain(
      value?.domain,
      field?.company,
    );
    setValue(
      `ABSplit.${index}.from`,
      {
        id: value?.id,
        name: via,
        email,
        company,
      },
      {
        shouldValidate: true,
      },
    );
  };
  useEffect(() => {
    if (isValid) {
      const finalData = controlledFields.map(field => {
        const domain = extractDomainFromEmail(field.from?.email);
        const { via, email, company } = generateDynamicNameFromDomain(
          domain,
          field?.company,
        );
        const formattedFrom = {
          ...field.from,
          name: via,
          email,
          company,
        };
        const newData = {
          ...field,
          from: formattedFrom,
          templateId: field?.templateId,
          subject: field?.subject,
        };
        return newData;
      });
      const updatedData = {
        ...node.data,
        actionDetails: finalData,
      };
      const changed = !isEqual(updatedData, node.data);

      if (changed) {
        handleUpdateNodeData(updatedData, node);
      }
    }
  }, [node, handleUpdateNodeData, isValid, controlledFields]);
  const handleAppend = async () => {
    const isValid = await trigger();

    if (isValid) {
      append(defaultFormValue);
    } else {
      enqueueSnackbar(
        'You cannot add a new form until the existing forms are filled completely',
        {
          variant: 'error',
        },
      );
    }
  };

  const gridSize = useMemo(() => calculateGridSize(fields.length), [fields]);
  // Filter to use shared domains
  const newCampaignDomains = filterAndAddDomain(campaignDomains);
  return (
    <>
      <Box className={classes.sendAllContainer}>
        <Handle type="target" position={Position.Top} />
        <Typography variant="h6" className={classes.sendAllTitle}>
          <span className={classes.titleSpan}>
            <CallSplit />
            AB SPLIT
          </span>
        </Typography>
        <Box component="form">
          <Grid container spacing={4}>
            {controlledFields.map((field, index) => {
              return (
                <Grid item xs={12} sm={6} key={field.id}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    minWidth="380px"
                    className={classes.sendAllContainer}
                  >
                    <SelectInfiniteScrollV2
                      data={campaignDomains}
                      isLoading={isLoadingDomains}
                      selectedId={field?.from?.id}
                      isError={isErrorDomains}
                      fetchNextPage={fetchNextDomainsPage}
                      hasNextPage={hasNextDomainsPage}
                      errorMessage={
                        errors?.ABSplit?.[index]?.from?.message || ' '
                      }
                      onChange={value =>
                        handleCampaignFromChange(value, field, index)
                      }
                      optionProperty={['name', 'domain']}
                      renderOption={(props, option) => {
                        const { via, email } = generateDynamicNameFromDomain(
                          option?.domain,
                          field?.company,
                        );

                        return (
                          <MenuItem {...props} key={option.id}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                fontSize="12px"
                                component="p"
                              >
                                {via}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="p"
                                color="textSecondary"
                                ml="8px"
                                fontSize="12px"
                                fontWeight="400"
                              >
                                {`<${email}>`}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      }}
                      label="From"
                    />
                    <TextField
                      placeholder="Subject"
                      title="Enter Email Subject"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register(`ABSplit.${index}.subject`)}
                      margin="normal"
                      label="Subject"
                      error={!!errors?.ABSplit?.[index]?.subject}
                      helperText={
                        errors?.ABSplit?.[index]?.subject?.message || ' '
                      }
                    />

                    <TextField
                      placeholder="Company"
                      title="Enter Company Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register(`ABSplit.${index}.company`)}
                      margin="normal"
                      label="Company"
                      error={!!errors?.ABSplit?.[index]?.company}
                      helperText={
                        errors?.ABSplit?.[index]?.company?.message || ' '
                      }
                    />

                    <SelectInfiniteScrollV2
                      data={emailTemplates}
                      isLoading={isLoading}
                      selectedId={field?.templateId}
                      isError={isError}
                      fetchNextPage={fetchNextPage}
                      hasNextPage={hasNextPage}
                      errorMessage={
                        errors?.ABSplit?.[index]?.templateId?.message || ' '
                      }
                      onChange={value =>
                        handleTemplateChange(value, field, index)
                      }
                      renderOption={(props, option) => {
                        let optionLabel = option['template_name'];
                        const createdAt = option['date_created'];
                        const dateAgo = formatDateAgo(createdAt);
                        return (
                          <Box component="li" {...props} key={option.id}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body2"
                                fontWeight="500"
                                fontSize="12px"
                                component="p"
                              >
                                {optionLabel}:{' '}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="p"
                                color="textSecondary"
                                ml="8px"
                                fontSize="12px"
                                fontWeight="400"
                              >
                                Created {dateAgo}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }}
                      optionProperty="template_name"
                      label="Creative"
                    />
                    <TextField
                      placeholder="Weight"
                      title="Enter Weight Value: 0 - 100"
                      variant="outlined"
                      fullWidth
                      size="small"
                      margin="normal"
                      label="Weight"
                      {...register(`ABSplit.${index}.weight`)}
                      inputProps={{
                        maxLength: 3,
                      }}
                      error={!!errors?.ABSplit?.[index]?.weight}
                      helperText={
                        errors?.ABSplit?.[index]?.weight?.message || ' '
                      }
                      style={{ width: '100%' }}
                      defaultValue={99}
                    />
                    <Button
                      variant="contained"
                      onClick={() => remove(index)}
                      sx={{
                        mt: 1,
                      }}
                      disabled={controlledFields.length < 3}
                      startIcon={<DeleteOutlined />}
                    >
                      Remove
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          <IconButton
            color="primary"
            aria-label="Add"
            className={classes.addButton}
            onClick={handleAppend}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Handle type="source" position={Position.Bottom} />
      </Box>
    </>
  );
};

export default ABSplit;
