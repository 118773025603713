import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const RowActions = ({ actions }) => {
  const classes = useStyles();

  return (
    <ul className={classes.actionsList}>
      {actions.map((action, key) => (
        <li key={key}>
          <Button
            {...action}
            color="inherit"
            variant="contained"
            disabled={action.disabled}
            className={classes.buttonRoot}
          />
        </li>
      ))}
    </ul>
  );
};

export default RowActions;
