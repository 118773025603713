import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionsList: {
    display: 'flex',
    margin: 0,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    '& > li': {
      display: 'block',
      color: 'rgb(255, 255, 255)',
      marginLeft: theme.spacing(0.5),
      '&:first-of-type': {
        marginLeft: 0,
      },
      marginRight: theme.spacing(0.5),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  buttonRoot: {
    padding: theme.spacing(1),
    minWidth: theme.spacing(5),
    backgroundColor: 'rgb(186, 191, 211)',
    '&:hover': {
      backgroundColor: 'rgb(159, 164, 183)',
    },
  },
}));
