import axios from 'axios';
import Box from '@material-ui/core/Box';
import { useMutation } from 'react-query';
import Grid from '@material-ui/core/Grid';
import JSONPretty from 'react-json-pretty';
import Button from '@material-ui/core/Button';
import { useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import useCurrentPartner from '../../hooks/useCurrentPartner';

const useStyles = makeStyles({
  jsonPreview: {
    '& .__json-pretty__': {
      color: 'rgb(249, 38, 114)',
      background: 'rgba(0, 0, 0, 0.05)',
      '& .__json-value__': {
        color: 'rgb(253, 151, 31)',
      },
    },
  },
});

const helpText = (
  <Box component="ul" marginY={1} paddingX={2}>
    <li>
      Use the token <strong>{`{{contact.email}}`}</strong> to specify where the
      user's email address should be merged.
    </li>
    <li>
      Use the token <strong>{`{{custom_parameter_name.custom}}`}</strong> to
      specify where the custom parameters should be merged. The{' '}
      <strong>custom_parameter_name</strong> should be replaced with the actual
      name of the parameter the user appended to the pixel url OR the actual name
      of the custom field column header within the network upload file.
    </li>
  </Box>
);

const headerHelpText = (
  <Box component="ul" marginY={1} paddingX={2}>
    <li>
      If including headers, please structure using the key-value format.
    </li>
    <li>
      If including multiple headers, please separate each header by a comma.
    </li>
  </Box>
);

const IntegrationWebhookField = () => {
  const classes = useStyles();
  const partner = useCurrentPartner();
  const { values, setFieldError, setStatus } = useFormikContext();

  const {
    data: testResponse,
    mutate: testEndpoint,
    isLoading: isWebhookTesting,
    reset: resetTestEndpoint,
  } = useMutation(
    () => {
      const replaceToken = string =>
        string
          .replace('{{contact.email}}', partner.email_address)
          .replace(/\{\{\w+\.custom\}\}/gm, 'test')
          .replace(/\{\{\w+\.field\}\}/gm, 'test');

      return axios.put('/testEndpoint', {
        url: replaceToken(values.request_url),
        method: values.method,
        headers: values.request_headers
            ? JSON.parse(values.request_headers)
            : null,
        data: values.request_body
          ? JSON.parse(replaceToken(values.request_body))
          : null,
      });
    },
    {
      onError: () => {
        setFieldError(
          'request_url',
          'Unable to get a successful response from Webhook URL',
        );
      },
      onSuccess: () => {
        setStatus(undefined);
      },
    },
  );

  useEffect(() => {
    resetTestEndpoint();
    setStatus({ submitProps: { disabled: true } });
  }, [
    values.method,
    values.request_url,
    values.request_headers,
    values.request_body,
    resetTestEndpoint,
    setStatus,
  ]);

  const handleValidateURLField = useCallback(value => {
    if (!value) {
      return 'Webhook URL is a required field';
    }
  }, []);
  
  const handleValidateHeadersJsonField = useCallback(value => {
    if (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return 'Webhook Headers are not a valid JSON object';
      }
    }
  }, []);

  const handleValidateJsonField = useCallback(value => {
    if (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return 'Webhook Body is not a valid JSON object';
      }
    }
  }, []);

  return (
    <>
      <Field name="request_url" validate={handleValidateURLField}>
        {({ field, meta }) => (
          <Grid container spacing={6} alignItems="flex-start">
            <Grid item xs={10}>
              <TextField
                {...field}
                label="Webhook URL"
                placeholder="Enter URL"
                helperText={
                  meta.touched && meta.error
                    ? meta.error
                    : values.method === 'GET'
                    ? helpText
                    : ''
                }
                error={meta.touched && Boolean(meta.error)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box marginTop={4.5}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={testEndpoint}
                  disabled={isWebhookTesting || !field.value}
                  startIcon={
                    isWebhookTesting ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <RefreshIcon />
                    )
                  }
                >
                  Test
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Field>
      <Field name="request_headers" validate={handleValidateHeadersJsonField}>
        {({ field, meta }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label="Webhook Headers"
            placeholder="{}"
            helperText={meta.touched && meta.error ? meta.error : headerHelpText}
            error={meta.touched && Boolean(meta.error)}
           />
         )}
       </Field>
      {values.method === 'POST' && (
        <Field name="request_body" validate={handleValidateJsonField}>
          {({ field, meta }) => (
            <TextField
              {...field}
              multiline
              rows={6}
              label="Webhook Body"
              placeholder="{}"
              helperText={meta.touched && meta.error ? meta.error : helpText}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
      )}
      {testResponse && (
        <Box marginTop={2}>
          <Typography component="label" variant="body2" color="textSecondary">
            Webhook Response
          </Typography>
          <JSONPretty
            space="2"
            data={testResponse}
            className={classes.jsonPreview}
          />
        </Box>
      )}
    </>
  );
};

export default IntegrationWebhookField;
