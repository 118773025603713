import { createContext } from 'react';

const initialState = {
  id: null,
  partner_name: '',
  email_address: '',
  network_in: false,
  publisher: false,
  hash_access: false,
  details: {},
  available_credits: 0,
  balance_to_be_paid: 0,
  credit_reload: 0,
  credit_reload_threshold: 0,
  credits_before_reload: 0,
  support_group: false,
};

const PartnerContext = createContext(initialState);

export { initialState };
export default PartnerContext;
