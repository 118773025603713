import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const FormSchema = yup.object().shape({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
});

const TriggerFormSchema = yup.object().shape({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
  subject: yup.string().required('Required'),
  company: yup.string().required('Required'),
  webhookUrl: yup
    .string()
    .nullable(true)
    .test(
      'is-url',
      'Must be a valid URL',
      value => !value || yup.string().url().isValidSync(value),
    ),
});

const SendTestEmailFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
});

const NodeFormSchema = yup.object().shape({
  subject: yup.string().required('Required'),
  from: yup.object().shape({
    id: yup.string().required('Required'),
    email: yup.string().required('Required'),
    name: yup.string().required('Required'),
    company: yup.string().required('Required'),
  }),
  company: yup.string().required('Required'),

  templateId: yup.string().required('Required'),
});

export const FormResolver = yupResolver(FormSchema);
export const TriggerFormResolver = yupResolver(TriggerFormSchema);
export const SendTestEmailFormResolver = yupResolver(SendTestEmailFormSchema);
export const NodeFormResolver = yupResolver(NodeFormSchema);
