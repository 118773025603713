import React from 'react';
import { Link } from 'react-router-dom';

const RouterLink = props => {
  return <Link {...props} />;
};

export default React.forwardRef((props, ref) => (
  <RouterLink {...props} innerRef={ref} />
));
