import axios from 'axios';
import { useMemo } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

import DataGrid from '../../components/DataGrid';
import RowAction from '../../components/RowAction';
import RowActions from '../../components/RowActions';
import StatusLabel from '../../components/StatusLabel';
import RouterLink from '../../components/RouterLink';
import { useAccess, appPermissions } from '../../hooks/useAccess';
import {
  integrationProviders,
  integrationMethods,
  inboxMailersCampaign,
} from '../../config/integration';
import IntegrationApiKey from './IntegrationApiKey';
import { deleteCampaign } from '../../utils/apis/campaigns';

const renderIcon = integrationType => {
  const integrationConfig = integrationProviders.find(
    provider => provider.type === integrationType,
  );

  return integrationConfig ? (
    <integrationConfig.icon
      width={100}
      height={integrationConfig.type === inboxMailersCampaign.type ? 60 : 32}
    />
  ) : null;
};

// const getEditURL = (row, campaignType) => {
//   if (
//     row.type === campaignType &&
//     row?.campaignId &&
//     row.campaign_designs?.length > 0
//   ) {
//     return `/campaigns/${row.campaignId}/edit`;
//   }
//   return `/integrations/${row.id}/edit`;
// };

const handleRowAction = (row, inboxMailersCampaign) => {
  if (row.type === inboxMailersCampaign.type && row.campaignId) {
    return deleteCampaign(row.campaignId);
  } else {
    return axios.put(`/integrations/${row.id}/archive`);
  }
};
const columns = [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'type',
    title: 'Type',
    width: '200px',
  },
  {
    field: 'method',
    title: 'Method',
    width: '150px',
  },
  {
    field: 'status',
    title: 'Status',
    width: '150px',
  },
  {
    field: 'actions',
    width: '92px',
  },
];

const IntegrationList = ({ isLoading, rows }) => {
  const { hasPermission } = useAccess();
  const dataSource = useMemo(
    () =>
      rows.map(row => ({
        id: row.id,
        name: row.name,
        type: renderIcon(row.type),
        status: (
          <StatusLabel
            label={row.status ? 'Connected' : 'Unknown'}
            status={row.status}
          />
        ),
        method: integrationMethods[row.method]?.title || '',
        actions: (
          <RowActions
            actions={[
              {
                title: 'Edit',
                component: RouterLink,
                to: `/integrations/${row.id}/edit`,
                children: <BorderColorOutlinedIcon />,
              },
              {
                title: 'Delete',
                children: (
                  <RowAction
                    icon={<DeleteOutlinedIcon />}
                    buttonLabel="Delete"
                    actionLabel="delete"
                    entityLabel="integration"
                    queryKey="integrations"
                    queryFunction={() =>
                      handleRowAction(row, inboxMailersCampaign)
                    }
                  />
                ),
              },
            ]}
          />
        ),
      })),
    [rows],
  );

  return (
    <>
      {hasPermission(appPermissions.apiAccess) && <IntegrationApiKey />}
      <DataGrid
        rowKey="id"
        rows={dataSource}
        columns={columns}
        loading={isLoading}
        emptyText="You don't have any integrations yet"
      />
    </>
  );
};

export default IntegrationList;
