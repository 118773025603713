export const runLou = (() => {
  const LOU = window.LOU;

  const identifyLou = userInfo => {
    const { attributes } = userInfo || {};
    if (!attributes) {
      LOU.identify({ isAuthenticated: false });
      return;
    }

    LOU.identify(attributes.sub, {
      isAuthenticated: true,
    });
  };

  return userInfo => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    const louTimer = setInterval(() => {
      if (LOU?.sessionObj) {
        console.debug('LOU connected');

        clearInterval(louTimer);
        identifyLou(userInfo);
      }
    }, 100);
  };
})();
