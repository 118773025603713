import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  fixed: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    margin: '-20px',
  },
});
