import { useSnackbar } from 'notistack';
import { useEffect, useCallback, useMemo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';

const QueryCacheProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback(
    error => {
      if (typeof error === 'object' && !error.message && !error.error) {
        return;
      }

      if (typeof error !== 'undefined') {
        console.debug(error);

        const text = error?.message || error?.error || String(error);
        enqueueSnackbar(text, { variant: 'error' });
      }
    },
    [enqueueSnackbar],
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            onError,
          },
          mutations: { onError },
        },
      }),
    [onError],
  );

  useEffect(() => {
    broadcastQueryClient({ queryClient });
  }, [queryClient]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default QueryCacheProvider;
