import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import ProgressBar from '../../components/ProgressBar';
import { Box, Checkbox, InputLabel, Select } from '@material-ui/core';

const validationSchema = yup.object({
  email_address: yup.string().max(70).required(),
});

const UploadDialog = ({
  onClose,
  onSubmit,
  csvHeaders,
  uploadProgress,
  hash_access,
  fileType,
}) => {
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const formik = useFormik({
    initialValues: {
      email_address: '',
      file_type: 'raw',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let data = { ...values };
      data.custom_fields = selectedHeaders.toString();
      try {
        await onSubmit(data); // Assuming onSubmit returns a promise
      } finally {
        setSubmitting(false); // Ensure the button is re-enabled after submission
      }
    },
  });

  const isLoading = formik.isSubmitting;

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedHeaders(typeof value === 'string' ? value.split(',') : value);
  };

  const submitForm = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      {isLoading && <LinearProgress />}
      <DialogTitle>CSV File Details</DialogTitle>
      <DialogContent>
        <Grid>Provide mapping details for your CSV file</Grid>
        <form onSubmit={formik.handleSubmit}>
          {hash_access ? (
            <TextField
              name="file_type"
              label="File Type"
              value={formik.values.file_type}
              onChange={formik.handleChange}
              error={
                formik.touched.file_type && Boolean(formik.errors.file_type)
              }
              helperText={formik.touched.file_type && formik.errors.file_type}
              select
            >
              {fileType.map((type, index) => (
                <MenuItem value={type.value} key={`menuitem-${index}`}>
                  {type.key}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
          <TextField
            name="email_address"
            label={
              formik?.values?.file_type == 'MD5'
                ? 'Column Containing MD5 Hashes'
                : 'Column Containing Email Addresses'
            }
            value={formik.values.email_address}
            onChange={formik.handleChange}
            error={
              formik.touched.email_address &&
              Boolean(formik.errors.email_address)
            }
            helperText={
              formik.touched.email_address && formik.errors.email_address
            }
            select
          >
            {csvHeaders.map((value, index) => (
              <MenuItem value={value} key={`menuitem-${index}`}>
                {value}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ marginBlock: '1rem' }}>
            <InputLabel id="custom_field_multiselect_label">
              Column Containing Custom Fields (Optional)
            </InputLabel>
            <Select
              labelId="custom_field_multiselect_label"
              id="custom_field_multiselect"
              multiple
              value={selectedHeaders}
              onChange={handleChange}
              renderValue={selected => selected.join(', ')}
              variant="filled"
            >
              {csvHeaders.map((value, index) => (
                <MenuItem key={`menuitem-${index}`} value={value}>
                  <Checkbox checked={selectedHeaders.indexOf(value) > -1} />
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </form>

        <ProgressBar value={uploadProgress}>
          {uploadProgress}% Uploaded
        </ProgressBar>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={submitForm}
        >
          {isLoading ? 'Uploading...' : 'Start Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
