import axios from 'axios';
import * as yup from 'yup';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import FieldGroup from '../../components/FieldGroup';
import RouterLink from '../../components/RouterLink';
import TriggerDropdown from '../../components/TriggerDropdown';

const validationSchema = yup.object({
  name: yup.string().label('Activity Name').max(32).required(),
  description: yup.string().label('Description').max(100),
});

const ActivityBuilder = () => {
  console.log('ACTIVITY BUILDER LOADED!');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { activityId } = useParams();

  function saveS3File() {
    var file = document.getElementById('testing');
    console.log(file);
    axios.get(`/getActivitySignedURL`).then(response => {
      console.log(response);
      const myHeaders = new Headers({ 'Content-Type': 'image/*' });
      const fet = fetch(response.uploadURL, {
        method: 'PUT',
        headers: myHeaders,
        body: file,
      }).then(nex => {
        console.log(nex);
        axios
          .get(`/activityDBSave?fileName=${response.csvFilename}`)
          .then(next => {})
          .then(json => {});
      });
    });
  }

  /*const { mutateAsync: saveActivity } = useMutation(
    data =>
      axios.request({
        url: activityId ? `/partner-activity/${activityId}/edit` : '/partner-activity',
        method: activityId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: async response => {
        queryClient.invalidateQueries('partnerActivity');
        queryClient.invalidateQueries('activity');

        if (activityId) {
          history.push('/activity-checker');
        } else {
          history.push(`/activity-checker/${response.id}/contacts`);
        }
      },
    },
  );

  const { data: activity } = useQuery(
    ['activity', activityId],
    () => axios.get(`/partner-activity/${activityId}`),
    {
      enabled: !!activityId,
      onError: () => {
        history.push('/404');
      },
    },
  );

  if (activityId && !activity) {
    return <Spinner fixed />;
  }

  const initialValues = {
    name: activity?.name || '',
    description: activity?.description || '',
    trigger_id: activity?.trigger_id || '',
  };
  */

  return (
    <div>
      <input type="file" id="testing"></input>
      <input type="button" id="submission" onClick={saveS3File}></input>
    </div>

    /*<Builder
      onSubmit={saveActivity}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title="Create/Edit Activity"
      okText={activityId ? 'Save' : 'Next'}
      cancelButtonProps={{
        component: RouterLink,
        to: '/activity-checker',
      }}
    >
      <FieldGroup title="Activity Details">
        <Field name="name">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Activity Name"
              placeholder="Enter Activity Name"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
        <Field name="description">
          {({ field, meta }) => (
            <TextField
              {...field}
              multiline
              rows={4}
              label="Description"
              placeholder="Enter Description for Activity"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
      </FieldGroup>
      <Field name="trigger_id" component={TriggerDropdown} />
    </Builder>*/
  );
};

export default ActivityBuilder;
