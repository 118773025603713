import { useQuery } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import IntegrationList from './IntegrationList';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';
import { fetchIntegrations } from '../../utils/apis/campaigns';
const Integrations = () => {
  const {
    data: rows = [],
    isLoading,
    isFetching,
  } = useQuery('integrations', fetchIntegrations, {
    staleTime: 0,
  });

  return (
    <>
      <PageHeader
        title="Integrations"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            component={RouterLink}
            to="/integrations/add"
          >
            Add new
          </Button>,
        ]}
      />
      <IntegrationList rows={rows} isLoading={isLoading || isFetching} />
    </>
  );
};

export default Integrations;
