/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import '../../dtButtons.css';
// MUI
import Box from '@material-ui/core/Box';
// Components
import Spinner from '../../components/Spinner';
import HistoryIcon from './history.svg';

function statusRender(data) {
  if (data === 17) {
    return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(30, 146, 244); color: rgb(235 246 255); border: none;'>System Paused</span>`;
  } else if (data === 1) {
    return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(196, 248, 226); color: rgb(6, 165, 97); border: none;'>Active</span>`;
  } else if (data === 2) {
    return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(223, 223, 223); color: rgb(173, 173, 173); border: none;'>Deleted</span>`;
  } else {
    return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(255, 244, 201); color: rgb(249, 150, 0); border: none;'>Inactive</span>`;
  }
}

const IntegrationTable = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const tableRef = useRef(null);
  const [render, setRender] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: `<img src="${HistoryIcon}" alt="Edit" style="width:30px; height:30px; border-radius:4px;"/>`,
        className: 'dt-control',
        orderable: false,
        data: null,
        defaultContent: '',
        width: '30px',
      },
      { title: 'Partner', data: 'partner_name', width: '250px' },
      { title: 'Name', data: 'name', width: '290px' },
      { title: 'Type', data: 'type', width: '200px' },
      { title: 'Method', data: 'method', width: '200px' },
      { title: 'Request URL', data: 'request_url', width: '300px' },
      {
        title: 'Request Body',
        data: 'request_body',
        width: '300px',
        render: function (data) {
          return typeof data === 'object'
            ? JSON.stringify(data, null, 2)
            : data;
        },
      },
      {
        title: 'Request Headers',
        data: 'request_headers',
        width: '300px',
        render: function (data) {
          return typeof data === 'object'
            ? JSON.stringify(data, null, 2)
            : data;
        },
      },
      {
        title: 'API Key',
        data: 'api_key',
        width: '2500px',
        render: function (data) {
          return typeof data === 'object'
            ? JSON.stringify(data, null, 2)
            : data;
        },
      },
      {
        title: 'Custom Properties',
        data: 'custom_properties',
        width: '300px',
        render: function (data) {
          return typeof data === 'object'
            ? JSON.stringify(data, null, 2)
            : data;
        },
      },
      { title: 'Status', data: 'status', width: '135px', render: statusRender },
      { title: 'Failed Count', data: 'failed_count', width: '150px' },
      {
        title: 'Date Created',
        data: 'date_created',
        width: '200px',
        render: function (data) {
          return dateFormats(data);
        },
      },
      {
        title: 'Date Updated',
        data: 'date_updated',
        width: '200px',
        render: function (data) {
          return dateFormats(data);
        },
      },
      { title: 'Campaign ID', data: 'campaign_id', width: '150px' },
    ],
    [],
  );

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 25; // Updated to default to 25

    const order = urlParams.get('order');
    if (order) {
      params.order = JSON.parse(order);
    }

    const search = urlParams.get('search');
    if (search) {
      params.search = JSON.parse(search);
    }

    return params;
  };

  const fetchData = async params => {
    params.star = 1; // Ensure the 'star' param is included
    const urlParams = getParamsFromURL();

    const mergedParams = { ...urlParams, ...params };

    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);
    if (mergedParams.order.length > 0 && mergedParams.order[0]?.column > 0) {
      mergedParams.order[0].column -= 1;
    }

    const response = await axios.post(
      `/triggers/datatables?datatables=integration`,
      mergedParams,
    );
    return response;
  };

  const dateFormats = dateString => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const format = d => {
    if (!d.logs || d.logs.length === 0) {
      return '<hr style="border: 1px solid #000;"><strong>No logs available.</strong><hr style="border: 1px solid #000;">';
    }

    return `
      <div style="background-color: #f0f0f0; padding: 10px; border-radius: 5px;">
        <table style="width: 100%; border-collapse: collapse; font-size: 14px; border-bottom: 2px solid #000; margin-bottom: 20px; margin-top: 5px; table-layout: auto;">
          <thead>
            <tr style="border-bottom: 2px solid #666;">
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Log ID</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 100px;">ID</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Partner ID</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Name</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Type</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Method</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 250px;">Request URL</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 300px;">Request Body</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 250px;">Request Headers</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">API Key</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 250px;">Custom Properties</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 100px;">Status</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Failed Count</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 200px;">Date Created</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 200px;">Date Updated</th>
              <th style="word-wrap: break-word; white-space: normal; padding: 10px; width: 150px;">Campaign ID</th>
            </tr>
          </thead>
          <tbody>
            ${d.logs
              .map(
                log => `
              <tr style="border-bottom: 1px solid #ccc;">
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.log_id !== undefined ? log.log_id : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 100px;">${
                  log.id !== undefined ? log.id : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.partner_id !== undefined ? log.partner_id : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.name ? log.name : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.type ? log.type : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.method ? log.method : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 250px;">${
                  log.request_url ? log.request_url : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 300px;">${
                  log.request_body ? JSON.stringify(log.request_body) : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 250px;">${
                  log.request_headers
                    ? JSON.stringify(log.request_headers)
                    : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.api_key ? JSON.stringify(log.api_key) : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 250px;">${
                  log.custom_properties
                    ? JSON.stringify(log.custom_properties)
                    : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 100px;">${
                  log.status !== undefined ? log.status : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.failed_count !== undefined ? log.failed_count : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 200px;">${
                  log.date_created ? dateFormats(log.date_created) : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 200px;">${
                  log.date_updated ? dateFormats(log.date_updated) : 'N/A'
                }</td>
                <td style="word-wrap: break-word; white-space: normal; padding: 10px 2px; width: 150px;">${
                  log.campaign_id !== null ? log.campaign_id : 'N/A'
                }</td>
              </tr>
            `,
              )
              .join('')}
          </tbody>
        </table>
      </div>
    `;
  };

  const initializeDataTable = () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }

    const initialParams = getParamsFromURL();

    const table = $(tableRef.current).DataTable({
      serverSide: true,
      processing: true,
      columns: columns,
      dom: '<"d-flex align-items-center dt-controls"lfB>rtip',
      responsive: true,
      scrollX: true,
      displayStart: initialParams.start || 0,
      pageLength: initialParams.length || 25, // Updated to default to 25
      order: initialParams.order || [[0, 'asc']],
      search: initialParams.search || { value: '', regex: false },
      ajax: (data, callback, settings) => {
        fetchData(data)
          .then(result => {
            const recordsFiltered = result.recordsFiltered || 0;
            const recordsTotal = result.recordsTotal || 0;

            callback({
              draw: data.draw,
              recordsTotal: recordsTotal,
              recordsFiltered: recordsFiltered,
              data: result.data,
            });
          })
          .catch(error => {
            console.error('Error fetching data: ', error);
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          });
      },
      buttons: [
        {
          extend: 'csv',
          text: 'Export',
          action: function (e, dt, node, config) {
            fetchAllDataAndExport();
          },
        },
      ],
      language: {
        infoFiltered: '',
      },
      initComplete: function () {
        const api = this.api();
        applyCustomStyles(api);

        if (initialParams.search && initialParams.search.value) {
          api.search(initialParams.search.value).draw();
          $('input[type="search"]').val(initialParams.search.value);
        }

        const searchBox = $('input[type="search"]');
        searchBox.off('keyup').on('keyup', function () {
          api.search(this.value).draw();
        });
      },
      drawCallback: function (settings) {
        const api = this.api();
        applyCustomStyles(api);

        const draw = settings.iDraw || initialParams.draw;
        const start = settings._iDisplayStart;
        const length = settings._iDisplayLength || 25; // Updated to default to 25
        const order = api.order();
        const search = api.search();

        const queryString = new URLSearchParams({
          draw: draw,
          start: start,
          length: length,
          order: JSON.stringify(order),
          search: JSON.stringify({ value: search, regex: false }),
        }).toString();

        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.replaceState(null, '', newUrl);
      },
    });

    // Add event listener for opening and closing details
    $(tableRef.current).on('click', 'td.dt-control', function () {
      const tr = $(this).closest('tr');
      const row = table.row(tr);

      if (!row.node()) {
        console.error('Failed to get row node.');
        return;
      }

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        row.child(format(row.data())).show();
        tr.addClass('shown');
      }
    });
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    if (!initialParams.draw) {
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 25, // Updated to default to 25
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
      };
      const queryString = new URLSearchParams(initialDrawParams).toString();
      const newUrl = `${window.location.pathname}?${queryString}`;
      window.history.replaceState(null, '', newUrl);
    }

    initializeDataTable();

    // Debounce resize event handler
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          const table = $(tableRef.current).DataTable();
          table.columns.adjust().draw(); // Adjust and redraw columns without reinitializing the table
        }
      }, 200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, render]);

  async function fetchAllDataAndExport() {
    // 10k limit on exporting
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    try {
      const response = await axios.post(
        '/triggers/datatables?datatables=integration&export=integration',
        params,
      );
      if (response.data && response.data.length > 0) {
        const csvData = convertToCSV(response.data);
        downloadCSV(csvData, 'export.csv');
        setRender(!render);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    }
  }

  function convertToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const val = row[header];
        return typeof val === 'string' ? `"${val.replace(/"/g, '""')}"` : val;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function applyCustomStyles(api) {
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
      'border-bottom': '2px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
    });
    $(api.table().container()).find('tfoot tr th').css({
      'border-bottom': '2px solid #dee2e6',
    });
    $(api.table().container()).find('tbody tr td').css({
      'padding-left': '10px',
      'border-bottom': '1px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
      'white-space': 'normal',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    });
    $(api.table().container())
      .find('tbody tr td:last-child, thead tr th:last-child')
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
      )
      .css({
        'border-right': '1px solid #dee2e6',
        'background-color': 'inherit',
      });
    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
      )
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
      .css({
        'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
      });

    $(api.table().container()).find('ul.pagination').css({
      display: 'flex',
      justifyContent: 'center',
      padding: '0',
      marginTop: '10px',
      listStyle: 'none',
    });

    $(api.table().container()).find('ul.pagination li').css({
      display: 'inline',
      margin: '0 2px',
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block',
      padding: '5px 10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      textDecoration: 'none',
      color: '#333',
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001',
      color: 'white',
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1',
      color: '#333',
    });

    $(api.table().container()).find('.dt-controls').css({
      display: 'flex',
      justifyContent: 'flex-start', // Aligns controls to the left
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      gap: '15px', // Add some space between elements if needed
    });

    $(api.table().container()).find('input[id^="dt-search-"]').css({
      height: '2.5rem',
      width: '15rem',
    });

    $(api.table().container()).find('select[id^="dt-length-"]').css({
      height: '2.5rem',
      width: '3rem',
    });

    $(api.table().container()).find('div.dt-length').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('div.dt-search').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#E00001',
      'border-width': '2px',
      'border-radius': '10px',
      'border-color': '#E00001',
      color: 'white',
      padding: '10px',
      cursor: 'pointer',
      'font-size': '1rem',
    });
  }

  return (
    <>
      <Box>
        {isLoading && (
          <Box
            display="flex"
            paddingTop={2}
            justifyContent="flex-start"
            position="absolute"
            top={0}
            left={0}
          >
            <Spinner />
          </Box>
        )}
        <table ref={tableRef} className="display" />
      </Box>
    </>
  );
};

export default IntegrationTable;
