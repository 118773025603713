import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: theme.shadows[1],
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: theme.spacing(4),
  },
  toolbar: {
    padding: theme.spacing(0, 4),
  },
}));
