import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sendEmailContainer: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    border: `2px solid #e00001`,
  },
  sendEmailTitle: {
    padding: theme.spacing(1),
    border: `2px solid #e00001`,
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(2),
    fontWeight: 700,
    alignSelf: 'center',
    backgroundColor: '#e00001',
    color: '#fff',
  },
  customSelect: {
    padding: 10,
    cursor: 'pointer',
    fontSize: 16,
    width: '100%',
    borderWidth: 1,
    color: 'grey',
    marginBottom: 15,
  },
  fieldTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#000',
  },
  titleSpan: {
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
  },
  whiteBackground: {
    backgroundColor: 'white',
    marginTop: 5,
    marginBottom: 15,
  },
}));

export default useStyles;
