import PropTypes from 'prop-types';

import { useStyles } from './styles';

const ProgressBar = ({ value, maxValue, children }) => {
  const percentage = Math.round((value * 100) / maxValue);
  const classes = useStyles({ percentage });

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <div className={classes.progress} />
      </div>
      <div className={classes.label}>{children}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
};

ProgressBar.defaultProps = {
  maxValue: 100,
};

export default ProgressBar;
