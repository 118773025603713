import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

const ContactListRowAction = ({
  icon,
  okText,
  cancelText,
  buttonLabel,
  entityLabel,
  onSuccess,
  contactModal,
  setContactModal,
}) => {
  const handleOpen = () => setContactModal(true);
  const handleClose = () => setContactModal(false);

  const endpointHelpText = (
    <Box component="ul" marginY={1} paddingX={2}>
      <li>
        This API endpoint should be used for both adding and removing contacts.
      </li>
      <li>
        In order to add contacts, use the POST method. In order to remove
        contacts, use the DELETE method.
      </li>
    </Box>
  );

  const headerHelpText = (
    <Box component="ul" marginY={1} paddingX={2}>
      <li>
        In place of the <strong>{`{{YOUR_API_KEY}}`}</strong> token, please
        place your API key which can be found by navigating to the profile menu
        in the top right corner of the page, and selecting "Integrations".
      </li>
    </Box>
  );

  const bodyHelpText = (
    <Box component="ul" marginY={1} paddingX={2}>
      <li>
        In the request body, replace the sample email addresses with the email
        addresses of the contacts you'd like to add or remove. Follow the shown
        structure in order to add more email addresses in the request body.
        Please note, adding custom fields is optional.
      </li>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: 'inherit' }} onClick={handleOpen}>
        {icon ?? buttonLabel}
      </Box>
      <Dialog open={contactModal} onClose={handleClose}>
        <DialogTitle>{buttonLabel}</DialogTitle>
        <DialogContent>
          <TextField
            label="API Endpoint"
            helperText={endpointHelpText}
            InputProps={{ readOnly: true, disabled: true }}
            value="https://api.inboxmailers.com/contacts"
          />
          <TextField
            label="Header"
            helperText={headerHelpText}
            InputProps={{ readOnly: true, disabled: true }}
            value={`{"x-api-key": "{{YOUR_API_KEY}}"}`}
          />
          <TextField
            multiline
            rows={7}
            label="Request Body"
            helperText={bodyHelpText}
            InputProps={{ readOnly: true, disabled: true }}
            value={`{
  "list_ids": ["${entityLabel}"],
  "contacts": [
    {"email": "abc@example.com","custom_fields": {"first_name":"Bob","last_name":"Test"}},
    {"email": "xyz@example.com"}
  ],
}`}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            {'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactListRowAction;
