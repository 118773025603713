import qs from 'qs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import CsvFileList from './CsvFileList';

const CsvFileListContainer = () => {
  const { listId } = useParams();

  // GET ALL ROWS
  const {
    isLoading,
    isFetching,
    data: rows = [],
    refetch: refetchRows,
  } = useQuery(
    ['csvFiles', listId],
    () => axios.get(`/records/files/${listId}`),
    {
      select: data => data.filter(row => row.fileName),
    },
  );

  const {
    mutate: updateNotificationState,
    isLoading: isNotificationStateLoading,
  } = useMutation(
    e =>
      axios.get(
        `/records/sendNotificationAfterImport?${qs.stringify({
          importId: e.target.name,
          shouldSendNotificationAfterImport: !e.target.checked,
        })}`,
      ),
    {
      onSuccess: () => {
        refetchRows();
      },
    },
  );

  return (
    <CsvFileList
      rows={rows}
      onNotificationStateChange={updateNotificationState}
      isLoading={isLoading || isFetching || isNotificationStateLoading}
    />
  );
};

export default CsvFileListContainer;
