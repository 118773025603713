import { makeStyles } from '@material-ui/core/styles';

const itemActiveStyles = theme => ({
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  '& $itemIcon': {
    color: '#fff',
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& $beta': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  },
});

const itemAdminActiveStyles = theme => ({
  color: '#000',
  //backgroundColor: theme.palette.primary.main,
  backgroundColor: '#e8c94d',
  '& $itemIcon': {
    color: '#fff',
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& $beta': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  },
});

// '&:hover': isAdmin =>
//   isAdmin ? itemAdminActiveStyles(theme) : itemActiveStyles(theme),
export const useStyles = makeStyles(theme => ({
  itemRoot: {
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['background-color', 'color', 'fontWeight'],
      { duration: 150 },
    ),
    '&:hover': itemActiveStyles(theme),
    margin: theme.spacing(0.5, 0),
  },
  itemRootAdmin: {
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['background-color', 'color', 'fontWeight'],
      { duration: 150 },
    ),
    '&:hover': itemAdminActiveStyles(theme),
    margin: theme.spacing(0.5, 0),
  },
  itemRootIsDropdownListLink: {
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['background-color', 'color', 'fontWeight'],
      { duration: 150 },
    ),
    margin: theme.spacing(0.5, 0),
    position: 'relative', // Add position relative to create a reference for the indicator
    cursor: 'pointer',
    '&:hover::before': {
      backgroundColor: theme.palette.primary.main,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: theme.spacing(4),
      transform: 'translateY(-50%)',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: 'rgb(126, 132, 163)',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  itemActive: itemActiveStyles(theme),
  itemActiveAdmin: itemAdminActiveStyles(theme),
  itemIcon: {
    minWidth: theme.spacing(5),
    color: 'rgb(126, 132, 163)',
    transition: theme.transitions.create('color', { duration: 150 }),
  },
  beta: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '0.25rem 0.4rem',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('color', { duration: 150 }),
  },
}));
