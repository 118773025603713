import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sendAllContainer: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    border: `2px solid #e00001`,
  },
  sendAllFormContainer: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  sendAllTitle: {
    padding: theme.spacing(1),
    border: `2px solid #e00001`,
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(2),
    fontWeight: 700,
    backgroundColor: '#e00001',
    color: '#fff',
  },
  customSelect: {
    padding: 10,
    cursor: 'pointer',
    fontSize: 16,
    width: 'auto',
    borderWidth: 1,
    color: 'grey',
    marginBottom: 12,
    marginTop: 14,
  },

  addButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%', // To make it a square
    padding: '8px', // To add some padding inside the square
    marginTop: '40px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  titleSpan: {
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
  },
}));

export default useStyles;
