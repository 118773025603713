import Logo from '../../assets/images/logo.png';
import { Box } from '@material-ui/core';

const CampaignIntegrationIcon = ({ width, height }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };
  const logoStyle = {
    width: width ? `${width}px` : '158px',
    height: height ? `${height}px` : '110px',
  };

  return (
    <Box sx={containerStyle}>
      <img src={Logo} alt="Logo" style={logoStyle} />
    </Box>
  );
};

export default CampaignIntegrationIcon;
