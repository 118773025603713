import axios from 'axios';
import { useMemo } from 'react';
import Link from '@material-ui/core/Link';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CodeOffOutlinedIcon from '@material-ui/icons/CodeOffOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

import DataGrid from '../../components/DataGrid';
import RowAction from '../../components/RowAction';
import RowActions from '../../components/RowActions';
import RouterLink from '../../components/RouterLink';

const columns = [
  {
    field: 'name',
    title: 'Name',
    width: '290px',
  },
  {
    field: 'description',
    title: 'Description',
  },
  {
    field: 'numberOfFires',
    title: 'Total opens',
    width: '200px',
    align: 'center',
  },
  {
    field: 'trigger',
    title: 'Trigger',
    width: '200px',
  },
  {
    field: 'actions',
    title: '',
    width: '156px',
  },
];

const PixelList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
  onPixelCodeGenerate,
}) => {
  const dataSource = useMemo(
    () =>
      rows.map(row => ({
        id: row.id,
        name: row.pixel_name,
        description: row.description,
        numberOfFires: row.fires_number,
        trigger: row.trigger_id ? (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/trigger-manager/${row.trigger_id}/edit`}
          >
            {row.trigger_name}
          </Link>
        ) : (
          `-`
        ),
        actions: (
          <RowActions
            actions={[
              {
                title: 'Copy Pixel Code',
                children: <CodeOffOutlinedIcon />,
                onClick: () => onPixelCodeGenerate(row),
              },
              {
                title: 'Edit',
                component: RouterLink,
                to: `/pixel/${row.id}/edit`,
                children: <BorderColorOutlinedIcon />,
              },
              {
                title: 'Delete',
                children: (
                  <RowAction
                    icon={<DeleteOutlinedIcon />}
                    buttonLabel="Delete"
                    actionLabel="delete"
                    entityLabel="pixel"
                    queryKey="pixelData"
                    queryFunction={() => axios.put(`/pixels/${row.id}/archive`)}
                  />
                ),
              },
            ]}
          />
        ),
      })),
    [rows, onPixelCodeGenerate],
  );

  return (
    <DataGrid
      pageable
      rowKey="id"
      rows={dataSource}
      columns={columns}
      loading={isLoading}
      totalPages={totalPages}
      totalElements={totalElements}
      onQueryChange={onQueryChange}
      emptyText="You don't have any pixels yet"
    />
  );
};

export default PixelList;
