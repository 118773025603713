import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  newOption: {
    display: 'flex',
    alignItems: 'center',
  },
  clearSelectIcon: {
    cursor: 'pointer',
    marginRight: '-3px',
    visibility: 'hidden',
    '.MuiInputBase-root:hover &': {
      visibility: 'visible',
    },
  },
  clearableSelect: {
    '&:hover .MuiSelect-icon': {
      visibility: 'hidden',
    },
  },
  partnerDetails: {
    display: 'flex',
    alignItems: 'center',
  },
}));
