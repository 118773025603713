import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/core/Pagination';
import TableContainer from '@material-ui/core/TableContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { Tooltip } from '@material-ui/core';
import { CustomToolTipStyles } from '../../styles/theme';

import { useStyles } from './styles';

const DataGrid = ({
  loading,
  columns,
  dense,
  rows,
  rowKey,
  emptyText,
  pageable,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const classes = useStyles({ dense });
  const customToolTipClasses = CustomToolTipStyles();

  const handlePageChange = useCallback(
    (_, newPage) => {
      onQueryChange(prevQuery => ({
        ...prevQuery,
        page: newPage,
      }));
    },
    [onQueryChange],
  );

  const convertTimestampToDate = timeStamp => {
    const date = new Date(timeStamp);
    return date.toDateString();
  };

  return (
    <>
      {loading && <LinearProgress />}
      <TableContainer component={Paper} className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.field}
                  width={column.width}
                  align={column.align}
                  className={
                    column.field === 'actions' || column.field === 'name'
                      ? classes.freezeCell
                      : ''
                  }
                >
                  {column.title}
                  {column.tooltipMessage ? (
                    <Tooltip
                      title={column.tooltipMessage}
                      arrow
                      classes={customToolTipClasses}
                    >
                      <span className={classes.tooltipIcon}>?</span>
                    </Tooltip>
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {emptyText ?? 'No Data'}
                </TableCell>
              </TableRow>
            )}
            {rows.map((row, key) => (
              <TableRow key={rowKey ? row[rowKey] : key}>
                {columns.map(column => (
                  <TableCell
                    align={column.align}
                    key={column.field}
                    className={
                      column.field === 'actions' || column.field === 'name'
                        ? classes.freezeCell
                        : ''
                    }
                  >
                    {
                      /*column.field === 'status' ? (
                      <Tooltip
                        title={`Uploaded: ${convertTimestampToDate(
                          row.createdAt,
                        )}`}
                        arrow
                        classes={customToolTipClasses}
                      >
                        {row[column.field]}
                      </Tooltip>
                    ) : (
                      row[column.field]
                    )*/
                      row[column.field]
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {pageable && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop={3.5}
            marginBottom={1.5}
          >
            <Pagination
              shape="rounded"
              count={totalPages}
              onChange={handlePageChange}
            />
            <div>
              {typeof totalElements === 'number' && (
                <Typography color="textSecondary">
                  {totalElements} Results
                </Typography>
              )}
            </div>
          </Box>
        )}
      </TableContainer>
      {loading && <LinearProgress />}
    </>
  );
};

DataGrid.propTypes = {
  dense: PropTypes.bool,
  loading: PropTypes.bool,
  pageable: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      align: PropTypes.string,
      width: PropTypes.string,
      field: PropTypes.string.isRequired,
    }),
  ),
  rows: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.string,
  onQueryChange: PropTypes.func,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DataGrid;
