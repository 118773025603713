import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { FormResolver } from './validation';
import { Handle, Position } from 'reactflow';
import PanToolOutlined from '@material-ui/icons/PanToolOutlined';

const Wait = ({ handleUpdateNodeData, node }) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    delayError: 500,
    defaultValues: {
      wait: node.data.actionDetails?.wait,
      waitUnit: node.data.actionDetails?.waitUnit,
    },
    resolver: FormResolver,
  });

  const handleFieldChange = (field, value) => {
    const updatedData = {
      ...node.data,
      actionDetails: {
        ...node.data.actionDetails,
        [field]: value,
        waitUnit: 'hrs',
      },
    };
    handleUpdateNodeData(updatedData, node);
  };

  return (
    <Box className={classes.sendEmailContainer}>
      <Handle type="target" position={Position.Top} />
      <Typography variant="h6" className={classes.sendEmailTitle}>
        <span className={classes.titleSpan}>
          <PanToolOutlined /> WAIT (hrs)
        </span>
      </Typography>
      <form style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          placeholder="0"
          variant="outlined"
          size="small"
          margin="normal"
          {...register('wait')}
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 2,
          }}
          error={!!errors.wait}
          helperText={errors.wait?.message}
          className={classes.whiteBackground}
          onChange={event => handleFieldChange('wait', event.target.value)}
        />
      </form>

      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

export default Wait;
