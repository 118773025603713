import * as yup from 'yup';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useMutation, useQuery } from 'react-query';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

const validationSchema = yup.object({
  oldPassword: yup.string().label('Old Password').min(8).required(),
  newPassword1: yup.string().label('New Password').min(8).required(),
  newPassword2: yup
    .string()
    .label('Confirm New Password')
    .oneOf([yup.ref('newPassword1'), null], 'Passwords must match')
    .required(),
});

const ChangePasswordDialog = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: user, isLoading: isUserLoading } = useQuery(
    'currentAuthenticatedUser',
    () => Auth.currentAuthenticatedUser(),
  );

  const { mutateAsync: changePassword } = useMutation(
    ({ oldPassword, newPassword }) =>
      Auth.changePassword(user, oldPassword, newPassword),
    {
      onSuccess: () => {
        enqueueSnackbar('Password has been changed successfully', {
          variant: 'success',
        });

        onClose();
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    },
    validationSchema,
    onSubmit: values =>
      changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword1,
      }),
  });

  const isLoading = formik.isSubmitting || isUserLoading;

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      {isLoading && <LinearProgress />}
      <DialogTitle>Change your password</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            type="password"
            name="oldPassword"
            label="Old Password"
            autoComplete="current-password"
            placeholder="Enter Password"
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
          <TextField
            type="password"
            name="newPassword1"
            label="New Password"
            autoComplete="new-password"
            placeholder="Enter Password"
            onChange={formik.handleChange}
            value={formik.values.newPassword1}
            error={
              formik.touched.newPassword1 && Boolean(formik.errors.newPassword1)
            }
            helperText={
              formik.touched.newPassword1 && formik.errors.newPassword1
            }
          />
          <TextField
            type="password"
            name="newPassword2"
            autoComplete="new-password"
            label="Confirm New Password"
            placeholder="Enter Password"
            onChange={formik.handleChange}
            value={formik.values.newPassword2}
            error={
              formik.touched.newPassword2 && Boolean(formik.errors.newPassword2)
            }
            helperText={
              formik.touched.newPassword2 && formik.errors.newPassword2
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
