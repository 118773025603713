import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Auth, Hub } from 'aws-amplify';
import { useQuery, useQueryClient } from 'react-query';
import {
  AmplifySignIn,
  AmplifyAuthenticator,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';

import Spinner from '../Spinner';
import { useStyles } from './styles';
import { runLou } from '../../utils/lou';
import Logo from '../../assets/images/logo.png';

const AuthProvider = ({ children }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(
    'currentUser',
    () => Auth.currentUserInfo(),
    {
      onSuccess: data => {
        setTimeout(() => {
          runLou(data);
        }, 100);
      },
    },
  );

  useEffect(() => {
    const onAuthChange = ({ payload }) => {
      if (
        ['signIn', 'signOut', 'tokenRefresh_failure'].includes(payload.event)
      ) {
        refetch();
        queryClient.clear();
      }
    };

    Hub.listen('auth', onAuthChange);

    return () => {
      Hub.remove('auth', onAuthChange);
    };
  }, [refetch, queryClient]);

  if (data?.username) {
    return children;
  }

  return isLoading ? (
    <Spinner fixed />
  ) : (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img className={classes.logo} src={Logo} alt="logo" />
      </Box>
      <AmplifyAuthenticator>
        <AmplifySignIn
          hideSignUp
          usernameAlias="email"
          headerText=""
          slot="sign-in"
        />
        <AmplifyForgotPassword
          hideSignUp
          usernameAlias="email"
          slot="forgot-password"
        />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthProvider;
