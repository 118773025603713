import { Box, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import Spinner from '../../components/Spinner';
import { getCampaign } from '../../utils/apis/campaigns';
import TriggerCampaignForm from '../../components/CampaignBuilder/TriggerCampaignForm';

const TriggerCampaignEditor = () => {
  const { campaignId } = useParams();
  const {
    data: { data: campaign } = { data: {} },
    error,
    isLoading,
  } = useQuery(
    ['email-template', campaignId],
    async () => await getCampaign(campaignId),
    {
      enabled: !!campaignId, // Enable the query only when partner.id is truthy
      staleTime: 0,
    },
  );
  if (error) {
    return <Typography>{error.message}</Typography>;
  }
  if (isLoading) {
    return <Spinner fixed />;
  }
  const initialCampaignData = {
    title: campaign?.title || '',
    description: campaign?.description || '',
    webhookUrl: campaign?.webhook_url || '',
    id: campaign?.id || null,
    actions: campaign?.campaign_actions || [],
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <TriggerCampaignForm
        initialCampaignData={initialCampaignData}
        enableIcon
      />
    </Box>
  );
};

export default TriggerCampaignEditor;
