import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  buttonsList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& > li': {
      flexShrink: 0,
      display: 'block',
      marginLeft: theme.spacing(4),
    },
  },
  tooltipIcon: {
    background: '#ffffff',
    color: '#000000',
    borderRadius: '50%',
    width: '17px',
    height: '17px',
    lineHeight: '14px',
    textAlign: 'center',
    fontSize: '12px',
    border: '2px solid black',
    marginLeft: '0.5em',
    fontWeight: 600,
  },
}));
