import axios from 'axios';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Spinner from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import CsvFileListContainer from './CsvFileListContainer';
import UploadContactsContainer from './UploadContactsContainer';

const ListView = () => {
  const history = useHistory();
  const { listId } = useParams();

  const { data: list } = useQuery(
    ['list', listId],
    () => axios.get(`/partner-lists/${listId}`),
    {
      enabled: !!listId,
      onError: () => {
        history.push('/404');
      },
    },
  );

  if (listId && !list) {
    return <Spinner fixed />;
  }

  return (
    <>
      <PageHeader
        title={`Add Contacts to ${list.name}`}
        buttons={[
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => history.push('/list-manager')}
          >
            Finish
          </Button>,
          <UploadContactsContainer />,
        ]}
      />
      <CsvFileListContainer />
    </>
  );
};

export default ListView;
