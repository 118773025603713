import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 1.75),
    color: 'rgb(161, 167, 196)',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgb(224, 224, 224)',
    transition: theme.transitions.create('backgroundColor'),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));
