import { useParams } from 'react-router-dom';
import CampaignActionsBuilder from '../../components/CampaignBuilder';
import { useQuery } from 'react-query';
import { getCampaign } from '../../utils/apis/campaigns';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { Typography } from '@material-ui/core';
import Spinner from '../../components/Spinner';
import { transformOriginalActionsData } from '../../components/CampaignBuilder/utils';

const CampaignBuilder = () => {
  const { campaignId } = useParams();
  const partner = useCurrentPartner();
  const {
    data: { data: campaign } = [],
    error,
    isLoading,
  } = useQuery(
    ['campaign', campaignId, partner.id],
    async () => await getCampaign(campaignId, partner.id),
    {
      enabled: !!campaignId && !!partner.id, // Enable the query only when partner.id is truthy
      staleTime: 0,
    },
  );
  if (error) {
    return <Typography>{error.message}</Typography>;
  }
  if (isLoading) {
    return <Spinner fixed />;
  }
  const initialBuilderData = {
    id: campaign?.id,
    title: campaign?.title,
    description: campaign?.description,
    actions: transformOriginalActionsData(campaign?.campaign_actions) || [],
    nodes: campaign?.campaign_designs[0]?.design_details?.nodes || [],
    edges: campaign?.campaign_designs[0]?.design_details?.edges || [],
  };
  return <CampaignActionsBuilder initialBuilderData={initialBuilderData} />;
};

export default CampaignBuilder;
