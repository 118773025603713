import { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { useStyles } from './styles';
import { displayTypes } from './index';

import ExportCreatives from './ExportCreatives';

const dateFilters = {
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'Last 3 days',
  thisMonth: 'Last 7 days',
  thisYear: 'Last 30 days',
  allTime: 'All Time',
  customDate: 'Custom',
};

const pixelStatuses = {
  active: '1',
  all: '',
  deleted: '2',
};

const pixelOwnerFilters = {
  all: 'All',
  myPixel: 'My Pixels',
  network: 'Network Pixels',
};

const emailTemplatesFilter = {
  all: 'All Templates',
  myEmails: 'My Emails',
  oldEmails: 'Old Emails',
};

let d = new Date();
// d.setDate(d.getDate() - 7);
d.setDate(d.getDate());
let frmDt = d.toISOString();
frmDt = frmDt.split('T', 1);
// let toDt = new Date().toISOString();
// toDt = toDt.split('T', 1);
let toDt = new Date();
toDt.setHours(24, 0, 0, 0); // Sets the time to midnight tonight
toDt = toDt.toISOString().split('T'); // Get only the date part in 'YYYY-MM-DD' format

const FiltersBox = ({ onFiltersSet, displayType, exportFunc }) => {
  const [clickExport, setClickExport] = useState(false);

  const [filters, setFilters] = useState({
    date: dateFilters.today,
    from: frmDt[0],
    to: toDt[0],
    name: '',
    pixelStatus: pixelStatuses.active,
    pixelOwner: pixelOwnerFilters.all,
    emailTemplateTitle: emailTemplatesFilter.all,
  });

  const setDateFromToday = date => {
    const d = new Date();
    switch (date) {
      case 'Yesterday':
        d.setDate(d.getDate() - 1);
        break;
      case 'Last 3 days':
        d.setDate(d.getDate() - 2);
        break;
      case 'Last 7 days':
        d.setDate(d.getDate() - 6);
        break;
      case 'Last 30 days':
        d.setDate(d.getDate() - 29);
        break;
      default:
        break;
    }
    return d.toISOString().split('T', 1)[0];
  };

  const [regChange, setRegChange] = useState('');
  const classes = useStyles();

  const {
    isLoading,
    isFetching,
    data: pixels = [],
  } = useQuery('pixelData', () => axios.get('/pixels?pageSize=100'), {
    select: data => data.content,
    enabled: displayType === displayTypes.incomingAlerts,
  });

  const handleExportClick = (displayType, filters) => {
    setClickExport(true);
    exportFunc(displayType, filters);
  };

  useEffect(() => {
    setFilters({
      date: dateFilters.today,
      from: frmDt[0],
      to: toDt[0],
      name: '',
      pixelStatus: pixelStatuses.active,
      pixelOwner: pixelOwnerFilters.all,
      emailTemplateTitle: emailTemplatesFilter.active,
    });
  }, [displayType]);

  return (
    <Box display="flex" alignItems="flex-start">
      <FormControl className={classes.formControl}>
        <TextField
          select
          label="Date"
          value={filters.date}
          onChange={event => {
            setFilters(prevFilters => ({
              ...prevFilters,
              date: event.target.value,
              from: setDateFromToday(event.target.value),
            }));
            console.log('DATES: ', filters.from, ' : ', filters.to);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" variant="standard">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        >
          {Object.entries(dateFilters).map(([key, value]) => {
            return (
              <MenuItem value={value} key={key}>
                {value}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
      {filters.date === 'Custom' && (
        <>
          <FormControl className={classes.formControl}>
            <TextField
              label="From"
              type="date"
              value={filters.from}
              onChange={event =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  from: event.target.value,
                }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
            <TextField
              label="To"
              type="date"
              value={filters.to}
              onChange={event =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  to: event.target.value,
                }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </FormControl>
        </>
      )}
      {displayType === displayTypes.myPixels && (
        <>
          <FormControl className={classes.formControl}>
            <TextField
              select
              label="Pixel name"
              SelectProps={{ displayEmpty: true }}
              value={filters.name}
              onChange={event =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  name: event.target.value,
                }))
              }
              disabled={isLoading || isFetching || pixels.length === 0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" variant="standard">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="">All</MenuItem>
              {pixels.map((item, key) => (
                <MenuItem value={item.pixel_name} key={key}>
                  {item.pixel_name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              select
              label="Pixel status"
              SelectProps={{ displayEmpty: true }}
              value={filters.pixelStatus}
              onChange={event =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  pixelStatus: event.target.value,
                }))
              }
            >
              <MenuItem value={pixelStatuses.active}>Active</MenuItem>
              <MenuItem value={pixelStatuses.all}>All</MenuItem>
              <MenuItem value={pixelStatuses.deleted}>Deleted</MenuItem>
            </TextField>
          </FormControl>
        </>
      )}
      {displayType === displayTypes.myTriggers && (
        <>
          <FormControl className={classes.formControl}>
            <TextField
              select
              label="Pixel Owner"
              value={filters.pixelOwner}
              onChange={event =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  pixelOwner: event.target.value,
                }))
              }
            >
              {Object.entries(pixelOwnerFilters).map(([key, value]) => (
                <MenuItem value={value} key={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </>
      )}

      <Button
        color="primary"
        variant="contained"
        sx={{ marginTop: 4.5 }}
        onClick={() => {
          onFiltersSet(filters);
          setRegChange(filters);
        }}
      >
        Search
      </Button>
      {displayType !== displayTypes.myEmailTemplates && (
        <Button
          color="secondary"
          variant="outlined"
          sx={{ marginTop: 4.5, marginLeft: 4.5 }}
          onClick={() => handleExportClick(displayType, filters)}
          disabled={clickExport}
        >
          Export
        </Button>
      )}
      {displayType === displayTypes.myEmailTemplates && (
        <>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ marginTop: 4.5, marginLeft: 4.5 }}
          >
            <ExportCreatives
              filterDate={filters.date}
              filterFrom={filters.from}
              filterTo={filters.to}
              regChange={regChange}
              style={{ textDecoration: 'none' }}
            />
          </Button>
        </>
      )}
    </Box>
  );
};

export default FiltersBox;
