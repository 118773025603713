const appendHeaderFooterToHtml = (htmlString, header, footer) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Create and append header
  const headerContainer = doc.createElement('div');
  headerContainer.innerHTML = header;
  doc.body.insertBefore(headerContainer, doc.body.firstChild);

  // Create and append footer
  const footerContainer = doc.createElement('div');
  footerContainer.innerHTML = footer;
  doc.body.appendChild(footerContainer);

  return doc.documentElement.outerHTML;
};
export default appendHeaderFooterToHtml;
