import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      padding: 0,
      margin: 0,
      borderBottom: 'none',
    },
    '& + hr': {
      padding: 0,
      margin: 0,
      borderBottom: 'none',
    },
    '& + .MuiFormControl-root': {
      marginTop: 0,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  tooltipIcon: {
    background: '#ffffff',
    color: '#000000',
    borderRadius: '50%',
    width: '17px',
    height: '17px',
    lineHeight: '14px',
    textAlign: 'center',
    fontSize: '12px',
    border: '2px solid black',
    marginLeft: '0.5em',
    fontWeight: 600,
  },
}));
