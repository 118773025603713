import axios from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';

import { useStyles } from './styles';
import Spinner from '../../components/Spinner';

const IntegrationApiKey = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied to clipboard successfully!', {
      variant: 'success',
    });
  }, [enqueueSnackbar]);

  const { data: apiKey = 'Loading...', isLoading } = useQuery('apiKey', () =>
    axios.get('/partner/api-key'),
  );

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={11} md={6}>
          <TextField
            margin="none"
            label="API Key"
            helperText="You need API key to access InboxMailers web services."
            value={apiKey || 'You do not have any active keys.'}
            InputProps={{ readOnly: true, disabled: isLoading || !apiKey }}
          />
        </Grid>
        <Grid item xs={1} md={6}>
          <Box marginTop={3}>
            <CopyToClipboard text={apiKey} onCopy={handleCopy}>
              <IconButton size="small" color="primary" disabled={!apiKey}>
                {isLoading ? <Spinner size={24} /> : <ContentCopyIcon />}
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IntegrationApiKey;
