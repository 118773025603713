import React from 'react';
import ReactDOM from 'react-dom';

import Root from './components/Root';

import './beforeLaunch/amplify';
import './beforeLaunch/axios';
import './beforeLaunch/gtm';

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
