import { MenuItem } from '@material-ui/core';

export const defaultRenderOption = (props, option, optionProperty) => {
  let propertyToDisplay = '';

  if (Array.isArray(optionProperty)) {
    // If optionProperty is an array, find the first existing and non-empty property
    for (const prop of optionProperty) {
      const trimmedValue = option[prop]?.trim();
      if (trimmedValue) {
        propertyToDisplay = trimmedValue;
        break; // Exit loop when the first suitable property is found
      }
    }
  } else {
    // If optionProperty is a single property, use it directly
    propertyToDisplay = option[optionProperty]?.trim() || '';
  }

  return propertyToDisplay ? (
    <MenuItem {...props} key={option.id}>
      {propertyToDisplay}
    </MenuItem>
  ) : null;
};
export const getOptionLabel = (option, optionProperty) => {
  const getTrimmedValue = value => {
    // Regular expression to match URLs starting with "http://" or "https://"
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,5})(\/[^\s]*)?$/;
    const match = value?.match(urlRegex);

    if (match) {
      // If it's a URL, return the domain name without "http://", "https://", "www.", and ".com"
      return match[3]?.replace(/(https?:\/\/|www\.|\.com)/gi, '');
    } else {
      // If not a URL, return the trimmed value and remove ".com" if it exists
      const trimmedValue = value?.trim();
      return trimmedValue?.endsWith('.com')
        ? trimmedValue?.slice(0, -4)
        : trimmedValue;
    }
  };

  if (Array.isArray(optionProperty)) {
    // If optionProperty is an array, find the first existing and non-empty property
    for (const prop of optionProperty) {
      const trimmedValue = getTrimmedValue(option[prop]);
      if (trimmedValue) {
        return trimmedValue;
      }
    }
  } else {
    // If optionProperty is a single property, use it directly
    const trimmedValue = getTrimmedValue(option[optionProperty]);
    return trimmedValue || '';
  }
};
