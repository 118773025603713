import React from 'react';
import { Box } from '@material-ui/core';
import { appPermissions, useAccess } from '../../hooks/useAccess';

function PartnerActivity() {
  const { hasPermission } = useAccess();

  return hasPermission(appPermissions.adminRole) ? (
    <>
      <h1>Partners Activity</h1>
      <iframe
        src="https://metabase.smartbrandscompany.com/public/dashboard/bb2b6e15-de1d-4050-af44-7d0fb1efb3ee"
        frameborder="0"
        width="1145"
        height="600"
        allowtransparency="true"
        title="Metabase Dashboard"
      ></iframe>
    </>
  ) : (
    <Box>
      <div className="permission-error" style={{ color: '#777' }}>
        You do not have permissions. Please contact support.
      </div>
    </Box>
  );
}

export default PartnerActivity;
