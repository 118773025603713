const replacePlaceholders = (emailContent, userData) => {
  // Create a case-insensitive map of userData
  const caseInsensitiveUserData = new Map();
  for (const [key, value] of Object.entries(userData)) {
    caseInsensitiveUserData.set(key.toLowerCase(), value);
  }

  const regex = /{{(\w+)}}|%%(\w+)%%|\[\[(\w+)\]\]/gi;

  return emailContent?.replace(regex, match => {
    const key = match?.replace(/{{|}}|%%|\[\[/g, '').toLowerCase();
    return caseInsensitiveUserData.has(key)
      ? caseInsensitiveUserData.get(key)
      : match;
  });
};
export default replacePlaceholders;
