import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3.5),
    borderRadius: 6,
  },
  statusSwitchTrack: {
    opacity: 1,
    backgroundColor: theme.palette.error.main,
    '.MuiSwitch-switchBase.Mui-checked + &': {
      opacity: 1,
      backgroundColor: theme.palette.success.main,
    },
  },
  rechargeInputs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  costText: {
    maxWidth: '90px',
    marginLeft: '20px',
  },
  errorMsg: {
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    marginBottom: '18px',
    color: '#e00001',
  },
}));
