const appearance = {
  loader: {
    html: '<div class="custom-spinner"></div>',
    css: `.custom-spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-top: 4px solid rgb(224, 0, 1);
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }`,
  },
};
export default appearance;
