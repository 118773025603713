import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    outline: 'none',
  },
  modalContent: {
    width: props => props.width || '500px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
    outline: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },
}));

export default useStyles;
