const API_BASE_URL =
  process.env.REACT_APP_BASE_WYSIWYG_API_URL ||
  'https://dev-wysiwyg-im.smartbrandscompany.com/wysiwyg/api/v1';

export const sendTestEmail = async data => {
  const apiUrl = `${API_BASE_URL}/smtp/send`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const sendTestEmailUpdated = async data => {
  const apiUrl = `${API_BASE_URL}/smtp/updated/send`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
