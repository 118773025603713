import {
  Box,
  Button,
  Card,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getEmailTemplate } from '../../utils/apis/emailTemplates';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Spinner from '../../components/Spinner';
import ChosenTemplate from '../../components/ChosenTemplate';
import axios from 'axios';
import { ArrowBack } from '@material-ui/icons';
import { useState } from 'react';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';

const TemplateGallery = () => {
  const [parentSelectLoading, setParentSelectLoading] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState('');
  const queryClient = useQueryClient();
  const partner = useCurrentPartner();
  const [submitLoader, setSubmitLoader] = useState(false);
  const { customid } = useParams();

  const handleChildSelectLoading = isLoading => {};

  const { data, error, isLoading } = useQuery('fetchEmailTemp', async () => {
    const response = await axios.get(`${API_BASE_URL}/templates`);

    return response.data;
  });

  const handleChosenTemplate = async id => {
    setParentSelectLoading(true);
    const updatedData = {
      templateId: id,
      partnerId: partner.id,
    };
    try {
      await fetchTemplateById(updatedData);
    } catch (error) {
      setParentSelectLoading(false);
    }
  };

  const fetchTemplateById = async ({ templateId, partnerId }) => {
    const response = await axios.get(
      `${API_BASE_URL}/templates/${templateId}/${partnerId}/use`,
    );

    const editedTemplateId = response.data.id;
    history.push(`/emails/${editedTemplateId}/edit`);

    return response.data;
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ArrowBack />
          <Typography>Back</Typography>
        </Box>
        <Typography
          sx={{ fontWeight: 'bolder', marginBottom: '20px' }}
          variant="h4"
        >
          Template gallery : Choose a template
        </Typography>
      </Box>
      {isLoading && <Spinner />}

      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexWrap: 'wrap',
          marginTop: 50,
        }}
      >
        {data &&
          data.map((item, index) => (
            <ChosenTemplate
              data={item}
              key={index}
              handleChosenTemplate={handleChosenTemplate}
              //   loading={loading}
            />
          ))}
      </div>
      {parentSelectLoading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 0.7)"
          zIndex={1000}
        >
          <Box textAlign="center">
            <CircularProgress />
            <Typography>
              Please wait while your template is being prepared...
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TemplateGallery;
