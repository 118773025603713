import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';

import axios from 'axios';
import * as yup from 'yup';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';

import './style.css';

const UploadActivityManager = ({ isOpen, onClose, setShowSuccess }) => {
  const [file, setFile] = useState('');
  const [fileSizeMsg, setFileSizeMsg] = useState('Accepts CSV Files Only');
  const maxSize = 104857600;

  const validationSchema = yup.object({
    name: yup.string().label('Activity Name').max(32).required(),
    description: yup.string().label('Description').max(100),
  });

  console.log('ACTIVITY BUILDER LOADED!');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { activityId } = useParams();

  function saveS3File() {
    axios
      .get(`/getActivitySignedURL?fileName=${encodeURIComponent(file.name)}`)
      .then(response => {
        // Assume the correct content type is returned in the response, or set it appropriately if known
        const contentType = 'text/csv'; // Adjust as needed
        const myHeaders = new Headers({ 'Content-Type': contentType });

        setShowSuccess(true);

        // Directly use the file object as the body, without JSON.stringify
        const fet = fetch(response.uploadURL, {
          // Ensure the correct property is used to access the URL
          method: 'PUT',
          headers: myHeaders,
          body: file, // Directly use the file object
        })
          .then(nex => {
            // Handle the response here if necessary
            axios
              .get(
                `/activityDBSave?eventType=postActivity&fileName=${encodeURIComponent(
                  response.csvFilename,
                )}`,
              )
              .then(next => {
                setTimeout(() => setShowSuccess(false), 3000);
              })
              .catch(error => {
                console.error('Error saving to DB:', error);
                // Handle the error appropriately
              });
          })
          .catch(error => {
            console.error('Error uploading to S3:', error);
            // Handle the error appropriately
          });

        return fet;
      })
      .catch(error => {
        console.error('Error getting signed URL:', error);
        // Handle the error appropriately
      });
  }

  function checkFileUploadSize(file) {
    if (file.size > maxSize) {
      setFileSizeMsg(`Filesize is too large.`);
    } else {
      setFileSizeMsg(`Uploaded: ${file.name}`);
    }
  }

  const handleProceed = () => {
    saveS3File();
    onClose();

    setTimeout(() => {
      setFile('');
      setFileSizeMsg('Accepts CSV Files Only');
    }, 1000);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setFile('');
      setFileSizeMsg('Accepts CSV Files Only');
    }, 500);
  };

  const handleFileInputChange = e => {
    checkFileUploadSize(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="add-activity-popup">
      <div className="add-activity-popup__close">
        <IconButton onClick={handleClose} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
      <DialogContent className="add-activity-popup__inputs">
        <CloudUploadIcon className="uploadIcon" />
        <label htmlFor="file-upload">Select a file to import</label>
        <input
          type="file"
          id="file-upload"
          accept=".csv"
          onChange={e => handleFileInputChange(e)}
        />
      </DialogContent>
      <DialogContent>
        <div
          className={`add-activity-popup__inputs--uploaded_file ${
            file.size > maxSize ? 'error' : ''
          }`}
        >
          {fileSizeMsg}
        </div>
      </DialogContent>
      <DialogActions className="add-activity-popup__buttons">
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleProceed}
          color="primary"
          variant="contained"
          disabled={!file ? true : false}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadActivityManager;
