import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { nodeOptions } from './utils';

import OutboxIcon from '@material-ui/icons/Outbox';
import HourglassTopIcon from '@material-ui/icons/HourglassTop';
import PanToolOutlined from '@material-ui/icons/PanToolOutlined';
import CallSplit from '@material-ui/icons/CallSplit';
import SendIcon from '@material-ui/icons/Send';

const FlowOptions = () => {
  const classes = useStyles();
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Box className={classes.aside}>
      <Typography className={classes.description}>
        You can drag these actions.
      </Typography>
      <Box
        className={`${classes.dndnode} input`}
        onDragStart={event => onDragStart(event, nodeOptions.SEND_EMAIL)}
        draggable
      >
        <span className={classes.titleSpan}>
          <SendIcon /> SEND EMAIL
        </span>
      </Box>

      <Box
        className={`${classes.dndnode} input`}
        onDragStart={event => onDragStart(event, nodeOptions.AB_SPLIT)}
        draggable
      >
        <span className={classes.titleSpan}>
          <CallSplit />
          AB SPLIT
        </span>
      </Box>
      <Box
        className={`${classes.dndnode} input`}
        onDragStart={event => onDragStart(event, nodeOptions.SEND_ALL)}
        draggable
      >
        <span className={classes.titleSpan}>
          <OutboxIcon />
          SEND ALL
        </span>
      </Box>
      <Box
        className={`${classes.dndnode} input`}
        onDragStart={event => onDragStart(event, nodeOptions.DELAY)}
        draggable
      >
        <span className={classes.titleSpan}>
          <HourglassTopIcon /> DELAY
        </span>
      </Box>
      <Box
        className={`${classes.dndnode} input`}
        onDragStart={event => onDragStart(event, nodeOptions.WAIT)}
        draggable
      >
        <span className={classes.titleSpan}>
          <PanToolOutlined /> WAIT
        </span>
      </Box>
    </Box>
  );
};

export default FlowOptions;
