import React from 'react';
import { Paper, Box } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { useStyles } from './styles';

const EmailPreview = ({ html }) => {
  const sanitizedHtml = DOMPurify.sanitize(html);
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box width="600px" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </Paper>
  );
};

export default EmailPreview;
