import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '20px',
    lineHeight: '20px',
    overflow: 'hidden',
    borderRadius: '4px',
    textAlign: 'center',
    position: 'relative',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
    '& div': {
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      position: 'absolute',
    },
  },
  bar: {
    opacity: 0.2,
    background: theme.palette.grey[300],
  },
  progress: {
    background: theme.palette.primary.main,
    transition: theme.transitions.create('transform', 400, 'linear'),
    transformOrigin: 'left',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    transform: props => `translateX(${props.percentage - 100}%)`,
    borderTopRightRadius: props => (props.percentage >= 100 ? '4px' : 0),
    borderBottomRightRadius: props => (props.percentage >= 100 ? '4px' : 0),
  },
  label: {
    whiteSpace: 'nowrap',
    color: props =>
      props.percentage === 0
        ? 'rgb(134, 138, 144)'
        : theme.palette.primary.main,
  },
}));
