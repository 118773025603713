import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  newOption: {
    display: 'flex',
    alignItems: 'center',
  },
  clearSelectIcon: {
    cursor: 'pointer',
    marginRight: '-3px',
    visibility: 'hidden',
    '.MuiInputBase-root:hover &': {
      visibility: 'visible',
    },
  },
  clearableSelect: {
    '&:hover .MuiSelect-icon': {
      visibility: 'hidden',
    },
  },
  refreshButton: {
    marginLeft: 'auto', // Align to the right
    height: 30,
    width: 30,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Add box shadow
  },
}));
