import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContentText from '@material-ui/core/DialogContentText';

import './styles.css';

const CustomPopup = ({
  icon,
  okText,
  cancelText,
  buttonLabel,
  messageToDisplay,
  entityLabel,
  queryKey,
  onSuccess,
  queryFunction,
  open,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const handleClose = () => setOpen(false);

  const { mutate: action, isLoading } = useMutation(queryFunction, {
    onSuccess: data => {
      queryKey && queryClient.invalidateQueries(queryKey);

      handleClose();

      if (onSuccess) {
        onSuccess(data, queryClient);
      }
    },
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        {isLoading && <LinearProgress />}
        <section className="custom-popup">
          <DialogTitle>{entityLabel}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span>{messageToDisplay}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose}>
              {cancelText ?? 'No'}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() => action()}
            >
              {okText ?? 'Yes'}
            </Button>
          </DialogActions>
        </section>
      </Dialog>
    </>
  );
};

export default CustomPopup;
