import React from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import { SnackbarProvider } from 'notistack';
import { StylesProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';

import App from '../App';
import theme from '../../styles/theme';
import AuthProvider from '../AuthProvider';
import PartnerProvider from '../PartnerProvider';
import QueryCacheProvider from '../QueryCacheProvider';

const Root = () => {
  return (
    <NoSsr>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <QueryCacheProvider>
              <AuthProvider>
                <PartnerProvider>
                  <StyledEngineProvider injectFirst>
                    <StylesProvider>
                      <App />
                    </StylesProvider>
                  </StyledEngineProvider>
                </PartnerProvider>
              </AuthProvider>
            </QueryCacheProvider>
          </SnackbarProvider>
        </>
      </ThemeProvider>
    </NoSsr>
  );
};

export default Root;
