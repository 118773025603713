import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    background: '#ffffff',
    color: '#000000',
    borderRadius: '50%',
    width: '14px',
    height: '14px',
    lineHeight: '11px',
    textAlign: 'center',
    fontSize: '9px',
    border: '2px solid black',
    marginLeft: '0.5em',
    fontWeight: 600,
  },
  headline: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.43',
    color: 'rgb(90, 96, 127)',
  },
  m24: {
    margin: '24px',
  },
  pl24: {
    paddingLeft: '24px',
  },
  pausedStatusMsg: {
    fontSize: 14,
  },
}));
