import axios from 'axios';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import ListCollection from './ListCollection';
import RouterLink from '../../components/RouterLink';
import PageHeader from '../../components/PageHeader';
import Banner from '../../components/Banner';
import UploadActivityManager from '../../components/UploadActivityManager/inbox';
import { useSnackbar } from 'notistack';

const ActivityManager = () => {
  const [query, setQuery] = useState({ page: 1 });
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data: listCollection } = useQuery(
    ['activityLists', query],
    () => {
      const source = axios.CancelToken.source();

      const promise = axios.get(`/activityDBSave?eventType=getActivity`, {
        cancelToken: source.token,
      });

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };

      return promise;
    },
    { refetchInterval: 10000, keepPreviousData: true },
  );

  const { mutateAsync: fileUploadedSuccess } = useMutation(() => {}, {
    onSuccess: () => {
      enqueueSnackbar('File successfully uploaded.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to upload file.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  const handleShowPopupOpen = () => {
    setShowPopup(true);
  };

  const handleShowPopupClose = () => {
    setShowPopup(false);
  };

  const handleShowSuccess = bool => {
    setShowSuccess(bool);
    if (bool) fileUploadedSuccess(showSuccess);
  };

  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/member-activity-checker/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader
        title="Activity Checker"
        buttons={[
          <Button
            color="primary"
            to="#"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
            onClick={handleShowPopupOpen}
          >
            Add new
          </Button>,
        ]}
      />
      <section className="action-checker__how-to-text">
        <p>
          To try out the Inbox Mailer Activity Checker for yourself, follow
          these simple steps:
        </p>

        <ul>
          <ol>
            <strong>1.</strong> Find the <em>CSV*</em> file you want to run
            through the Activity Checker.
          </ol>
          <ol>
            <strong>2.</strong> Click the{' '}
            <span className="action-checker__how-to-text--button-hightlight">
              + Add new
            </span>{' '}
            button in the top, right corner.
          </ol>
          <ol>
            <strong>3.</strong> In the file upload form, click the "Select a
            file to import” button to select your <em>CSV*</em> file.
          </ol>
          <ol>
            <strong>4.</strong> Then click the{' '}
            <span className="action-checker__how-to-text--button-hightlight">
              Proceed
            </span>{' '}
            button.
          </ol>
          <ol>
            <strong>5.</strong> Your <em>CSV*</em> file will be run through the
            Activity Checker, and the results will be presented in the table
            below.
          </ol>
        </ul>

        <p>
          <strong>Note:</strong> Depending on the size of the list, it may take
          a few minutes for your <em>CSV*</em> list to be checked. While this is
          happening, the status of your list will be set as “Pending“.
        </p>
        <p>* Please make sure your CSV file fits the required format.</p>
        <h2>File Formatting</h2>
        <p>
          Your CSV file should be in a{' '}
          <strong>single column of either email addresses or MD5 hashes</strong>{' '}
          with only one (1) email or hash on each line.
        </p>
        <p>Activity Checker can currently accept files of 100MB.</p>
        <h3>Download an Example File to Test</h3>
        <a
          className="action-checker__how-to-text__download-button"
          href="https://im-general-media.s3.amazonaws.com/email_template.csv"
        >
          Email Template
        </a>
        <a
          className="action-checker__how-to-text__download-button"
          href="https://im-general-media.s3.amazonaws.com/hash_template.csv"
        >
          MD5 Hashes Template
        </a>
      </section>
      <ListCollection
      // rows={listCollection?.results || []}
      // isLoading={isLoading}
      // totalPages={listCollection?.totalPages}
      // totalElements={listCollection?.totalElements}
      // onQueryChange={setQuery}
      />

      <UploadActivityManager
        isOpen={showPopup}
        onClose={handleShowPopupClose}
        setShowSuccess={handleShowSuccess}
      />
    </>
  );
};

export default ActivityManager;
