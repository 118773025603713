import axios from 'axios';

export const API_BASE_URL =
  process.env.REACT_APP_BASE_WYSIWYG_API_URL ||
  'https://dev-wysiwyg-im.smartbrandscompany.com/wysiwyg/api/v1';

export const fetchTemplateById = async id => {
  const response = await axios.get(`${API_BASE_URL}/templates/${id}`);
  return response.data;
};

export const updateTemplate = async (id, data) => {
  await axios.patch(`/templates/${id}/modify`, data);
};
