import axios from 'axios';
import * as yup from 'yup';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import FieldGroup from '../../components/FieldGroup';
import RouterLink from '../../components/RouterLink';
import TriggerDropdown from '../../components/TriggerDropdown';

const validationSchema = yup.object({
  name: yup.string().label('List Name').max(32).required(),
  description: yup.string().label('Description').max(100),
});

const ListBuilder = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { listId } = useParams();

  const { mutateAsync: saveList } = useMutation(
    data =>
      axios.request({
        url: listId ? `/partner-lists/${listId}/edit` : '/partner-lists',
        method: listId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: async response => {
        queryClient.invalidateQueries('partnerLists');
        queryClient.invalidateQueries('list');

        if (listId) {
          history.push('/list-manager');
        } else {
          history.push(`/list-manager/${response.id}/contacts`);
        }
      },
    },
  );

  const { data: list } = useQuery(
    ['list', listId],
    () => axios.get(`/partner-lists/${listId}`),
    {
      enabled: !!listId,
      onError: () => {
        history.push('/404');
      },
    },
  );

  if (listId && !list) {
    return <Spinner fixed />;
  }

  const initialValues = {
    name: list?.name || '',
    description: list?.description || '',
    trigger_id: list?.trigger_id || '',
  };

  return (
    <Builder
      onSubmit={saveList}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title="Create/Edit List"
      okText={listId ? 'Save' : 'Next'}
      cancelButtonProps={{
        component: RouterLink,
        to: '/list-manager',
      }}
    >
      <FieldGroup title="List Details">
        <Field name="name">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="List Name"
              placeholder="Enter List Name"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
        <Field name="description">
          {({ field, meta }) => (
            <TextField
              {...field}
              multiline
              rows={4}
              label="Description"
              placeholder="Enter Description for List"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
      </FieldGroup>
      <Field name="trigger_id" component={TriggerDropdown} />
    </Builder>
  );
};

export default ListBuilder;
