export const thanksReply = {
  header: '',
  footer: `<div style="padding: 0 10px 10px; max-width: 100%;">
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 860px; padding: 10px; margin: 0px auto; background-color: none;">
           
            <tr>
                <td align="center" style="padding: 20px; font-size: 14px;">
                    <p>If you no longer wish to receive {{SITE}} communications, please {{UNSUBSCRIBE}}.</p>
                </td>
            </tr>
        </table>
    </div>`,
};

export const offersInInbox = {
  header: '',
  footer: `<div style="padding: 0 10px 10px; max-width: 100%;">
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 860px; padding: 10px; margin: 0px auto; background-color: none;">
           
            <tr>
                <td align="center" style="padding: 20px; font-size: 14px;">
                    <p>If you no longer wish to receive {{SITE}} communications, please {{UNSUBSCRIBE}}.</p>
                </td>
            </tr>
        </table>
    </div>`,
};
export const informInInbox = {
  header: '',
  footer: `<div style="padding: 0 10px 10px; max-width: 100%;">
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 860px; padding: 10px; margin: 0px auto; background-color: none;">
            <tr>
                <td align="center" style="padding: 20px; font-size: 14px;">
                    <p>If you no longer wish to receive {{SITE}} communications, please {{UNSUBSCRIBE}}.</p>
                </td>
            </tr>
        </table>
    </div>`,
};

export const dontMissMail = {
  header: '',
  footer: `<div style="padding: 0 10px 10px; max-width: 100%;">
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 860px; padding: 10px; margin: 0px auto; background-color: none;">
            <tr>
                <td align="center" style="padding: 20px; font-size: 14px;">
                    <p>If you no longer wish to receive {{SITE}} communications, please {{UNSUBSCRIBE}}.</p>
                </td>
            </tr>
        </table>
    </div>`,
};
