import StatusPage from '../../components/StatusPage';
import useCloseCallback from '../../hooks/useCloseCallback';

const CloseCallback = () => {
  useCloseCallback('id');

  return <StatusPage text="Closing..." />;
};

export default CloseCallback;
