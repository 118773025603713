import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  TextField,
  Box,
  Autocomplete,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { defaultRenderOption, getOptionLabel } from './utils';

export const useStyles = makeStyles(theme => ({
  autoComplete: {
    '& .MuiFilledInput-root': {
      paddingTop: '0.5rem !important',
      paddingBottom: '0.5rem !important',
    },
  },
}));

const SelectInfiniteScroll = ({
  data,
  fetchNextPage,
  hasNextPage,
  onChange,
  optionProperty,
  label,
  isLoading,
  isError,
  selectedId,
  errorMessage,
  renderOption,
}) => {
  const scrollContainerRef = useRef();
  const [touched, setTouched] = useState(false); // New state for tracking touched state
  const classes = useStyles();
  useEffect(() => {
    const handleScroll = event => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop === clientHeight) {
        if (hasNextPage) {
          fetchNextPage();
        }
      }
    };

    const currentRef = scrollContainerRef.current;
    currentRef?.addEventListener('scroll', handleScroll);

    // Cleanup: remove the event listener when the component is unmounted or before the next rendering
    return () => {
      currentRef?.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage]); // re-run the effect if these variables change\
  const getDefaultValue = useMemo(() => {
    return data.find(d => d.id === selectedId);
  }, [data, selectedId]);
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };
  const handleBlur = () => {
    setTouched(true);
  };

  const handleGetOptionLabel = useCallback(
    option => getOptionLabel(option, optionProperty),
    [optionProperty],
  );
  const handleDefaultRenderOption = useCallback(
    (props, option) => defaultRenderOption(props, option, optionProperty),
    [optionProperty],
  );
  return (
    <Box ref={scrollContainerRef}>
      <Autocomplete
        options={data}
        getOptionLabel={handleGetOptionLabel}
        renderOption={renderOption || handleDefaultRenderOption}
        renderInput={params => (
          <TextField
            {...params}
            label={label || 'Select/Search...'}
            InputLabelProps={{
              disableAnimation: true,
            }}
            size="small"
            placeholder="Select/Search..."
            error={touched && !getDefaultValue} // Show error only when touched and no default value
            helperText={
              touched && !getDefaultValue ? errorMessage || 'Required' : ''
            }
            onBlur={handleBlur}
          />
        )}
        noOptionsText={
          isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="1.5rem" />
            </Box>
          ) : isError ? (
            'Error loading options...'
          ) : (
            'No options'
          )
        }
        onChange={handleChange}
        value={getDefaultValue || null}
        className={classes.autoComplete}
      />
    </Box>
  );
};

export default SelectInfiniteScroll;
