import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { FormResolver } from './validation';
import { Handle, Position } from 'reactflow';
import HourglassTopIcon from '@material-ui/icons/HourglassTop';

const Delay = ({ handleUpdateNodeData, node }) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      delay: node.data.actionDetails?.delay,
      delayUnit: node.data.actionDetails?.delayUnit,
    },
    delayError: 500,
    resolver: FormResolver,
  });

  const handleFieldChange = (field, value) => {
    const updatedData = {
      ...node.data,
      actionDetails: {
        ...node.data.actionDetails,
        [field]: value,
        delayUnit: 'hrs',
      },
    };
    handleUpdateNodeData(updatedData, node);
  };

  return (
    <Box className={classes.sendEmailContainer}>
      <Handle type="target" position={Position.Top} />
      <Typography variant="h6" className={classes.sendEmailTitle}>
        <span className={classes.titleSpan}>
          <HourglassTopIcon /> DELAY (hrs)
        </span>
      </Typography>
      <form style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          placeholder="0"
          variant="outlined"
          size="small"
          margin="normal"
          {...register('delay')}
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 2,
          }}
          error={!!errors.delay}
          helperText={errors.delay?.message}
          className={classes.whiteBackground}
          onChange={event => handleFieldChange('delay', event.target.value)}
        />
      </form>

      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

export default Delay;
