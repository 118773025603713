export const formatCurrency = (value, options) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(value);

export const covertCreditsToValue = credits => credits * 0.006;

export const formatCreditsAsCurrency = credits =>
  formatCurrency(covertCreditsToValue(credits));
