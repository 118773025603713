import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(3.5, 5),
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 5, 3.5),
  },
}));
