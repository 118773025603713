import axios from 'axios';
import { useQuery } from 'react-query';

import PartnerContext, { initialState } from '../../contexts/PartnerContext';

const PartnerProvider = ({ children }) => {
  const { data } = useQuery('currentPartner', () => axios.get('/partner'), {
    placeholderData: initialState,
  });

  return (
    <PartnerContext.Provider value={data}>{children}</PartnerContext.Provider>
  );
};

export default PartnerProvider;
