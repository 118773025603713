const calculateGridSize = (numItems, maxItemsPerRow = 3) => {
  const gridSize = 12 / maxItemsPerRow;

  if (numItems === 1) {
    return 12; // full width
  } else if (numItems <= maxItemsPerRow) {
    return 12 / numItems; // divides width based on the number of items
  }
  return gridSize; // default size for items more than maxItemsPerRow
};

export default calculateGridSize;
