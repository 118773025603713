import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import ListCollection from './ListCollection';
import RouterLink from '../../components/RouterLink';
import PageHeader from '../../components/PageHeader';
import Banner from '../../components/Banner';

const ListManager = () => {
  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/list-uploading/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader
        title="List Management"
        buttons={[
          <Button
            color="primary"
            to="/list-manager/add"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <ListCollection />
    </>
  );
};

export default ListManager;
