import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import EmailForm from '../Form';

import { useMutation } from 'react-query';
import {
  EMAIL_TEMPLATE_TYPE,
  createEmailImportedTemplate,
  updateEmailImportedTemplate,
} from '../../../utils/apis/emailTemplates';
import { useUnsavedEditorChangesWarning } from '../../../hooks/useUnsavedEditorChangesWarning';
import { Jodit } from 'jodit-react';
import useCurrentPartner from '../../../hooks/useCurrentPartner';

const CustomEmailImportEditorV1 = props => {
  const { placeholder, initialEditorData } = props;

  const editorRef = useRef(null);
  const partner = useCurrentPartner();
  const { enqueueSnackbar } = useSnackbar();
  const [editorData, setEditorData] = useState(initialEditorData);
  const [editorVisible, setEditorVisible] = useState(false);
  const { setUnsaved, WarningModal } = useUnsavedEditorChangesWarning({
    setEditorData: data => {
      setEditorData({
        ...editorData,
        templateHtml: data.html || editorData.templateHtml,
      });
    },
  });

  useEffect(() => {
    setEditorData(initialEditorData);
  }, [initialEditorData]);

  const config = useMemo(
    () => ({
      readonly: false,
      enableDragAndDropFileToEditor: true,
      placeholder: placeholder || 'Start typing...',
      minHeight: '800px',
      uploader: {
        url: `${process.env.REACT_APP_BASE_WYSIWYG_API_URL}/image/public/upload`,
        format: 'json',
        prepareData: function (formData) {
          const file = formData.getAll('files[0]')?.[0];
          formData.append('file', file);
          formData.delete('files[0]');
        },
        isSuccess: resp => {
          return resp?.status === 200;
        },
        process: resp => {
          // Process the server response, you may return false for stopping the default event
          return {
            file: resp.data.imageUrl,
          };
        },
        error: e => {
          console.error(e, 'error');
        },
        defaultHandlerSuccess: data => {
          const imageSrc = data.file;
          editorRef.current?.selection?.insertImage(imageSrc);
        },
        defaultHandlerError: resp => {},
      },
    }),
    [placeholder],
  );
  const onBlur = useCallback(
    newContent => {
      setEditorData(prevEditorData => ({
        ...prevEditorData,
        templateHtml: newContent,
      }));
      setUnsaved({ active: true, html: newContent });
    },
    [setUnsaved],
  );
  const onChange = newContent => {};

  const handleNextClick = data => {
    if (data.templateName) {
      setEditorVisible(true);
    }
    setEditorData({ ...editorData, templateName: data.templateName });
  };

  const handlePreviousClick = () => {
    saveDesign();
    setTimeout(() => {
      setEditorVisible(false);
    }, 2000);
  };

  const saveDesign = () => {
    const dataToSave = {
      ...editorData,
      type: EMAIL_TEMPLATE_TYPE.IMPORTED,
    };
    if (!dataToSave?.id) {
      saveEmailTemplateDetails(dataToSave);
    } else {
      updateEmailTemplateDetails(dataToSave);
    }
  };

  const {
    mutateAsync: saveEmailTemplateDetails,
    isLoading: isSavingEmailTemplate,
  } = useMutation(data => createEmailImportedTemplate(partner.id, data), {
    onSuccess: ({ data }) => {
      setEditorData({ ...editorData, id: data?.id });
      setUnsaved({
        active: false,
        design: null,
        html: null,
      });
      enqueueSnackbar('Email template saved successfully.', {
        variant: 'success',
        autoHideDuration: 500,
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to save email template.', {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });

  const {
    mutateAsync: updateEmailTemplateDetails,
    isLoading: isUpdatingEmailTemplateDetails,
  } = useMutation(data => updateEmailImportedTemplate(data), {
    onSuccess: ({ data }) => {
      setUnsaved({
        active: false,
        design: null,
        html: null,
      });
      enqueueSnackbar('Email template updated successfully.', {
        variant: 'success',
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to update email template.', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (editorVisible) {
      const joditInstance = Jodit.make('#jodit-editor', config);
      joditInstance.value = editorData?.templateHtml || '';
      editorRef.current = joditInstance;
      // Add onBlur and onChange event listeners to the Jodit instance
      joditInstance.events.on('blur', () => onBlur(joditInstance.value));
      joditInstance.events.on('change', () => onChange(joditInstance.value));
    }
  }, [config, editorData?.templateHtml, editorVisible, onBlur]);
  return (
    <Box>
      {!editorVisible ? (
        <EmailForm
          handleNextClick={handleNextClick}
          defaultValues={{ templateName: editorData.templateName }}
        />
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              padding: '14px 20px',
              borderRadius: '4px',
              backgroundColor: '#ADD8E6',
              color: '#000',
              marginBottom: '1rem',
              fontWeight: '600',
              boxShadow:
                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
          >
            <Typography
              sx={{
                backgroundColor: '#fff',
                borderRadius: '50%',
                padding: '0 5px',
                marginRight: '5px',
              }}
            >
              ❓
            </Typography>{' '}
            <Typography fontWeight="bold">Need Help?</Typography>: If you have
            any questions or run into any issues, we're here to help.{' '}
            <Link
              sx={{
                color: '#000',
                marginLeft: '5px',
                textDecoration: 'underline',
              }}
              href="https://inboxmailers.portal.trainn.co/share/W2kB31hgil1NBtcZ3YElzw"
            >
              Visit our Support Page
            </Link>
            .
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '150px',
              }}
              onClick={handlePreviousClick}
            >
              Previous
            </Button>
            <Box display="flex" justifyContent="space-evenly" gap={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: '150px',
                }}
                onClick={saveDesign}
              >
                {isSavingEmailTemplate || isUpdatingEmailTemplateDetails ? (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : editorData?.id ? (
                  'Update'
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
          <div id="jodit-editor"></div>
        </Box>
      )}
      <WarningModal onSave={saveDesign} />
    </Box>
  );
};

export default CustomEmailImportEditorV1;
