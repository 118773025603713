import Typography from '@material-ui/core/Typography';

const PageTitle = ({ children }) => {
  return (
    <Typography noWrap variant="h5" component="h1" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>
  );
};

export default PageTitle;
