import axios from 'axios';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useQuery } from 'react-query';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useCallback, useState, useMemo } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';

const SwitchAccountDialog = ({ form, onClose }) => {
  const classes = useStyles();

  const { data: user, isLoading: isUserLoading } = useQuery(
    'currentAuthenticatedUser',
    () => Auth.currentAuthenticatedUser(),
  );

  const {
    data: partnersList = [],
    isLoading: isPartnerListLoading,
    isFetching: isPartnerListFetching,
  } = useQuery('getallpartners', () => axios.get('/partners'), {
    select: data =>
      data.map(partner => ({
        value: partner.id,
        label: partner.email_address + ' - ' + partner.partner_name,
      })),
  });

  const formik = useFormik({
    initialValues: {
      partner: '',
    },
    onSubmit: values => {
      localStorage.supprtId = values.partner;
      axios.get('/partner').then(() => {
        window.location.reload();
      });
    },
  });

  const isLoading = formik.isSubmitting || isPartnerListLoading;

  const [searchText, setSearchText] = useState('');

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => partnersList.filter(option => containsText(option.label, searchText)),
    [searchText],
  );

  const emptyItem = useMemo(() => {
    if (isLoading) {
      return <MenuItem value="">Loading...</MenuItem>;
    }

    if (!formik.values.partner) {
      return <MenuItem value="">- No Account -</MenuItem>;
    }

    return null;
  }, [formik.values.partner, isLoading]);

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      {isLoading && <LinearProgress />}
      <DialogTitle>Switch Inbox Mailers Account</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            select
            name="partner"
            label="Inbox Mailers Account"
            value={isLoading ? '' : formik.values.partner}
            onChange={formik.handleChange}
            onClose={() => setSearchText('')}
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            SelectProps={{
              displayEmpty: true,
              disabled: isLoading,
              className: formik.values.partner ? classes.clearableSelect : '',
            }}
            InputProps={{
              endAdornment: formik.values.partner ? (
                <InputAdornment
                  position="end"
                  className={classes.clearSelectIcon}
                  onClick={() => form.setFieldValue(formik.partner, '')}
                >
                  <ClearIcon fontSize="small" color="action" />
                </InputAdornment>
              ) : null,
            }}
            helperText={
              formik.touched[formik.partner] && formik.errors[formik.partner]
            }
            error={
              formik.touched[formik.partner] &&
              Boolean(formik.errors[formik.partner])
            }
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" variant="standard">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>

            {emptyItem}
            {displayedOptions.map((item, key) => (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
        >
          Switch Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SwitchAccountDialog;
