import { useCallback } from 'react';
import { useFormikContext, Field } from 'formik';
import TextField from '@material-ui/core/TextField';

import { integrationMethods } from '../../config/integration';

const IntegrationConfigFields = () => {
  const { values } = useFormikContext();

  const handleValidateField = useCallback((fieldConfig, value) => {
    if (!value) {
      return `${fieldConfig.label} is a required field`;
    }
  }, []);

  return (
    integrationMethods[values.method]?.fields?.length > 0 &&
    integrationMethods[values.method]?.fields?.map(fieldConfig => (
      <Field
        key={fieldConfig.name}
        name={fieldConfig.name}
        validate={handleValidateField.bind(this, fieldConfig)}
      >
        {({ field, meta }) => (
          <TextField
            {...field}
            value={field.value || ''}
            placeholder={`Enter ${fieldConfig.label}`}
            {...fieldConfig}
            helperText={(meta.touched && meta.error) || fieldConfig.helperText}
            error={meta.touched && Boolean(meta.error)}
          />
        )}
      </Field>
    ))
  );
};

export default IntegrationConfigFields;
