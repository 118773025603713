import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const FormSchema = yup.object().shape({
  wait: yup
    .number()
    .typeError('Please enter a valid number')
    .integer('Hours should be an integer')
    .min(1, 'Hours should be at least 1')
    .max(72, 'Hours should not be more than 72')
    .required('This field is required'),
});
export const FormResolver = yupResolver(FormSchema);
