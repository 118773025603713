import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import PageHeader from '../../components/PageHeader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DataGrid from '../../components/DataGrid';
import RowActions from '../../components/RowActions';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import './styles.css';
import axios from 'axios';
import { WorkDocs } from 'aws-sdk';

const ReportDownloads = () => {
  const partner = useCurrentPartner();
  const history = useHistory();
  const [work, setWork] = useState();
  const [msg, setMsg] = useState('Retreiving...');
  const thisURL = window.location.href.split('/');
  const thisReportsId = thisURL[thisURL.length - 1];

  const getPartner = () => {
    console.log('partner ID: ', partner);
    getData();
  };

  const getData = async () => {
    if (!partner.id) return;
    console.log('PARTNER ID: ', partner);
    console.log('REPORT ID: ', thisReportsId);
    await axios
      .get(`/reports/outgoingNotifications/csv?reportId=${thisReportsId}`, {})
      .then(response => {
        console.log('RESPONSE ID: ', response.data);

        console.log('CONDITION: ', partner.id !== response.data[0].partner_id);
        if (
          !response.data[0]?.partner_id &&
          partner.id !== response.data[0]?.partner_id
        ) {
          history.push('/reports');
        } else {
          console.log('** WORK: ', response.data[0]);
          setWork(response.data[0]);
          setStatusMsg(response.data[0].status);
        }
      });
  };

  const goBackLink = () => {
    history.push('/reports/');
  };

  const setStatusMsg = status => {
    if (status === 'Fail') {
      setMsg(
        'Whoops! Something went wrong. Please try again, or contact support.',
      );
    } else if (status === 'Success') {
      setMsg('File is ready!');
    } else {
      setMsg("We're working on it. Please check back later.");
    }
  };

  const [query, setQuery] = useState({ page: 1 });
  const columns = [
    {
      field: 'id',
      title: 'ID',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'pixel_name',
      title: 'Pixel Name',
    },
    {
      field: 'pixel_status_owner',
      title: 'Pixel Status/Owner',
    },
    {
      field: 'from_date',
      title: 'From Date',
    },
    {
      field: 'to_date',
      title: 'To Date',
    },
    {
      field: 'created_at',
      title: 'Created At',
    },
  ];

  const { isLoading, data: exportList } = useQuery([query], () => {}, {
    refetchInterval: 10000,
    keepPreviousData: true,
  });

  const formatedDate = str => {
    return str.slice(0, 10);
  };

  const dataRows = work
    ? [
        {
          id: work.id,
          type: work.type,
          status: work.status,
          pixel_name: work.pixel_name ? work.pixel_name : '-',
          pixel_status_owner: work.pixel_status_owner
            ? work.pixel_status_owner
            : '-',
          from_date: work.from_date ? formatedDate(work.from_date) : '-',
          to_date: work.to_date ? formatedDate(work.to_date) : '-',
          created_at: work.created_at ? formatedDate(work.created_at) : '-',
        },
      ]
    : [];

  // const handleDownload = () => {
  //   let binaryData = [];
  //   binaryData.push(work);
  //   let downloadLink = document.createElement('a');
  //   downloadLink.href = `${work.download_link}`;

  //   // downloadLink.setAttribute('download', `export_${work.id}.csv`);

  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  const handleDownload = async () => {
    // Check if the download link is available
    if (!work.download_link) {
      console.error('Download link is not available.');
      return;
    }

    console.log('Download link:', work.download_link);

    try {
      const response = await fetch(work.download_link);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      // Create an Object URL from the Blob
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `report_${work.id}.csv`;

      // Append the anchor to the body (required for Firefox)
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Remove the anchor from the body after the click
      document.body.removeChild(downloadLink);
      // Revoke the Object URL to free up memory
      window.URL.revokeObjectURL(url);
      console.log('Download initiated');
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  useEffect(() => {
    getPartner();
  }, [partner]);

  return (
    <>
      <PageHeader title="Download Report" />
      <Button onClick={() => goBackLink()} color="primary">
        <ArrowBackIos /> Back
      </Button>
      <h3>{msg}</h3>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={dataRows}
        rowKey="exportListId"
        emptyText="No current Downloads available. Please check back."
        onQueryChange={setQuery}
      />
      <Button
        onClick={() => handleDownload()}
        color="primary"
        variant="contained"
        id="download-btn"
        disabled={work?.status !== 'Success'}
        children={
          work?.status === 'Success'
            ? 'Click to Download'
            : 'Download Pending...'
        }
      />
    </>
  );
};

export default ReportDownloads;
