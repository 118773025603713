import { useState } from 'react';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/core/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Star from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';

import InfoBlock from '../InfoBlock';
import RouterLink from '../RouterLink';
import { formatCurrency } from '../../utils/currency';
import ChangePasswordDialog from './ChangePasswordDialog';
import SwitchAccountDialog from './SwitchAccountDialog';
import BuyCreditDialog from './BuyCreditDialog';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { Tooltip } from '@material-ui/core';
import { CustomToolTipStyles } from '../../styles/theme';
import { useStyles } from './styles';

const menuId = 'primary-account-menu';

const UserProfileMenu = () => {
  const customToolTipClasses = CustomToolTipStyles();
  const partner = useCurrentPartner();
  const [dialogId, setDialogId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBuyCreditDialog, setIsBuyCreditDialog] = useState('');
  const classes = useStyles();

  const handleMenuOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {partner.active_subscription == 'inbox-mailer' ? (
          <Box marginRight={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsBuyCreditDialog(!isBuyCreditDialog)}
            >
              Buy Additional Credits
            </Button>
          </Box>
        ) : null}
        {partner.publisher && (
          <Box marginRight={2}>
            <InfoBlock
              title="Current Commissions"
              to="/reports#commissions"
              value={formatCurrency(partner.balance_to_be_paid ?? 0)}
            />
          </Box>
        )}
        <Box marginRight={5}>
          <Tooltip
            title="One credit is worth one trigger fire. "
            arrow
            classes={customToolTipClasses}
          >
            <Box>
              <InfoBlock
                title="Available Credits"
                value={partner.credits_before_reload.toLocaleString('en-US')}
                to="/account/settings/billing"
              />
            </Box>
          </Tooltip>
        </Box>
        <IconButton
          size="small"
          sx={{ borderRadius: 4 }}
          onClick={handleMenuOpen}
        >
          <AccountCircleIcon
            color="disabled"
            sx={{ fontSize: 36, marginRight: 1.5 }}
          />
          <Box marginRight={1} maxWidth={200} textAlign="left">
            <Typography noWrap color="textSecondary" variant="body2">
              <div className={classes.partnerDetails}>
                {partner.is_special ? <Star /> : <></>}
                {partner.partner_name || <Skeleton width={120} />}
              </div>
            </Typography>
          </Box>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          component={RouterLink}
          to="/account/settings"
          onClick={() => handleMenuClose()}
        >
          Account
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogId('changePassword');
            handleMenuClose();
          }}
        >
          Change Password
        </MenuItem>
        {partner.support_group && (
          <MenuItem
            onClick={() => {
              setDialogId('switchAccount');
              handleMenuClose();
            }}
          >
            Switch Account
          </MenuItem>
        )}
        <MenuItem onClick={() => Auth.signOut()}>Logout</MenuItem>
      </Menu>
      {dialogId === 'changePassword' && (
        <ChangePasswordDialog
          onClose={() => {
            setDialogId('');
          }}
        />
      )}
      {dialogId === 'switchAccount' && (
        <SwitchAccountDialog
          onClose={() => {
            setDialogId('');
          }}
        />
      )}
      {isBuyCreditDialog ? (
        <BuyCreditDialog onClose={() => setIsBuyCreditDialog(false)} />
      ) : null}
    </>
  );
};

export default UserProfileMenu;
