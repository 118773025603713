export const mergeTags = {
  first_name: {
    name: 'First Name',
    value: '{{first_name}}',
    sample: 'John',
  },
  last_name: {
    name: 'Last Name',
    value: '{{last_name}}',
    sample: 'Doe',
  },
};
const blocks = [
  {
    id: 163444,
    category: 'headers',
    tags: ['header'],
    data: {
      id: 'wfIBpn6rnU',
      cells: [1],
      columns: [
        {
          id: '8sCSvLBQd4',
          contents: [
            {
              id: 'yfdDIU56-J',
              type: 'heading',
              values: {
                containerPadding: '30px',
                anchor: '',
                headingType: 'h1',
                fontSize: '22px',
                textAlign: 'center',
                lineHeight: '140%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                hideDesktop: false,
                displayCondition: null,
                _meta: {
                  htmlClassNames: 'u_content_heading',
                },
                selectable: false,
                draggable: false,
                duplicatable: false,
                deletable: false,
                hideable: false,
                text: '<span style="color: #000000; text-align: -webkit-left; white-space: normal; background-color: #e7e7e7; float: none; display: inline;">__locked_verified_partner_</span><wbr style="line-height: inherit; color: #000000; text-align: -webkit-left; white-space: normal; background-color: #e7e7e7;" /><span style="color: #000000; text-align: -webkit-left; white-space: normal; background-color: #e7e7e7; float: none; display: inline;">header__</span>',
              },
            },
          ],
          values: {
            _meta: {
              htmlClassNames: 'u_column',
            },
          },
        },
      ],
      values: {
        displayCondition: null,
        columns: false,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: 'no-repeat',
          size: 'custom',
          position: 'center',
        },
        padding: '0px',
        anchor: '',
        hideDesktop: false,
        _meta: {
          htmlClassNames: 'u_row',
        },
        selectable: false,
        draggable: false,
        duplicatable: false,
        deletable: false,
        hideable: false,
      },
      schemaVersion: 15,
    },
    displayMode: 'email',
    schemaVersion: 15,
  },
  {
    id: 163449,
    category: 'footers',
    tags: ['footer'],
    data: {
      id: 'vs9WdYZRjU',
      cells: [1],
      columns: [
        {
          id: 'R3AchHFpEt',
          contents: [
            {
              id: 'wtcVPys2fj',
              type: 'heading',
              values: {
                containerPadding: '30px',
                anchor: '',
                headingType: 'h1',
                fontSize: '22px',
                textAlign: 'center',
                lineHeight: '140%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                hideDesktop: false,
                displayCondition: null,
                _meta: {
                  htmlClassNames: 'u_content_heading',
                },
                selectable: false,
                draggable: false,
                duplicatable: false,
                deletable: false,
                hideable: false,
                text: '<div>\n<div>\n<div>\n<div>\n<div>\n<div>\n<div>__locked_verified_partner_footer__</div>\n</div>\n</div>\n</div>\n</div>\n</div>\n</div>',
              },
            },
          ],
          values: {
            _meta: {
              htmlClassNames: 'u_column',
            },
          },
        },
      ],
      values: {
        displayCondition: null,
        columns: false,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: 'no-repeat',
          size: 'custom',
          position: 'center',
        },
        padding: '0px',
        anchor: '',
        hideDesktop: false,
        _meta: {
          htmlClassNames: 'u_row',
        },
        selectable: false,
        draggable: false,
        duplicatable: false,
        deletable: false,
        hideable: false,
      },
      schemaVersion: 15,
    },
    displayMode: 'email',
    schemaVersion: 15,
  },
];
//email and send from domain will be there

const options = {
  editor: {
    autoSelectOnDrop: true,
    confirmOnDelete: false,
  },
};

export default options;
