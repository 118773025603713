/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import PageHeader from '../../components/PageHeader';
import Banner from '../../components/Banner';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import '../../dtButtons.css';
import { TrafficRounded } from '@material-ui/icons';

function getYesterdayDate() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString().split('T')[0];
}

function getTodayDate() {
  return new Date().toISOString().split('T')[0];
}

const getParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  params.draw = parseInt(urlParams.get('draw')) || 1;
  params.start = parseInt(urlParams.get('start')) || 0;
  params.length = parseInt(urlParams.get('length')) || 25;

  params.dateStart = urlParams.get('dateStart') || getYesterdayDate();
  params.dateEnd = urlParams.get('dateEnd') || getTodayDate();

  const order = urlParams.get('order');
  if (order) {
    params.order = JSON.parse(order);
  }

  const search = urlParams.get('search');
  if (search) {
    params.search = JSON.parse(search);
  }

  return params;
};

const PixelDataTable = ({ exportFunc }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const [render, setRender] = useState(false);
  //   const [hasResu`lts, setHasResults] = useState(false);
  const paramsFromURL = useMemo(() => getParamsFromURL(), []);
  const minDateRef = useRef(paramsFromURL.dateStart || getYesterdayDate());
  const maxDateRef = useRef(paramsFromURL.dateEnd || getTodayDate());
  let queryId = null;
  let pollingInterval = null;

  const columns = useMemo(
    () => [
      {
        title: 'Email Address',
        data: d => (d['email_address'] ? d['email_address'] : d['key0']),
        defaultContent: '<i>No value</i>',
      },
      {
        title: 'Pixel ID',
        data: d => (d['uuid'] ? d['uuid'] : d['key3']),
      },
      {
        title: 'Pixel Name',
        data: d => (d['pixel_name'] ? d['pixel_name'] : d['key1']),
        defaultContent: '<i>No value</i>',
      },
      {
        title: 'Pixel Description',
        data: d => (d['description'] ? d['description'] : d['key2']),
        defaultContent: '<i>No value</i>',
      },
      {
        title: 'Date Fired',
        data: null,
      },
    ],
    [],
  );

  function formatToMMDDYYYY(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;

    hours = hours ? hours : 12;

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${month}/${day}/${year} ${formattedTime}`;
  }

  const fetchData = async params => {
    const urlParams = getParamsFromURL();

    if (minDateRef.current.value) {
      params['dateStart'] = minDateRef.current.value;
    } else if (minDateRef.current) {
      params['dateStart'] = minDateRef.current;
    } else {
      params['dateStart'] = getYesterdayDate();
    }

    if (maxDateRef.current.value) {
      params['dateEnd'] = maxDateRef.current.value;
    } else if (maxDateRef.current) {
      params['dateEnd'] = maxDateRef.current;
    } else {
      params['dateEnd'] = getTodayDate();
    }
    const mergedParams = { ...urlParams, ...params };

    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);

    const response = await axios.post(
      `/reports/incomingNotifications/?datatables=yes&action=initQuery`,
      mergedParams,
    );

    return response;
  };

  useEffect(() => {
    const initializeDataTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          let min = minDateRef.current.value;
          let max = maxDateRef.current.value;
          let date = new Date(data[9]);

          if (
            (min === '' && max === '') ||
            (min === '' && date <= new Date(max)) ||
            (new Date(min) <= date && max === '') ||
            (new Date(min) <= date && date <= new Date(max))
          ) {
            return true;
          }
          return false;
        });

        const initialParams = getParamsFromURL();

        $(tableRef.current).DataTable({
          serverSide: true,
          processing: true,
          columns: columns,
          dom: '<"d-flex align-items-center dt-controls" l <"date-range-inputs"> f B>rtip',
          responsive: true,
          scrollX: true,
          displayStart: initialParams.start || 0,
          pageLength: initialParams.length || 25,
          order: initialParams.order || [[0, 'asc']],
          search: initialParams.search || { value: '', regex: false },
          ajax: (data, callback, settings) => {
            fetchData(data)
              .then(result => {
                queryId = result.queryId;
                pollForResults(callback);
              })
              .catch(error => {
                console.log('Error initiating query:', error);
                callback({
                  draw: data.draw,
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                  error: 'Failed to initiate query',
                });
              });
          },
          buttons: [
            {
              extend: 'csv',
              text: 'Export',
              action: function (e, dt, node, config) {
                fetchAllDataAndExport();
              },
            },
          ],
          language: {
            infoFiltered: '',
          },
          initComplete: function (settings, json) {
            const api = this.api();
            applyCustomStyles(api);

            $('.date-range-inputs').html(
              `<label class="date-rage-input">From: <input type="date" id="min" name="min" value="${initialParams.dateStart}"></label> &nbsp; ` +
                `<label class="date-rage-input">To: <input type="date" id="max" name="max" value="${initialParams.dateEnd}"></label>`,
            );

            minDateRef.current = document.getElementById('min');
            maxDateRef.current = document.getElementById('max');

            $('#min, #max').on('change', function () {
              api.draw();
            });
          },
          drawCallback: function (settings) {
            const api = this.api();
            applyCustomStyles(api);

            const draw = settings.iDraw || api.context[0]._iDraw;
            const start = settings._iDisplayStart;
            const length = settings._iDisplayLength;
            const order = api.order();
            const search = api.search(); // Capture the search value

            // Ensure the date parameters are updated from the refs or fallback to defaults
            const dateStart = minDateRef.current?.value || getYesterdayDate();
            const dateEnd = maxDateRef.current?.value || getTodayDate();

            // Serialize parameters for the URL
            const queryString = new URLSearchParams({
              draw: draw,
              start: start,
              length: length,
              order: JSON.stringify(order),
              search: JSON.stringify({ value: search, regex: false }), // Include the search value
              dateStart: dateStart,
              dateEnd: dateEnd,
            }).toString();

            // Update the URL with the new query string
            const newUrl = `${window.location.pathname}?${queryString}`;
            window.history.replaceState(null, '', newUrl);
          },
        });

        if (paramsFromURL.search && paramsFromURL.search.value) {
          $(tableRef.current)
            .DataTable()
            .search(paramsFromURL.search.value)
            .draw();
        }
      }
    };

    initializeDataTable();

    window.addEventListener('resize', initializeDataTable);

    return () => {
      window.removeEventListener('resize', initializeDataTable);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [render]);

  async function fetchAllDataAndExport() {
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    try {
      const response = await axios.post(
        '/reports/incomingNotifications/?datatables=yes&export=yes',
        params,
      );

      console.log('RESPONSE: ', response);
      if (response.body && response.body.length > 0) {
        const csvData = convertToCSV(response.body);
        downloadCSV(csvData, `export_pixel_report_${formatDate()}.csv`);
        setRender(!render);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    }
  }

  function pollForResults(callback) {
    let data;
    pollingInterval = setInterval(async function () {
      data = await axios.post(
        `/reports/incomingNotifications/?datatables=yes&action=pollStatus`,
        queryId,
      );
      if (data.status === 'completed') {
        clearInterval(pollingInterval);
        console.log('DATA: ', data);
        // setHasResults(true);
        callback({
          draw: data.draw,
          recordsTotal: data.recordsTotal || 0,
          recordsFiltered: data.recordsFiltered || 0,
          data: data.data || [],
        });
      } else if (data.error || data.status === 'error') {
        clearInterval(pollingInterval);
        callback({
          draw: data.draw,
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
          error: 'Failed to initiate query',
        });
      }
    }, 2000);
  }

  function formatDate(d = null) {
    if (d) {
      const date = new Date(d);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      today = mm + '-' + dd + '-' + yyyy;
      return today;
    }
  }

  function convertToCSV(data) {
    console.log('CSV DATA: ', data);
    const csvRows = [];
    const headers = Object.keys(data[0] || {});

    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const val = row[header];
        return val;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  }

  function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function applyCustomStyles(api) {
    $(api.table().container()).find('.makeStyles-paper-35').css({
      'max-width': '1200px',
    });
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('.date-rage-input').css({
      padding: '8px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
      'border-bottom': '2px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
    });

    $(api.table().container()).find('tfoot tr th').css({
      'border-bottom': '2px solid #dee2e6',
    });

    $(api.table().container()).find('.dt-info').css({
      'padding-left': '10px',
    });
    $(api.table().container()).find('tbody tr td').css({
      'padding-left': '5px',
    });

    $(api.table().container())
      .find('tbody tr td:last-child, thead tr th:last-child')
      .css({
        'border-right': 'none',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
      )
      .css({
        'border-right': '1px solid #dee2e6',
        'background-color': 'inherit',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
      )
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
      .css({
        'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
      });

    // Apply custom styles for pagination controls
    $(api.table().container()).find('ul.pagination').css({
      display: 'flex', // Use flexbox to align items horizontally
      justifyContent: 'center', // Center align pagination controls
      padding: '0', // Remove default padding
      marginTop: '10px', // Add some spacing above the pagination controls
      listStyle: 'none', // Remove default list styling
    });

    $(api.table().container()).find('.date-range-inputs').css({
      'font-size': '14px',
      'font-weight': 'bold',
    });

    $(api.table().container()).find('ul.pagination li').css({
      display: 'inline',
      margin: '0 2px', // Add spacing between pagination items
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block', // Ensure links are inline
      padding: '5px 10px', // Add padding for clickable area
      border: '1px solid #ddd', // Border for pagination items
      borderRadius: '5px', // Rounded corners for pagination items
      textDecoration: 'none', // Remove underline from links
      color: '#333', // Text color
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001', // Highlight background color for the active page
      color: 'white', // Text color for active page
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1', // Background color on hover
      color: '#333', // Text color on hover
    });

    $(api.table().container()).find('.dt-controls').css({
      display: 'flex',
      alignItems: 'center',
    });

    // Increase size for all inputs with ids matching dt-search-*
    $(api.table().container()).find('input[id^="dt-search-"]').css({
      height: '2.5rem',
      width: '15rem',
    });

    // Increase size for all selects with ids matching dt-length-*
    $(api.table().container()).find('select[id^="dt-length-"]').css({
      height: '2.5rem',
      width: '3rem',
    });

    // Apply flexbox to div.dt-length
    $(api.table().container()).find('div.dt-length').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('div.dt-search').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#4CAF50',
      'border-width': '0',
      'border-radius': '5px',
      color: 'white',
    });
  }

  return (
    <>
      <PageHeader title="Pixel Reports" />
      <Paper className={classes.paper}>
        <Box>
          <table
            ref={tableRef}
            className="display"
            style={{ width: '100%' }}
          ></table>
        </Box>
      </Paper>
    </>
  );
};

export default PixelDataTable;
