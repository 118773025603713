import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { TriggerFormResolver } from './validation';
import FieldGroup from '../FieldGroup';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useInfiniteQuery, useMutation } from 'react-query';
import {
  getCampaignDomains,
  getEmailTemplates,
} from '../../utils/apis/emailTemplates';
import SelectInfiniteScroll from '../SelectInfiniteScroll';
import { nodeOptions } from './utils';
import {
  createCampaignAsIntegration,
  updateCampaign,
} from '../../utils/apis/campaigns';
import { useSnackbar } from 'notistack';
import PageHeader from '../PageHeader';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  inboxMailersCampaign,
  integrationProviders,
} from '../../config/integration';
import { formatDateAgo } from '../../utils/date';
import { generateDynamicNameFromDomain } from '../../utils/domain';
import replacePlaceholdersForEmail from '../../utils/domains/replacePlaceHoldersForEmail';
import EmailPreview from '../EmailPreview.js';
import {
  Visibility as VisibilityIcon,
  Send as SendIcon,
} from '@material-ui/icons';
import SendTestEmailForm from './SendTestEmailForm';
import extractDomainFromEmail from '../../utils/domains/extractDomainFromEmail';
import { filterOutSharedDomains } from './utils';
import theme from '../../styles/theme.js';

const useStyles = makeStyles(theme => ({
  campaignFormContainer: {
    width: props => props.width || '600px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
  },
}));

const WebhookInformation = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: '10px',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontSize: '14px', color: 'rgb(224, 0, 1)' }}
        gutterBottom
      >
        Webhook Configuration
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '12px' }}>
        Provide your webhook URL to be notified on open and click events. We
        will append parameters for{' '}
        <code>
          <b>email</b>
        </code>{' '}
        (contact email) and{' '}
        <code>
          <b>event</b>
        </code>{' '}
        (event type, as either "open", "click", "complaint" or "unsubscribe").
      </Typography>
      <Box component="ul" sx={{ margin: 0, paddingLeft: '20px' }}>
        <li>
          <Typography
            variant="body2"
            sx={{ fontSize: '12px' }}
            color="rgb(224, 0, 1)"
          >
            Example format of your webhook URL:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '12px' }}>
            <code>https://{`{your.domain}`}.com/somewhere</code>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body2"
            sx={{ fontSize: '12px' }}
            color="rgb(224, 0, 1)"
          >
            When an event occurs, we will send a GET request like this:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '12px' }}>
            <code>
              https://{`{your.domain}`}.com/somewhere?email={`{email}`}
              &event={`{event}`}
            </code>
          </Typography>
        </li>
      </Box>
    </Paper>
  );
};

const TriggerCampaignForm = ({
  handleCancel,
  initialCampaignData,
  isNew,
  enableIcon,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const partner = useCurrentPartner();
  const { enqueueSnackbar } = useSnackbar();
  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [showShared, setShowShared] = useState(true);
  const [showVerified, setShowVerified] = useState(false);
  const [sharedSelected, setSharedSelected] = useState(true);
  const integrationConfig = integrationProviders.find(
    provider => provider.type === inboxMailersCampaign.type,
  );
  const [selectedTemplate, setSelectedTemplate] = useState({
    id: initialCampaignData?.actions?.[0]?.action_details?.templateId,
  });
  const [from, setFrom] = useState({
    id: initialCampaignData?.actions?.[0]?.action_details?.from?.id,
    name: initialCampaignData?.actions?.[0]?.action_details?.from?.name,
    email: initialCampaignData?.actions?.[0]?.action_details?.from?.email,
    company: initialCampaignData?.actions?.[0]?.action_details?.from?.company,
  });
  const [campaignData, setCampaignData] = useState(initialCampaignData);
  useEffect(() => {
    setCampaignData(initialCampaignData);
    setSelectedTemplate({
      id: initialCampaignData?.actions?.[0]?.action_details?.templateId,
    });
    setFrom({
      id: initialCampaignData?.actions?.[0]?.action_details?.from?.id,
      name: initialCampaignData?.actions?.[0]?.action_details?.from?.name,
      email: initialCampaignData?.actions?.[0]?.action_details?.from?.email,
      company: initialCampaignData?.actions?.[0]?.action_details?.from?.company,
    });
  }, [initialCampaignData]);
  const { data, fetchNextPage, hasNextPage, isLoading, isError } =
    useInfiniteQuery(
      ['email-templates', partner.id],
      ({ pageParam = 1 }) => getEmailTemplates(partner.id, pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.totalPages > allPages.length) {
            return allPages.length + 1;
          }
          return false;
        },
        enabled: !!partner.id,
        staleTime: 0,
      },
    );

  const {
    data: domainsData,
    fetchNextPage: fetchNextDomainsPage,
    hasNextPage: hasNextDomainsPage,
    isLoading: isLoadingDomains,
    isError: isErrorDomains,
  } = useInfiniteQuery(
    ['campaign_domains'],
    ({ pageParam = 1 }) => getCampaignDomains(partner.id, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.totalPages > allPages.length) {
          return allPages.length + 1;
        }
        return false;
      },
      enabled: !!partner.id,
      staleTime: 0,
    },
  );

  const handleTemplateChange = value => {
    setSelectedTemplate({
      ...value,
      id: value?.id,
    });
  };
  const handleCampaignFromChange = value => {
    const { via, email, company } = generateDynamicNameFromDomain(
      value?.domain,
      formValues?.company,
    );
    setFrom({
      id: value?.id,
      name: via,
      email,
      company,
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      title: campaignData?.title,
      description: campaignData?.description,
      webhookUrl: campaignData?.webhookUrl,
      subject: campaignData?.actions?.[0]?.action_details?.subject,
      company:
        campaignData?.actions?.[0]?.action_details?.from?.company ||
        partner?.partner_name ||
        partner?.details?.company_name,
    },
    delayError: 500,
    resolver: TriggerFormResolver,
  });

  const formValues = watch();

  useEffect(() => {
    // Update the form with new campaign data whenever initialCampaignData changes
    if (campaignData) {
      reset({
        title: campaignData?.title,
        description: campaignData?.description,
        subject: campaignData?.actions?.[0]?.action_details?.subject,
        company:
          campaignData?.actions?.[0]?.action_details?.from?.company ||
          partner?.partner_name ||
          partner?.details?.company_name,
      });
    }
  }, [
    campaignData,
    partner?.details?.company_name,
    partner?.partner_name,
    reset,
  ]);
  const {
    mutateAsync: saveCampaignIntegrationData,
    isLoading: isSavingCampaignIntegrationData,
  } = useMutation(data => createCampaignAsIntegration(partner.id, data), {
    onSuccess: ({ data }) => {
      enqueueSnackbar('Integration detail saved successfully.', {
        variant: 'success',
      });
      !campaignData?.id && !isNew && handleCancel();
      reset();
      setSelectedTemplate({});
      setFrom({});
    },
    onError: error => {
      enqueueSnackbar('Unable to save integration details.', {
        variant: 'error',
      });
    },
  });

  const {
    mutateAsync: updateCampaignIntegrationData,
    isLoading: isUpdatingCampaignIntegrationData,
  } = useMutation(data => updateCampaign(data), {
    onSuccess: ({ data }) => {
      enqueueSnackbar('Integration detail updated successfully.', {
        variant: 'success',
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to update integration details.', {
        variant: 'error',
      });
    },
  });

  const onSubmit = handleSubmit(async data => {
    const domain = extractDomainFromEmail(from?.email);
    const { via, email, company } = generateDynamicNameFromDomain(
      domain,
      formValues?.company,
    );
    const campaign = {
      id: campaignData?.id,
      title: data.title,
      description: data.description,
      webhookUrl: data.webhookUrl,
    };

    const formattedFrom = {
      ...from,
      name: via,
      email,
      company,
    };

    const formattedFromShared = {
      id: null,
      name: null,
      email: null,
      company: null,
    };

    const actions = [
      {
        id: campaignData?.actions?.[0]?.id,
        actionOrder: campaignData?.actions?.[0]?.action_order || 1,
        actionType:
          campaignData?.actions?.[0]?.action_type || nodeOptions.SEND_EMAIL,
        actionDetails: {
          templateId: selectedTemplate?.id,
          subject: data.subject,
          from: formattedFrom,
        },
      },
    ];
    const dataToSave = {
      ...campaign,
      actions,
    };

    if (!dataToSave?.id) {
      saveCampaignIntegrationData(dataToSave);
    } else {
      updateCampaignIntegrationData(dataToSave);
    }
  });

  const emailTemplates = useMemo(
    () => data?.pages.flatMap(page => page.data) || [],
    [data?.pages],
  );
  const campaignDomains = useMemo(
    () => domainsData?.pages.flatMap(page => page.data) || [],
    [domainsData?.pages],
  );
  const htmlString = useMemo(() => {
    return emailTemplates.find(
      template => template?.id === selectedTemplate?.id,
    )?.template_html;
  }, [emailTemplates, selectedTemplate?.id]);
  const fromDetails = useMemo(() => {
    return campaignDomains.find(domain => domain?.id === from?.id);
  }, [campaignDomains, from?.id]);
  const newTemplate = useMemo(
    () =>
      replacePlaceholdersForEmail(htmlString, {
        ...fromDetails,
        email: from?.email,
        name: from?.name,
      }),
    [from, fromDetails, htmlString],
  );
  const handleOpenPreviewEmail = e => {
    e.preventDefault();
    setOpenPreviewEmail(true);
  };

  const handleClosePreviewEmail = () => {
    setOpenPreviewEmail(false);
  };

  const handleOpenSendEmail = e => {
    e?.preventDefault();
    setOpenSendEmail(true);
  };

  const handleCloseSendEmail = e => {
    e?.preventDefault();
    setOpenSendEmail(false);
  };

  const handleVerifyOption = () => {
    setShowShared(false);
    setSharedSelected(false);
    setShowVerified(true);
  };

  const handleSharedOption = () => {
    setShowShared(true);
    setSharedSelected(true);
    setShowVerified(false);
  };
  const newCampaignDomains = filterOutSharedDomains(campaignDomains);
  return (
    <>
      {campaignData?.id || isNew ? (
        <PageHeader
          title={isNew ? 'Create Integration' : 'Edit Integration'}
          backButtonProps={{ to: '/integrations' }} // will change to campaigns when flow is completed
        />
      ) : null}

      <Box
        className={
          campaignData?.id || isNew ? classes.campaignFormContainer : ''
        }
        flex="1"
      >
        <FieldGroup title="Inbox Mailers Integration Details">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {enableIcon && <integrationConfig.icon width={120} height={80} />}
            {newTemplate?.length > 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
                mt="20px"
              >
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
                    height: '40px',
                  }}
                  onClick={handleOpenPreviewEmail}
                  color="primary"
                  startIcon={<VisibilityIcon />}
                >
                  Preview Email
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    height: '40px',
                  }}
                  onClick={handleOpenSendEmail}
                  color="primary"
                  startIcon={<SendIcon />}
                >
                  Send Test Email
                </Button>
              </Box>
            )}
          </Box>

          <form onSubmit={onSubmit}>
            <TextField
              label="Name"
              size="small"
              placeholder="Enter integration name"
              {...register('title')}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
            <TextField
              label="Description"
              placeholder="Enter integration description"
              multiline
              rows={4}
              {...register('description')}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            {/* TODO: add end and start date picker */}
            <TextField
              label=" Email Subject"
              placeholder="Enter email subject"
              size="small"
              {...register('subject')}
              error={!!errors.subject}
              helperText={errors.subject?.message}
            />
            <TextField
              label="Company"
              size="small"
              placeholder="Enter company name"
              {...register('company')}
              error={!!errors.company}
              helperText={errors.company?.message}
            />
            <TextField
              label="Webhook URL"
              size="small"
              placeholder="Enter webhook url"
              {...register('webhookUrl')}
              error={!!errors.webhookUrl}
              helperText={errors.webhookUrl?.message}
            />
            <WebhookInformation />

            {/* <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="selectDomain"
              sx={{ marginTop: 2 }}
              defaultValue="shared"
            >
              <Box
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 1.5,
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Send from our Shared Domains"
                  value="shared"
                  sx={{
                    border: '1px solid #d3d3d3',
                    paddingY: '10px',
                  }}
                  onClick={handleSharedOption}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Send from your Verified Domains"
                  value="verified"
                  sx={{ border: '1px solid #d3d3d3' }}
                  onClick={handleVerifyOption}
                />
              </Box>
              {showShared ? (
                <Typography sx={{ color: '#f44336', fontSize: '14px' }}>
                  Your email will be sent from the Inbox Mailer Shared Domain
                  Pool
                </Typography>
              ) : null}
            </RadioGroup>

            {showVerified ? (
              <SelectInfiniteScroll
                data={newCampaignDomains}
                isLoading={isLoadingDomains}
                selectedId={from?.id}
                isError={isErrorDomains}
                fetchNextPage={fetchNextDomainsPage}
                hasNextPage={hasNextDomainsPage}
                onChange={handleCampaignFromChange}
                optionProperty={['name', 'domain']}
                renderOption={(props, option) => {
                  const { via, email } = generateDynamicNameFromDomain(
                    option?.domain,
                    formValues?.company,
                  );

                  return (
                    <MenuItem {...props} key={option.id}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          fontWeight="500"
                          fontSize="12px"
                          component="p"
                        >
                          {via}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          color="textSecondary"
                          ml="8px"
                          fontSize="12px"
                          fontWeight="400"
                        >
                          {`<${email}>`}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                }}
                label={
                  <span style={{ color: '#f44336' }}>
                    Your Verified Domains...
                  </span>
                }
              />
            ) : null} */}
            <SelectInfiniteScroll
              data={campaignDomains}
              isLoading={isLoadingDomains}
              selectedId={from?.id}
              isError={isErrorDomains}
              fetchNextPage={fetchNextDomainsPage}
              hasNextPage={hasNextDomainsPage}
              onChange={handleCampaignFromChange}
              optionProperty={['name', 'domain']}
              renderOption={(props, option) => {
                const { via, email } = generateDynamicNameFromDomain(
                  option?.domain,
                  formValues?.company,
                );

                return (
                  <MenuItem {...props} key={option.id}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        fontSize="12px"
                        component="p"
                      >
                        {via}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                        ml="8px"
                        fontSize="12px"
                        fontWeight="400"
                      >
                        {`<${email}>`}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              }}
              label="Select From..."
            />

            <SelectInfiniteScroll
              data={emailTemplates}
              isLoading={isLoading}
              selectedId={selectedTemplate?.id}
              isError={isError}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              onChange={handleTemplateChange}
              renderOption={(props, option) => {
                let optionLabel = option['template_name'];
                const createdAt = option['date_created'];
                const dateAgo = formatDateAgo(createdAt);
                return (
                  <MenuItem {...props} key={option.id}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        fontSize="12px"
                        component="p"
                      >
                        {optionLabel}:{' '}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                        ml="8px"
                        fontSize="12px"
                        fontWeight="400"
                      >
                        Created {dateAgo}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              }}
              optionProperty="template_name"
              label="Select Email Template..."
            />
            <Box display="flex" justifyContent="space-between" my={1}>
              <Button
                type="cancel"
                onClick={() => {
                  if (!campaignData?.id && !isNew && handleCancel) {
                    handleCancel();
                  } else {
                    history.push('/integrations'); // will change to campaigns when flow is completed
                  }
                }}
                variant="outlined"
                sx={{
                  width: '150px',
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: '150px',
                }}
                color="primary"
              >
                {isSavingCampaignIntegrationData ||
                isUpdatingCampaignIntegrationData ? (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : campaignData?.id ? (
                  'Update'
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </form>
        </FieldGroup>
      </Box>
      {/* Preview email dialog */}
      <Dialog
        open={openPreviewEmail}
        onClose={handleClosePreviewEmail}
        maxWidth="md"
      >
        <DialogTitle>Email Preview</DialogTitle>
        <DialogContent>
          <EmailPreview html={newTemplate} />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          {/* Here you can add any actions for your dialog */}
          <Button
            sx={{
              width: '150px',
            }}
            onClick={handleClosePreviewEmail}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          {/* Add any other actions you need */}
        </DialogActions>
      </Dialog>
      {/* Send Email Dialog */}
      <Dialog open={openSendEmail} onClose={handleCloseSendEmail} maxWidth="md">
        <DialogContent>
          <SendTestEmailForm
            actions={
              <>
                {/* Here you can add any actions for your dialog */}
                <Button
                  sx={{
                    width: '150px',
                  }}
                  onClick={handleCloseSendEmail}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
                {/* Add any other actions you need */}
              </>
            }
            data={{
              fromId: fromDetails?.id,
              subject: formValues?.subject,
              fromCompany: formValues?.company,
              content: newTemplate,
              closeDialog: handleCloseSendEmail,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TriggerCampaignForm;
