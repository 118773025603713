import qs from 'qs';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const QUERY_KEY = 'IMP_AUTH_WINDOW_STATE';

const useCloseCallback = queryKey => {
  const queryClient = useQueryClient();

  const { data: status } = useQuery(QUERY_KEY, () => false, {
    initialData: () => false,
    onSuccess: data => {
      if (data) {
        queryClient.removeQueries(QUERY_KEY);
      }
    },
  });

  useEffect(() => {
    if (window.location.href.includes('/close')) {
      let successKey = true;

      if (queryKey) {
        const query = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        successKey = query[queryKey] || successKey;
      }

      queryClient.setQueryData(QUERY_KEY, successKey);

      setTimeout(() => {
        const authWindow = window.open('', 'IMP_AUTH_WINDOW');
        authWindow?.close();
      });
    }
  }, [queryClient, queryKey]);

  return status;
};

export default useCloseCallback;
