import axios from 'axios';
import { Field } from 'formik';
import timezones from 'timezones-list';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useMemo, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { countries, languages } from 'countries-list';
import { useMutation, useQueryClient } from 'react-query';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import RouterLink from '../../components/RouterLink';
import FieldGroup from '../../components/FieldGroup';
import useCurrentPartner from '../../hooks/useCurrentPartner';

const fields = [
  {
    name: 'first_name',
    label: 'First Name',
  },
  {
    name: 'last_name',
    label: 'Last Name',
  },
  {
    name: 'company_name',
    label: 'Company Name',
  },
  {
    name: 'contact_email',
    label: 'Contact Email',
    type: 'email',
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'tel',
  },
  {
    name: 'category',
    label: 'Category of Business',
  },
  {
    name: 'timezone',
    label: 'Timezone',
    select: true,
    options: timezones.map(timezone => ({
      value: timezone.tzCode,
      label: timezone.label,
    })),
  },
  {
    name: 'size_of_list',
    label: 'Size of List',
  },
];

const AccountDetails = () => {
  const partner = useCurrentPartner();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: savePartnerDetails } = useMutation(
    data => axios.put('/partner/details', data),
    {
      onSuccess: details => {
        enqueueSnackbar('Your profile has been successfully updated.', {
          variant: 'success',
        });

        queryClient.setQueryData('currentPartner', prevData => ({
          ...prevData,
          details,
        }));
      },
    },
  );

  const handleSubmit = useCallback(
    values => savePartnerDetails(values),
    [savePartnerDetails],
  );

  const initialValues = useMemo(
    () => ({
      ...fields.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.name]: '',
        }),
        {},
      ),
      ...partner.details,
    }),
    [partner],
  );

  if (!partner.id) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Builder
      dense
      hideProgress
      onSubmit={handleSubmit}
      initialValues={initialValues}
      cancelButtonProps={{
        component: RouterLink,
        to: '/',
      }}
    >
      <FieldGroup
        title="Information"
        description="Enter your profile information"
      >
        <Grid container spacing={3} sx={{ marginTop: -1 }}>
          {fields.map(({ name, options, ...fieldConfig }) => (
            <Grid key={name} item xs={12} sm={6}>
              <Field name={name}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    {...fieldConfig}
                    margin="none"
                    SelectProps={{ displayEmpty: true }}
                    placeholder={`Enter ${fieldConfig.label}`}
                    helperText={meta.touched && meta.error}
                    error={meta.touched && Boolean(meta.error)}
                  >
                    {fieldConfig.select && (
                      <MenuItem value="">Select {fieldConfig.label}</MenuItem>
                    )}
                    {options?.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            </Grid>
          ))}
        </Grid>
      </FieldGroup>
    </Builder>
  );
};

export default AccountDetails;
