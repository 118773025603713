import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import { useStyles } from './styles';

const StatusSwitch = ({ label, onLabel, offLabel, field }) => {
  const classes = useStyles();

  return (
    <FormControl margin="normal">
      <Typography color="textSecondary" variant="body2">
        {label}
      </Typography>
      <Grid component="label" container alignItems="center">
        <Grid item>
          <Typography
            variant="body2"
            color={field.value ? 'textSecondary' : 'inherit'}
          >
            {offLabel}
          </Typography>
        </Grid>
        <Grid item>
          <Switch
            {...field}
            color="default"
            checked={field.value}
            classes={{ track: classes.statusSwitchTrack }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color={field.value ? 'inherit' : 'textSecondary'}
          >
            {onLabel}
          </Typography>
        </Grid>
      </Grid>
    </FormControl>
  );
};

StatusSwitch.propTypes = {
  title: PropTypes.string,
  field: PropTypes.object.isRequired,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
};

StatusSwitch.defaultProps = {
  onLabel: 'On',
  offLabel: 'Off',
};

export default StatusSwitch;
