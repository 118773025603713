import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dndflow: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    height: '80vh',
    border: `2px solid #e00001`,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    borderRadius: theme.spacing(1),
  },
  aside: {
    borderRight: '1px solid #eee',
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderLeft: '1px solid #ddd',
    // boxShadow: "-2px 0px 5px black",
    padding: '15px 10px',
    fontSize: '12px',
    background: '#fcfcfc',
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      maxWidth: '250px',
    },
  },
  description: {
    borderBottom: '2px solid #e00001',
    marginBottom: '10px',
    color: '#000',
    fontWeight: 700,
    textAlign: 'center',
  },
  dndnode: {
    height: '20px',
    padding: '4px',
    border: '1px solid #1a192b',
    borderRadius: '2px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#e00001',
    color: '#fff',
    fontWeight: 700,
    alignItems: 'center',
    cursor: 'grab',
    '&.input': {
      borderColor: '#000',
      padding: theme.spacing(2),
      fontSize: theme.spacing(2),
      fontWeight: 600,
      // boxShadow: '0 0 3px #000',
    },
    '&.output': {
      borderColor: '#ff0072',
    },
  },
  titleSpan: {
    display: 'flex',
    fontWeight: 700,
    gap: 5,
  },
  'reactflow-wrapper': {
    flexGrow: 1,
    height: '100%',
  },
  selectall: {
    marginTop: '10px',
  },
}));

export default useStyles;
